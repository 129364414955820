import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
    Typography,
    Paper,
    TableCell,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Box
} from '@mui/material'
import { ViewState } from '@devexpress/dx-react-scheduler'
import { Scheduler, MonthView, Appointments } from '@devexpress/dx-react-scheduler-material-ui'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { PATHS, WARNING } from '../../constants'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { CheckCircleOutlined, Circle } from '@mui/icons-material'
import SubmitSvg from '../SubmitShift/SubmitSvg'
import SelectedStore from '../SelectedStore'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import moment from 'moment'
import { computeColor } from './CheckShiftDay'
import { isMobile } from '../../Utilities/isMobile'

const CheckFinalizedCalendar = ({ history }) => {
    const { calendarYearMonth, employmentId } = useParams()
    const { isManagerFor, canEditShiftFor, userInfo } = useContext(EmploymentsContext)
    const [shifts, setShifts] = useState([])
    const [dialogue, setDialogue] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [year, month] = calendarYearMonth.split('-')
    const [resubmit, setResubmit] = useState(false)
    const [checkShift, setCheckShift] = useState(false)
    const [releasedDates, setReleasedDates] = useState([])
    const [selectedDates, setSelectedDates] = useState([])
    const [selectDates, setSelectDates] = useState(false)
    const day = moment(calendarYearMonth, 'YYYY-MM').daysInMonth()
    const days = Array.from(Array(day).keys()).map(day =>
        moment(calendarYearMonth + '-' + (day + 1), 'YYYY-MM-DD').format('YYYY-MM-DD')
    )
    const handleFinalize = () => {
        AxiosWithAuth.post('/finalizeShifts', {
            month_number: month,
            employment_id: employmentId,
            year: year,
            selected_dates: selectedDates
        }).then(res => {
            if (res.status === 200) {
                setSubmitted(true)
            }
        })
    }

    const redirectToDay = date => {
        const targetDate = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        if (selectDates) return
        const checking = history.location.pathname.split('/')[1].includes('checkshift')
        checking
            ? history.push(PATHS.checkDay.replace(':employmentId', employmentId).replace(':shiftDate', targetDate))
            : history.push(PATHS.createDay.replace(':employmentId', employmentId).replace(':shiftDate', targetDate))
    }
    const appointmentCellClicked = date => {
        if (!days.includes(date)) return
        if (selectDates) {
            selectedDates.includes(date)
                ? setSelectedDates(selectedDates.filter(d => d !== date))
                : setSelectedDates([...selectedDates, date])
        } else if (isManagerFor[employmentId] || canEditShiftFor[employmentId] || userInfo.view_only) {
            redirectToDay(date)
        }
    }
    const CustomCell = ({ startDate, ...props }) => {
        const date = moment(startDate).format('YYYY-MM-DD')
        return (
            <MonthView.TimeTableCell
                startDate={startDate}
                style={{
                    height: '120px',
                    background: selectedDates.includes(date)
                        ? '#CFD3EC'
                        : !isManagerFor[employmentId] &&
                          !canEditShiftFor[employmentId] &&
                          !releasedDates.includes(date) &&
                          !userInfo.view_only &&
                          days.includes(date)
                        ? 'lightgray'
                        : ''
                }}
                {...props}
                onClick={() => appointmentCellClicked(date)}
            />
        )
    }

    const CustomHeader = ({ startDate }) => {
        return (
            <TableCell>
                <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                    {Intl.DateTimeFormat('ja', { weekday: 'short' }).format(startDate)}
                </Typography>
            </TableCell>
        )
    }

    const CustomAppointment = ({ data, style, ...props }) => {
        if (data.title === 'warning') {
            data.title = '人員不足'
        }
        return (
            <>
                {data.title === '人員不足' ? (
                    <Appointments.Appointment
                        {...props}
                        style={{
                            ...style,
                            backgroundColor: WARNING,
                            fontSize: '.65rem',
                            height: '24px'
                        }}
                        onClick={() => redirectToDay(moment(data.startDate).format('YYYY-MM-DD'))}
                        color="red"
                    />
                ) : (
                    <Appointments.Appointment
                        data={data}
                        style={{ backgroundColor: computeColor(false, data) }}
                        {...props}
                        onClick={() => redirectToDay(moment(data.startDate).format('YYYY-MM-DD'))}
                    />
                )}
            </>
        )
    }

    const ManagerAppointment = ({ data, style, ...props }) => {
        const date = moment(data.startDate).format('YYYY-MM-DD')
        if (data.title === 'warning') {
            data.title = '人員不足'
        }
        if (data.title === 'released') {
            data.title = '送信済み'
        }
        return (
            <div
                style={{ width: '100%', height: '100%', textAlign: 'center' }}
                onClick={() => appointmentCellClicked(date)}
            >
                {data.title === 'confirmed' ? (
                    <CheckCircleOutlined color="success"></CheckCircleOutlined>
                ) : data.title === '送信済み' ? (
                    <Appointments.Appointment
                        {...props}
                        style={{
                            ...style,
                            backgroundColor: '#717171',
                            fontSize: '.65rem',
                            height: '24px'
                        }}
                    />
                ) : (
                    <Appointments.Appointment
                        {...props}
                        style={{
                            ...style,
                            backgroundColor: WARNING,
                            fontSize: '.65rem',
                            height: '24px'
                        }}
                        onClick={() => appointmentCellClicked(date)}
                        color="red"
                    />
                )}
            </div>
        )
    }

    const customAppointmentContent = ({ ...restProps }) => {
        return (
            <Appointments.AppointmentContent {...restProps} style={{ padding: '3px', width: '100%' }}>
                <div className={restProps.container}>
                    <div style={{ whiteSpace: 'normal' }}>{restProps.data.title}</div>
                </div>
            </Appointments.AppointmentContent>
        )
    }

    const getShiftTitle = (start, end) => {
        const startHours = start.getHours()
        const startMinutes = ('00' + start.getMinutes()).slice(-2)
        const endHours = end.getHours()
        const endMinutes = ('00' + end.getMinutes()).slice(-2)
        return `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`
    }

    const getManagerCalendar = async () => {
        let fusokuDatesArr
        let confirmedDatesArr
        let releasedDatesArr
        await AxiosWithAuth.get('/shifts/monthly', {
            params: { employment_id: employmentId, date: calendarYearMonth + '-01' }
        }).then(res => {
            fusokuDatesArr = res.data.fusoku_dates
            confirmedDatesArr = res.data.confirmed_shift_dates
            releasedDatesArr = res.data.released_shift_dates
        })
        const warningDates = fusokuDatesArr.map(e => {
            if (!e) return
            const [warningYear, warningMonth, warningDay] = e.split('-')
            return {
                title: 'warning',
                startDate: new Date(warningYear, warningMonth - 1, warningDay),
                endDate: new Date(warningYear, warningMonth - 1, warningDay, 1)
            }
        })
        const releasedDatesParsed = releasedDatesArr.map(e => {
            const [year, month, day] = e.split('-')
            return {
                title: 'released',
                startDate: new Date(year, month - 1, day),
                endDate: new Date(year, month - 1, day, 1)
            }
        })
        const confirmedParsed =
            confirmedDatesArr.length > 0
                ? confirmedDatesArr.map(e => {
                      const [year, month, day] = e.split('-')
                      return {
                          title: 'confirmed',
                          startDate: new Date(year, month - 1, day, 1),
                          endDate: new Date(year, month - 1, day, 2)
                      }
                  })
                : []
        setShifts([...warningDates, ...releasedDatesParsed, ...confirmedParsed])
    }

    useEffect(() => {
        if (isManagerFor[employmentId] || canEditShiftFor[employmentId] || userInfo.view_only) {
            console.log('test')
            getManagerCalendar()
        } else {
            AxiosWithAuth.get('/shifts/monthly', {
                params: { employment_id: employmentId, date: calendarYearMonth + '-01' }
            }).then(res => {
                const confirmedShifts = []
                const releasedDates = res.data?.released_shift_dates
                setReleasedDates(releasedDates.map(date => moment(date).format('YYYY-MM-DD')))
                if (res.data?.confirmed_or_released_shifts?.[employmentId]) {
                    Object.entries(res.data?.confirmed_or_released_shifts[employmentId])?.map(shift => {
                        if (releasedDates.includes(shift[0])) {
                            const [start, end] = shift[1]['shift_time']
                            const startDate = new Date(shift[0] + ' ' + start)
                            const endDate = new Date(shift[0] + ' ' + end)
                            endDate < startDate && endDate.setDate(endDate.getDate() + 1)
                            confirmedShifts.push({
                                title: getShiftTitle(startDate, endDate),
                                startDate,
                                endDate,
                                status: shift[1]['status'],
                                is_released: shift[1]['is_released']
                            })
                        }
                    })
                }
                const warningDates = res.data.fusoku_dates.map(e => {
                    const [warningYear, warningMonth, warningDay] = e.split('-')
                    return (
                        releasedDates.includes(e) && {
                            title: 'warning',
                            startDate: new Date(warningYear, warningMonth - 1, warningDay),
                            endDate: new Date(warningYear, warningMonth - 1, warningDay, 1)
                        }
                    )
                })
                setShifts([...confirmedShifts, ...warningDates])
            })
        }
    }, [isManagerFor, canEditShiftFor, employmentId, userInfo])

    useEffect(() => {
        if (history?.location?.state?.resubmit) {
            setResubmit(true)
        }
        if (history?.location?.pathname?.split('/')[1] === 'checkshift') {
            setCheckShift(true)
        }
    }, [history])

    if (submitted) {
        return (
            <div
                style={{
                    height: 'calc(100vh - 56px)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '0 1rem'
                }}
            >
                <SubmitSvg />
                <Typography variant="header1" sx={{ fontSize: '20px' }}>
                    シフトを送信しました。
                </Typography>
                <Button
                    onClick={() => history.push(PATHS.home)}
                    variant="contained"
                    color="paloBlue"
                    fullWidth
                    sx={{ mt: '2rem', mb: '2rem', width: isMobile() ? '100%' : '20%' }}
                >
                    ホームに戻る
                </Button>
            </div>
        )
    }

    return (
        <PageWithTitleLayout title={checkShift ? 'シフトを確認する' : 'シフト表を作成する'}>
            <Box sx={{ mx: isMobile() ? '0%' : '20%' }}>
                <SelectedStore disabled={true} />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1" margin="1rem">
                        {calendarYearMonth.split('-')[0]}年{parseInt(calendarYearMonth.split('-')[1])}月
                    </Typography>

                    {selectDates && (
                        <div>
                            <Button
                                sx={{ mr: 1 }}
                                color="paloBlue"
                                variant="outlined"
                                onClick={() => {
                                    setSelectedDates(days)
                                }}
                            >
                                全て選択
                            </Button>
                            <Button
                                sx={{ mr: 1 }}
                                color="paloBlue"
                                variant="contained"
                                onClick={() => {
                                    setSelectDates(false)
                                    setSelectedDates([])
                                }}
                            >
                                戻る
                            </Button>
                        </div>
                    )}
                </div>
                {checkShift && !isManagerFor[employmentId] && !canEditShiftFor[employmentId] && !userInfo.view_only ? (
                    <>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div className="legend-key">
                                <Circle fontSize="8px" color="confirmed" />
                                <Typography sx={{ fontSize: 12, ml: 1 }}>シフト確定</Typography>
                            </div>
                        </div>
                        <Divider sx={{ mb: '1rem' }} />
                    </>
                ) : null}
                <Paper sx={{ margin: window.innerWidth > 381 ? '1rem' : '1rem 0' }}>
                    <Scheduler data={shifts} firstDayOfWeek={1}>
                        <ViewState
                            currentDate={new Date(calendarYearMonth.split('-')[0], calendarYearMonth.split('-')[1] - 1)}
                        />
                        <MonthView dayScaleCellComponent={CustomHeader} timeTableCellComponent={CustomCell} />
                        <Appointments
                            appointmentComponent={
                                isManagerFor[employmentId] || canEditShiftFor[employmentId] || userInfo.view_only
                                    ? ManagerAppointment
                                    : CustomAppointment
                            }
                            appointmentContentComponent={customAppointmentContent}
                        />
                    </Scheduler>
                </Paper>
            </Box>
            {(isManagerFor[employmentId] || canEditShiftFor[employmentId]) && (
                <Button
                    onClick={() => {
                        if (selectDates && selectedDates.length > 0) {
                            setDialogue(true)
                        } else {
                            setSelectDates(true)
                        }
                    }}
                    disabled={selectDates && selectedDates.length === 0}
                    variant="contained"
                    color="paloBlue"
                    sx={{ mx: isMobile() ? '1rem' : '20%', mt: 'auto', mb: '2rem' }}
                >
                    {selectDates ? 'シフトを送信する' : 'シフト送信日を選択する'}
                </Button>
            )}
            <Dialog open={dialogue}>
                <DialogTitle>{resubmit ? 'シフトの再送信' : 'シフトの送信'}</DialogTitle>
                <DialogContent>
                    {resubmit ? 'シフトを再送信します。よろしいですか？' : 'シフトを送信します。よろしいですか？'}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setDialogue(false)}>
                        キャンセル
                    </Button>
                    <Button onClick={handleFinalize} autoFocus>
                        送信する
                    </Button>
                </DialogActions>
            </Dialog>
        </PageWithTitleLayout>
    )
}

export default CheckFinalizedCalendar
