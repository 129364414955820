import { useRef, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'

const useDebounce = (callback, timeout = 500) => {
    const ref = useRef

    useEffect(() => {
        ref.current = callback
    }, [callback])

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.()
        }

        return debounce(func, timeout)
    }, [])

    return debouncedCallback
}

export default useDebounce
