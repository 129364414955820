export const validateHiraganaKatakana = (text = '') => {
    return text.match('^[ぁ-ゔゞァ-・ヽヾ゛゜ー]+$')
}

export const validateKanjiHiraganaKatakana = (text = '') => {
    if (text.split(' ').join('') === '') return false
    let result = true
    text.split('').forEach(char => {
        if (
            !char.match('^[一-龠ぁ-ゔゞァ-・ヽヾ゛゜ー]+$') &&
            !char.match(
                /([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu
            )
        ) {
            result = false
        }
    })
    return result
}

export const validateNumbers = (text = '') => {
    return text.match('^[0-9]*$')
}
export const validateEmail = (text = '') => {
    const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,3}$/)
    if (emailRegex.test(text)) {
        return true
    }
    return false
}
export const validatePhone = (text = '') => {
    return text.match(/^[0-9]{9,11}$/)
}
export const validatePassword = (text = '') => {
    return text.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{10,}$/)
}
