import React, { useState } from 'react'
import { TextField, Stack, Button } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'

const EditPhone = ({ current, handleReturn, propsId }) => {
    const { userId } = useParams()
    const [newValue, setNewValue] = useState('')
    const [errors, setErrors] = useState({})

    return (
        <PageWithTitleLayout title="電話番号の変更">
            <Stack spacing={2} sx={{ m: '1rem' }} className="shrinkable">
                <TextField
                    label="現在の電話番号"
                    InputProps={{
                        readOnly: true
                    }}
                    value={current}
                />

                <TextField
                    label="新しい電話番号 "
                    placeholder={`例：${current}`}
                    helperText={errors.phone || '半角数字(ハイフンなし)'}
                    type="tel"
                    inputProps={{ pattern: '[0-9]*' }}
                    InputLabelProps={{ shrink: true }}
                    value={newValue}
                    onChange={e => setNewValue(e.target.value)}
                    error={errors.phone ? true : false}
                />
            </Stack>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        className="shrinkable"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                    >
                        変更して保存する
                    </Button>
                }
                title="電話番号の変更"
                content="電話番号を変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    AxiosWithAuth.put('/users/' + (userId || propsId), {
                        phone: newValue
                    })
                        .then(res => {
                            res.status == 200 && handleReturn()
                        })
                        .catch(error => {
                            const errors = {}
                            for (const [key, value] of Object.entries(error?.response?.data)) {
                                errors[key] = value.join(' ')
                            }
                            setErrors(errors)
                        })
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditPhone
