import React, { useContext } from 'react'
import { Typography, List, ListItem, ListItemIcon, Box } from '@mui/material'
import { LocationOnOutlined, KeyboardArrowRight } from '@mui/icons-material/'
import { PATHS } from '../../constants'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { changeSelectedStore } from '../../Utilities/authenticationService'

const SelectStore = ({ history }) => {
    const { userEmployments: employments } = useContext(EmploymentsContext)

    return (
        <PageWithTitleLayout title="希望シフトを提出する">
            <Box className="shrinkable">
                <Typography variant="subtitle2" margin="1rem" marginTop=".5rem" marginBottom=".5rem">
                    希望シフトを提出する店舗を選択してください。
                </Typography>
                <List sx={{ mx: '1rem' }}>
                    {employments.map(e => {
                        return (
                            <ListItem
                                key={e.id}
                                onClick={() => {
                                    changeSelectedStore(e.id)
                                    history.push(PATHS.stores + e.id)
                                }}
                            >
                                <ListItemIcon>
                                    <LocationOnOutlined />
                                </ListItemIcon>
                                {e.store.name}
                                <KeyboardArrowRight sx={{ ml: 'auto' }} />
                            </ListItem>
                        )
                    })}
                </List>
            </Box>
        </PageWithTitleLayout>
    )
}

export default SelectStore
