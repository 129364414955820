import React, { useEffect, useState } from 'react'
import { Document, Page, View, Text, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material'
import { DownloadOutlined } from '@mui/icons-material'
import { stitchShifts, getTotalShiftHours, timezoneOffsets } from '../../Utilities/shiftUtils'
import PdfEmploymentTable from './PdfEmploymentTable'
import yu from '../../fonts/yumin.ttf'
import PdfSalesTable from './PdfSalesTable'
import moment from 'moment'
import PdfFooter from './PdfFooter'
import { getOpsHoursAndStoreHours } from '../../Utilities/pdfExportUtils'

export const priceFormatter = price => {
    return price ? String(Math.round(price)).slice(0, -3) : '-'
}

const PdfExportButton = ({
    shiftDate,
    employments,
    shifts,
    predictedSales,
    store,
    staffRequirements,
    storeHour,
    dailyNotes,
    missingStaffHours,
    hoursWithStaffReq,
    totalActualStaff
}) => {
    const [dialogue, setDialogue] = useState(false)
    const [hourlyPleds, setHourlyPreds] = useState([])
    const [stitchedShifts, setStichedShifts] = useState([])
    const [storeHours, setStoreHours] = useState([])
    const [opsHours, setOpsHours] = useState([])
    const [staffPerHour, setStaffPerHour] = useState({})
    const [employmentShifts, setEmploymentShifts] = useState([])
    const [pageBreak, setPageBreak] = useState(false)
    const [hourlyEmpSalesPred, setHourlyEmpSalesPred] = useState()
    const [startHour, setStartHour] = useState()
    const [endHour, setEndHour] = useState()
    const [hours, setHours] = useState()
    const [leftColWidth, setLeftColWidth] = useState()

    useEffect(() => {
        if (opsHours.length > 0) {
            const start = opsHours[0] || 7
            const end = opsHours[1] || start + 21
            const hours = Array.from(Array(end - start), (x, i) => i + start)
            // we need 21 hours columns in order to fit the table, 1 column is 19.0px
            const missingWidth = (21 - (hours[hours.length - 1] - hours[0] + 1)) * 19.0
            const leftColWidth = 130.0 + missingWidth + 'px'

            setStartHour(start)
            setEndHour(end)
            setHours(hours)
            setLeftColWidth(leftColWidth)
        }
    }, [opsHours])

    useEffect(() => {
        if (employments.length > 0 && stitchedShifts) {
            let empShifts = employments
                .map(employment => {
                    const shifts = stitchedShifts?.filter(shift => {
                        return shift?.employment_id === employment?.id
                    })
                    const totalShiftHours = getTotalShiftHours(shifts)
                    return {
                        ...employment,
                        shifts,
                        totalShiftHours: totalShiftHours || '公'
                    }
                })
                .filter(employment => employment.shifts.length > 0)
            const emptyRows = []
            for (let i = 0; i < 2; i++) {
                emptyRows.push({
                    key: `empty-${i}`,
                    user: {
                        name_first: ''
                    },
                    shifts: []
                })
            }
            setEmploymentShifts([...empShifts, ...emptyRows])
            setPageBreak(empShifts.length >= 30)
        }
    }, [employments, stitchedShifts])

    useEffect(() => {
        if (storeHour && storeHour.open_time && storeHour.close_time) {
            getOpsHoursAndStoreHours(storeHour, setOpsHours, setStoreHours)
        }
    }, [storeHour])
    useEffect(() => {
        if (store?.id && shifts) {
            setStichedShifts(stitchShifts(shifts, timezoneOffsets[store?.time_zone], true))

            const staffPerHour = {}
            shifts.forEach(shift => {
                const start = moment(shift.startDate)
                staffPerHour[start.hours()] = staffPerHour[start.hours()] ? staffPerHour[start.hours()] + 0.25 : 0.25
            })
            setStaffPerHour(staffPerHour)
        }
    }, [store, shifts])
    useEffect(() => {
        if (staffRequirements.length > 0 && missingStaffHours) {
            const preds = []
            staffRequirements.forEach(staff => {
                const { hour, pred_sales, customer_preds, customer_staff_required, staff_required } = staff
                preds[hour] = {
                    pred_sales: customer_preds || pred_sales,
                    staff_required: customer_staff_required || staff_required
                }
            })
            Object.entries(missingStaffHours).forEach(([time, val]) => {
                const hour = time.split('-')[3]
                const missing = val > 0 ? val : 0
                preds[parseInt(hour)] = {
                    ...preds[parseInt(hour)],
                    missing
                }
            })
            setHourlyPreds(preds)
        }
    }, [staffRequirements, missingStaffHours])
    useEffect(() => {
        if (predictedSales.length > 0 && Object.keys(hoursWithStaffReq).length > 0) {
            setHourlyEmpSalesPred(Math.round(predictedSales[0] / hoursWithStaffReq?.fullCount).toLocaleString('ja-JP'))
        }
    }, [predictedSales, hoursWithStaffReq])
    const doc = (
        <Document>
            <Page style={styles.body}>
                <View style={styles.header}>
                    <Text style={styles.headerText}>ワークスケジュール表</Text>
                </View>
                <View style={{ ...styles.header, marginTop: 0 }}>
                    <Text style={{ fontSize: '12px' }}>
                        {moment(shiftDate, 'YYYY-MM-DD').format('YYYY年 M月D日 ddd')}{' '}
                        {storeHours.length > 0 ? `営業時間：${storeHours[0]}〜${storeHours[1]}` : ''}
                    </Text>
                </View>
                <PdfSalesTable predictedSales={predictedSales} store={store} dailyNotes={dailyNotes} />
                <PdfEmploymentTable
                    employmentShifts={employmentShifts}
                    shiftDate={shiftDate}
                    startHour={startHour}
                    endHour={endHour}
                    leftColWidth={leftColWidth}
                    HOURS={hours}
                />
                <PdfFooter
                    preds={hourlyPleds}
                    predictedSales={predictedSales}
                    nextPage={pageBreak}
                    hoursWithStaffReq={hoursWithStaffReq}
                    hourlyEmpSalesPred={hourlyEmpSalesPred}
                    leftColWidth={leftColWidth}
                    HOURS={hours}
                    staffPerHour={staffPerHour}
                    totalActualStaff={totalActualStaff}
                />
            </Page>
        </Document>
    )

    return (
        <>
            <IconButton aria-label="download schedule" onClick={() => setDialogue(true)}>
                <DownloadOutlined />
            </IconButton>
            <Dialog open={dialogue}>
                <DialogTitle>{'シフト表の出力'}</DialogTitle>
                <DialogContent>{'シフト表をPDF形式で出力します。よろしいですか？'}</DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setDialogue(false)}>
                        {'キャンセル'}
                    </Button>
                    <PDFDownloadLink document={doc} fileName={`${shiftDate}-schedule.pdf`}>
                        {({ loading, error }) => {
                            if (error) {
                                return 'エラーが発生しました。'
                            }
                            return loading ? (
                                ''
                            ) : (
                                <Button
                                    onClick={() => {
                                        setDialogue(false)
                                    }}
                                    autoFocus
                                >
                                    {'出力する'}
                                </Button>
                            )
                        }}
                    </PDFDownloadLink>
                </DialogActions>
            </Dialog>

            {/* Hidden PDFDownloadLink that triggers the font download. This is needed so that the font downloads 
                when the button is rendered and does not wait for the button to be clicked. */}
            <div style={{ display: 'none' }}>
                <PDFDownloadLink document={<DummyDocument />} fileName="dummy.pdf">
                    {({ loading }) => (loading ? 'Loading document...' : 'Download now!')}
                </PDFDownloadLink>
            </div>
        </>
    )
}

// Dummy document that uses the font
const DummyDocument = () => (
    <Document>
        <Page>
            <Text style={{ fontFamily: 'Yu Mincho' }}>Dummy</Text>
        </Page>
    </Document>
)

const styles = StyleSheet.create({
    body: {
        padding: 10,
        fontFamily: 'Yu Mincho'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        margin: '7.5px auto 0px'
    },
    headerText: {
        fontSize: '14px',
        fontWeight: 'bold'
    }
})

Font.register({
    family: 'Yu Mincho',
    src: yu
})

export default PdfExportButton
