import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { HttpClient as HttpClientIntegration } from '@sentry/integrations'

if (process.env.REACT_APP_ENVIRONMENT === 'staging' || process.env.REACT_APP_ENVIRONMENT === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new BrowserTracing(),
            new HttpClientIntegration({
                // This array can contain tuples of `[begin, end]` (both inclusive),
                // single status codes, or a combination of both.
                // default: [[500, 599]]
                failedRequestStatusCodes: [[500, 599]]
            })
        ],
        environment: process.env.REACT_APP_ENVIRONMENT || 'development',
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5
    })
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
