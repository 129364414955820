import React, { useEffect, useState, useMemo } from 'react'
import {
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Autocomplete,
    TextField,
    Alert
} from '@mui/material'
import { LocationOnOutlined, DeleteOutlined, AddOutlined } from '@mui/icons-material/'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'
import { PATHS } from '../../../constants'
import { isMobile } from '../../../Utilities/isMobile'

const EditStores = ({ history, current, handleReturn, employmentInfo, fetchAndSetEmployments }) => {
    const { employmentId, userId } = useParams()
    const [employments, setEmployments] = useState(current)
    const [newStores, setNewStores] = useState([])
    const [storeList, setStoreList] = useState([])
    const [selectedStore, setSelectedStore] = useState()
    const [makingNewStore, setMakingNewStore] = useState()
    const [deletedAllEmployments, setDeletedAllEmployments] = useState(false)
    const [originalStoreCount] = useState(employments.length)

    useEffect(() => {
        AxiosWithAuth.get('/stores').then(res => setStoreList(res.data))
    }, [])

    const filteredStores = useMemo(() => {
        return storeList.filter(
            store => !employments.some(e => e.store.id == store.id || newStores.some(n => n.id == store.id))
        )
    })
    if (deletedAllEmployments) {
        return (
            <PageWithTitleLayout title="店舗情報の変更">
                <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Alert sx={{ mx: '1rem' }} severity="success">
                        ユーザーの店舗雇用情報を削除しました。
                    </Alert>
                    <Button
                        onClick={() => history.push(PATHS.home)}
                        variant="contained"
                        color="paloBlue"
                        fullWidth
                        sx={{ mt: '2rem', mb: '2rem', width: isMobile() ? '100%' : '20%' }}
                    >
                        ホームに戻る
                    </Button>
                </div>
            </PageWithTitleLayout>
        )
    }

    return !makingNewStore ? (
        <PageWithTitleLayout title="店舗情報の変更">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                {newStores.length > 0 && (
                    <Alert sx={{ width: '45rem', margin: '1rem' }} severity="warning">
                        追加した店舗は保存するまで反映されません。
                    </Alert>
                )}
            </div>

            <List sx={{ mx: '1rem' }} className="shrinkable">
                {employments.map(e => (
                    <ListItem
                        key={e.id}
                        sx={{
                            background: 'rgba(0, 0, 0, .04)',
                            mb: '1rem'
                        }}
                    >
                        <ListItemIcon>
                            <LocationOnOutlined />
                        </ListItemIcon>
                        <ListItemText>{e.store.name}</ListItemText>
                        <ListItemIcon sx={{ ml: 'auto', mr: '-1rem' }}>
                            <ButtonWithConfirmPrompt
                                component={
                                    <Button disabled={e.id == employmentId || e.id < 0}>
                                        <DeleteOutlined />
                                    </Button>
                                }
                                title="店舗の削除"
                                content="このユーザーを店舗雇用リストから外しますか？なお、一度外すと元に戻すことはできません。また、このユーザーのこの店舗におけるシフトも全て削除されます。"
                                confirmText="削除する"
                                cancelText="キャンセル"
                                handleConfirm={() => {
                                    AxiosWithAuth.delete('/employments/' + e.id).then(res => {
                                        fetchAndSetEmployments()
                                        if (res.status == 204 && employments.length > 1) {
                                            if (String(e.id) === localStorage.getItem('selected_emp')) {
                                                const newEmployments = employments.filter(emp => emp.id != e.id)
                                                AxiosWithAuth.put('/users/me', { selected_emp: newEmployments[0].id })
                                                localStorage.setItem('selected_emp', newEmployments[0].id)
                                                localStorage.setItem('submit_for', newEmployments[0].id)
                                            }
                                            handleReturn()
                                        } else {
                                            localStorage.removeItem('selected_emp')
                                            localStorage.removeItem('submit_for')
                                            setDeletedAllEmployments(true)
                                        }
                                    })
                                }}
                            />
                        </ListItemIcon>
                    </ListItem>
                ))}
            </List>
            <Button
                variant="outlined"
                color="paloBlue"
                sx={{ mx: '1rem', mb: '1rem', mt: 'auto' }}
                onClick={() => setMakingNewStore(true)}
                className="shrinkable"
            >
                <AddOutlined sx={{ mr: '.5rem' }} />
                店舗を追加する
            </Button>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        sx={{ mx: '1rem', mb: '2rem' }}
                        className="shrinkable"
                        disabled={newStores.length === 0}
                    >
                        変更して保存する
                    </Button>
                }
                title="店舗情報の変更"
                content="店舗情報を変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    const resps = []
                    newStores.forEach(async store => {
                        const res = await AxiosWithAuth.post('/employments', {
                            user_id: userId,
                            store_id: store.id,
                            role: employmentInfo.role,
                            is_manager: employmentInfo.is_manager,
                            rank: employmentInfo.rank,
                            open_close_duty: employmentInfo.open_close_duty
                        })
                        resps.push(res)
                        if (resps.length === newStores.length) {
                            if (originalStoreCount === 0) {
                                localStorage.setItem('selected_emp', resps[0].data.id)
                                localStorage.setItem('submit_for', resps[0].data.id)
                                AxiosWithAuth.put('/users/me', { selected_emp: resps[0].data.id })
                            }
                            handleReturn()
                        }
                    })
                }}
            />
        </PageWithTitleLayout>
    ) : (
        <PageWithTitleLayout title="店舗情報の変更">
            <Typography variant="subtitle2" sx={{ m: '1rem', mt: '.5rem' }} className="shrinkable">
                追加する店舗を選択してください。
            </Typography>
            <Autocomplete
                sx={{ mx: '1rem' }}
                id="select-stores"
                className="shrinkable"
                options={filteredStores}
                getOptionLabel={option => option.seg_code + ' - ' + option.name}
                isOptionEqualToValue={(option, value) => option.seg_code === value.seg_code}
                label="店舗名"
                placeholder="選択してください"
                autoComplete
                onChange={(event, newValue) => setSelectedStore(newValue)}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            label="店舗名"
                            placeholder="選択してください"
                            InputLabelProps={{ shrink: true }}
                        />
                    )
                }}
            />
            <Button
                variant="contained"
                color="paloBlue"
                sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                disabled={!selectedStore}
                onClick={() => {
                    setNewStores([...newStores, selectedStore])
                    setEmployments([...employments, { id: selectedStore.id * -1, store: selectedStore }])
                    setMakingNewStore(false)
                    setSelectedStore(null)
                }}
                className="shrinkable"
            >
                追加する
            </Button>
        </PageWithTitleLayout>
    )
}
export default EditStores
