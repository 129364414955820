import React, { useState, useMemo } from 'react'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { SendOutlined, ThumbUp, ThumbDown } from '@mui/icons-material'
import {
    Stack,
    CircularProgress,
    TextField,
    Button,
    Typography,
    Divider,
    Chip,
    ToggleButtonGroup,
    ToggleButton
} from '@mui/material'
import { displayNameWithSpace } from '../../Utilities/japaneseStringUtils'
import moment from 'moment'
import { getDays } from '../../Utilities/shiftUtils'

const chatScheduler = ({ getAllShiftsFor, employmentId, year, month }) => {
    const user = JSON.parse(localStorage.getItem('currentUser'))

    const [sessionId, setSessionId] = useState(null)
    const [feedback, setFeedback] = useState(null)

    const today = moment()
    // get all days of the week in future

    const text = useMemo(() => {
        const monday =
            moment().startOf('isoWeek') < today
                ? moment().startOf('isoWeek').add(7, 'days')
                : moment().startOf('isoWeek')
        const tuesday =
            moment().startOf('isoWeek').add(1, 'days') < today
                ? moment().startOf('isoWeek').add(8, 'days')
                : moment().startOf('isoWeek').add(1, 'days')
        const wednesday =
            moment().startOf('isoWeek').add(2, 'days') < today
                ? moment().startOf('isoWeek').add(9, 'days')
                : moment().startOf('isoWeek').add(2, 'days')
        const thursday =
            moment().startOf('isoWeek').add(3, 'days') < today
                ? moment().startOf('isoWeek').add(10, 'days')
                : moment().startOf('isoWeek').add(3, 'days')
        const friday =
            moment().startOf('isoWeek').add(4, 'days') < today
                ? moment().startOf('isoWeek').add(11, 'days')
                : moment().startOf('isoWeek').add(4, 'days')
        const saturday =
            moment().startOf('isoWeek').add(5, 'days') < today
                ? moment().startOf('isoWeek').add(12, 'days')
                : moment().startOf('isoWeek').add(5, 'days')
        const sunday =
            moment().startOf('isoWeek').add(6, 'days') < today
                ? moment().startOf('isoWeek').add(13, 'days')
                : moment().startOf('isoWeek').add(6, 'days')

        const m = monday.date()
        const t = tuesday.date()
        // eslint-disable-next-line no-unused-vars
        const w = wednesday.date()
        const th = thursday.date()
        const f = friday.date()
        const sa = saturday.date()
        const su = sunday.date()

        const daysOfWeek = getDays(`${year}-${month}`)

        const text = `You are a helpful assistant for creating a schedule for part time workers at a restaurant. The user you are speaking to is ${displayNameWithSpace(
            user
        )}. Address them with their name.
            The user will state their preferences for shifts, and if their intent is clear, you will respond with a helpful message and a DATA array. An example of the DATA format for ${year}-${month}-02 9:00 AM to ${year}-${month}-02 3:00 PM would be 02:09 - 02:15.
            The DATA array should be formatted in the following manner: STARTDATA[01:12 - 01:18, 02:12 - 02:14]ENDDATA
            Right now it is ${year}-${month}.

            Today is ${today.toDate()}
            A weekday is a day that is not Saturday or Sunday. A weekend is a Saturday or Sunday.
            ${
                today.month() + 1 === parseInt(month)
                    ? `Monday is ${monday.toDate()}, Tuesday is ${tuesday.toDate()}, Wednesday is ${wednesday.toDate()}, Thursday is ${thursday.toDate()}, Friday is ${friday.toDate()}, Saturday is ${saturday.toDate()}, Sunday is ${sunday.toDate()}`
                    : 'If a user specifies a day of the week, ask them to specify a date.'
            }
            The mondays this month are ${daysOfWeek[1]}
            The tuesdays this month are ${daysOfWeek[2]}
            The wednesdays this month are ${daysOfWeek[3]}
            The thursdays this month are ${daysOfWeek[4]}
            The fridays this month are ${daysOfWeek[5]}
            The saturdays this month are ${daysOfWeek[6]}
            The sundays this month are ${daysOfWeek[0]}
            The weekends this month are ${daysOfWeek[0]}, ${daysOfWeek[6]}
            The weekdays this month are ${daysOfWeek[1]}, ${daysOfWeek[2]}, ${daysOfWeek[3]}, ${daysOfWeek[4]}, ${
            daysOfWeek[5]
        }
            Here are some examples of the input you will receive and the output you should give:
            INPUT (translation) -> OUTPUT
            I would like to work on Monday from 9am to 5pm.	(月曜日の午前9時から午後5時まで働きたいです。) -> STARTDATA[${m}:09 - ${m}:17]ENDDATA
            I am available to work from 3pm to 11pm on Thursday.	(木曜日の午後3時から午後11時まで働くことができます。) -> STARTDATA[${th}:15 - ${th}:23]ENDDATA
            I am open for work on Saturday, 8am to 4pm.	(土曜日の午前8時から午後4時まで働くことができます。) -> STARTDATA[${sa}:08 - ${sa}:16]ENDDATA
            I'd prefer to work on Sunday from 7am to 3pm.	(日曜日の午前7時から午後3時まで働くことを希望します。) -> STARTDATA[${su}:07 - ${su}:15]ENDDATA
            Put me down for the 10am to 6pm shift next Monday.	(次の月曜日の午前10時から午後6時までのシフトに入れてください。) -> STARTDATA[${m}:10 - ${m}:18]ENDDATA
            I would like to request the 11am to 7pm shift on Sunday.	(日曜日の午前11時から午後7時までのシフトを希望します。)-> STARTDATA[${su}:11 - ${su}:19]ENDDATA
            I want to work from 1pm to 9pm on Tuesday.	(火曜日の午後1時から午後9時まで働きたいです。) -> STARTDATA[${t}:13 - ${t}:21]ENDDATA
            I can do the 12pm to 8pm shift on Thursday.	(木曜日の午後12時から午後8時までのシフトが可能です。) -> STARTDATA[${th}:12 - ${th}:20]ENDDATA
            I would like the morning shift every Tuesday.	(毎週火曜日の朝のシフトを希望します。) -> STARTDATA[${daysOfWeek[2].map(
                date => `${date}:09 - ${date}:12`
            )}]ENDDATA
            I am available to work from 3pm to 11pm every Thursday.	(毎週木曜日の午後3時から午後11時まで働けます。) -> STARTDATA[${th}:15 - ${th}:23]ENDDATA
            I am open to work from 8am to 4pm every weekend.	(毎週末の午前8時から午後4時まで働けます。) -> STARTDATA[${daysOfWeek[0]
                .concat(daysOfWeek[6])
                .map(date => `${date}:08 - ${date}:16`)}]ENDDATA
            I'd prefer to work on Sundays from 9am to 3pm every week.	(毎週日曜日の午前9時から午後3時まで働きたいです。) -> STARTDATA[${daysOfWeek[6].map(
                date => `${date}:09 - ${date}:15`
            )}]ENDDATA
            Please put me down for the 10am to 6pm shift each Monday.	(毎週月曜日の午前10時から午後6時までのシフトに予定してください。) -> STARTDATA[${m}:10 - ${m}:18]ENDDATA
            Is it possible for me to work from 2pm to 10pm every Tuesday?	(毎週火曜日の午後2時から午後10時まで働けますか？) -> STARTDATA[${t}:14 - ${t}:22]ENDDATA
            I would like to request the 11am to 7pm shift every Sunday.	(毎週日曜日の午前11時から午後7時までのシフトを希望します。) -> STARTDATA[${su}:11 - ${su}:19]ENDDATA
            I'd like to work from 1pm to 9pm every Tuesday.	(毎週火曜日の午後1時から午後9時まで働きたいです。) -> STARTDATA[${t}:13 - ${t}:21]ENDDATA
            I can do the 12pm to 8pm shift every Thursday.	(毎週木曜日の午後12時から午後8時までのシフトができます。) -> STARTDATA[${th}:12 - ${th}:20]ENDDATA
            Is the 4pm to midnight shift available every Friday?	(毎週金曜日の午後4時から深夜までのシフトは可能ですか？ ) -> STARTDATA[${f}:16 - ${f}:24]ENDDATA
            `
        return text
    }, [year, month])

    const localizedRoles = {
        user: 'ユーザー',
        assistant: 'アシスタント'
    }

    // example
    // STARTJSON ['20230501 09:00 - 20230501 17:00', '20230502 09:00 - 20230502 17:00', '20230503 09:00 - 20230503 17:00', '20230504 09:00 - 20230504 17:00', '20230505 09:00 - 20230505 17:00', '20230508 09:00 - 20230508 17:00', '20230509 09:00 - 20230509 17:00', '20230510 09:00 - 20230510 17:00', '20230511 09:00 - 20230511 17:00', '20230512 09:00 - 20230512 17:00', '20230515 09:00 - 20230515 17:00', '20230516 09:00 - 20230516 17:00', '20230517 09:00 - 20230517 17:00', '20230518 09:00 - 20230518 17:00', '20230519 09:00 - 20230519 17:00', '20230522 09:00 - 20230522 17:00', '20230523 09:00 - 20230523 17:00', '20230524 09:00 - 20230524 17:00', '20230525 09:00 - 20230525 17:00', '20230526 09:00 - 20230526 17:00', '20230529 09:00 - 20230529 17:00', '20230530 09:00 - 20230530 17:00', '20230531 09:00 - 20230531 17:00'] ENDJSON
    const systemPrompt = { role: 'system', content: text }

    const [chatHistory, setChatHistory] = useState([])
    const [responseLoading, setResponseLoading] = useState(false)
    const [input, setInput] = useState('')
    const [createdIds, setCreatedIds] = useState([])

    const generateAPIRequest = user_message => {
        // put together systemPrompt, chatHistory, and input into JSON array
        return JSON.stringify([systemPrompt, ...chatHistory, { role: 'user', content: user_message }])
    }

    const parseAndSubmitShifts = async (message, session_id) => {
        const { content } = message
        const start = content.indexOf('STARTDATA') + 9
        const end = content.indexOf('ENDDATA')
        const array = content.slice(start, end).replaceAll(' ', '').replaceAll('[', '').replaceAll(']', '').split(',')
        if (array.length > 50 || array.length < 1) {
            setChatHistory(prev => {
                return [
                    ...prev,
                    {
                        role: 'assistant',
                        content: `チャットを通して一気に50個以上のシフトは作成できません。(You cannot create more than 50 shifts at once using the chat interface)`
                    }
                ]
            })
            return
        }
        if (createdIds.length > 0) {
            await AxiosWithAuth.delete('/multishifts', {
                data: { shift: { ids: createdIds } }
            })
        }
        const created = []
        array.forEach(shift => {
            const [first, second] = shift.split('-')
            console.log(first, second)
            const startTime = moment(first, 'DD:HH')
                .set('month', parseInt(month) - 1)
                .set('year', year)
                .toDate()
            const endTime = moment(second, 'DD:HH')
                .set('month', parseInt(month) - 1)
                .set('year', year)
                .toDate()
            console.log(startTime, endTime)
            // only submit if startTime is in current month
            if (moment(startTime).month() !== parseInt(month) - 1) {
                return
            }
            AxiosWithAuth.post('/shifts', {
                employment_id: employmentId,
                start_time: startTime,
                end_time: endTime,
                status: 'available',
                submitted_by: employmentId,
                chat_session_id: session_id
            }).then(res => {
                created.push(res.data.id)
                if (created.length === array.length) {
                    setCreatedIds(created)
                    getAllShiftsFor(employmentId)
                }
            })
        })
    }

    const handleSubmit = e => {
        const user_message = input
        setInput('')
        e.preventDefault()
        setChatHistory(prev => {
            return [...prev, { role: 'user', content: user_message }]
        })
        setResponseLoading(true)
        AxiosWithAuth.post('/openai/chat_completions', {
            messages: generateAPIRequest(user_message),
            session_id: sessionId
        })
            .then(res => {
                const messages = res.data.response

                if (res.data.session_id) {
                    setSessionId(res.data.session_id)
                }

                setChatHistory(prev => {
                    setResponseLoading(false)
                    return [...prev, { role: 'assistant', content: messages.choices[0].message.content }]
                })
                if (messages.choices[0].message.content.includes('STARTDATA')) {
                    parseAndSubmitShifts(messages.choices[0].message, res.data.session_id)
                }
            })
            .catch(err => {
                setChatHistory(prev => {
                    setResponseLoading(false)
                    return [...prev, { role: 'assistant', content: err.response.data.error }]
                })
            })
    }

    const handleFeedback = (e, value) => {
        setFeedback(value)
        AxiosWithAuth.put('/chat_sessions/' + sessionId, {
            chat_session: {
                feedback: value
            }
        })
    }

    return (
        <div
            style={{
                margin: '1rem auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%'
                }}
            >
                <Chip style={{ marginRight: '1rem' }} label="EXPERIMENTAL" />
                <Typography>
                    チャット形式で予定表を提出できます。たとえば、「平日の午後５時から１１時」とチャットしてみてください。
                </Typography>
            </div>
            <Stack style={{ margin: '1rem' }}>
                {chatHistory.map((message, index) => {
                    return (
                        <div key={index}>
                            {index > 0 && <Divider style={{ margin: '.5rem 0' }} />}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '10rem', textAlign: 'right' }}>
                                    {localizedRoles[message.role]}：
                                </div>
                                <div>{message.content.replace(/STARTDATA.*ENDDATA/, '')}</div>
                            </div>
                        </div>
                    )
                })}
            </Stack>
            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    maxHeight: '6rem',
                    position: 'relative',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {responseLoading && <CircularProgress />}
                <TextField
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    style={{ width: '25rem', marginRight: '1rem' }}
                />
                <Button variant="contained" color="paloBlue" type="submit" endIcon={<SendOutlined />}>
                    送信
                </Button>

                {sessionId && (
                    <ToggleButtonGroup
                        value={feedback}
                        onChange={handleFeedback}
                        exclusive={true}
                        size="small"
                        style={{ maxHeight: '3rem', position: 'absolute', right: '1rem' }}
                    >
                        <ToggleButton value="good" key="good">
                            <ThumbUp />
                        </ToggleButton>
                        <ToggleButton value="bad" key="bad">
                            <ThumbDown />
                        </ToggleButton>
                    </ToggleButtonGroup>
                )}
            </form>
        </div>
    )
}

export default chatScheduler
