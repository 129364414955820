import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MenuItem, ListItemIcon, Select } from '@mui/material'
import { LocationOnOutlined } from '@mui/icons-material/'
import { EmploymentsContext } from '../contexts/EmploymentsContext'
import { useHistory } from 'react-router-dom'
import { changeSelectedStore } from '../Utilities/authenticationService'
import { isMobile } from '../Utilities/isMobile'

const SelectedStore = ({ disabled }) => {
    const { userEmployments, canEditShiftFor, isManagerFor } = useContext(EmploymentsContext)
    const [selectedStore, setSelectedStore] = useState()
    const { employmentId } = useParams()
    const history = useHistory()

    useEffect(() => {
        const selected_employment_id = parseInt(localStorage.selected_emp)
        if (!userEmployments) return
        setSelectedStore(
            userEmployments.find(employment => employment.id === selected_employment_id) || userEmployments[0]
        )
    }, [userEmployments])

    const handleChange = e => {
        setSelectedStore(userEmployments.find(employment => employment.id === e.target.value))
        changeSelectedStore(e.target.value)
        history.replace(history.location.pathname.replace(`${employmentId}`, `${e.target.value}`))
    }

    const disableOption = employment => {
        if (!history.location.pathname.includes('/createshift')) return false
        if (canEditShiftFor[employment.id] || isManagerFor[employment.id]) return false
        return true
    }

    if (selectedStore)
        return (
            <div
                style={{
                    margin: '.5rem auto',
                    background: 'rgba(0, 0, 0, .04)',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: isMobile() ? '95%' : '620px'
                }}
            >
                <Select
                    disabled={disabled}
                    size="small"
                    sx={{ border: 'none', mx: 'auto' }}
                    fullWidth
                    defaultValue=""
                    value={selectedStore.id} // really doesn't like objects as values
                    onChange={handleChange}
                >
                    {userEmployments.map(employment => (
                        <MenuItem
                            value={employment.id}
                            key={employment.id}
                            disabled={disableOption(employment)}
                            sx={{
                                background: 'rgba(0, 0, 0, .04)'
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <ListItemIcon sx={{ minWidth: '2.5rem' }}>
                                    <LocationOnOutlined />
                                </ListItemIcon>
                                {employment?.store?.name}
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </div>
        )
    return <></>
}

export default SelectedStore
