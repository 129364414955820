import React, { useState } from 'react'
import { TextField, Button, Autocomplete } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'

const EditRank = ({ current, handleReturn, employments = [] }) => {
    const { userEmpId } = useParams()
    const [newValue, setNewValue] = useState(current)

    return (
        <PageWithTitleLayout title="ランクの変更">
            <Autocomplete
                sx={{ m: '1rem' }}
                id="select-work-type"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={['A', 'B', 'T']}
                label="ランク"
                placeholder="選択してください"
                value={newValue}
                onChange={(event, nv) => setNewValue(nv)}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            label="ランク"
                            placeholder="選択してください"
                            InputLabelProps={{ shrink: true }}
                        />
                    )
                }}
                className="shrinkable"
            />
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                        disabled={!newValue}
                        className="shrinkable"
                    >
                        変更して保存する
                    </Button>
                }
                title="ランクの変更"
                content="ランクを変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    if (employments.length > 0) {
                        // uses array of employment ids if given through props instead of params
                        employments.forEach(e => {
                            AxiosWithAuth.put('/employments/' + e, {
                                rank: newValue
                            }).then(res => {
                                res.status == 200 && handleReturn()
                            })
                        })
                    } else {
                        AxiosWithAuth.put('/employments/' + userEmpId, {
                            rank: newValue
                        }).then(res => {
                            res.status == 200 && handleReturn()
                        })
                    }
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditRank
