import React, { useState, useMemo } from 'react'
import { TextField, Stack, Button } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'

const EditPhone = ({ current, handleReturn, propsId }) => {
    const { userId } = useParams()
    const [newValue, setNewValue] = useState('')
    const [error, setError] = useState()
    const [errorMessage, setErrorMessage] = useState('')

    const same = useMemo(() => current === newValue)

    return (
        <PageWithTitleLayout title="電話番号の変更">
            <Stack spacing={2} className="input-box shrinkable">
                <TextField
                    label="現在の電話番号"
                    InputProps={{
                        readOnly: true
                    }}
                    value={current}
                    sx={{ marginTop: '1rem', width: '100%' }}
                />

                <TextField
                    label="新しい電話番号"
                    placeholder="例：0000000"
                    helperText={errorMessage || '半角数字'}
                    InputLabelProps={{ shrink: true }}
                    value={newValue}
                    onChange={e => {
                        const regex = /^[0-9\b]+$/
                        if (e.target.value === '' || regex.test(e.target.value)) {
                            setNewValue(e.target.value)
                        }
                    }}
                    error={error}
                />
            </Stack>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        disabled={same}
                        variant="contained"
                        color="paloBlue"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                        className="shrinkable"
                    >
                        変更して保存する
                    </Button>
                }
                title="電話番号の変更"
                content="電話番号を変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    AxiosWithAuth.put('/users/' + (userId || propsId), {
                        phone: newValue
                    })
                        .then(res => {
                            res.status == 200 && handleReturn()
                        })
                        .catch(err => {
                            setError(true)
                            setErrorMessage(err.response?.data?.phone[0])
                        })
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditPhone
