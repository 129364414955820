import React from 'react'
import propTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { authenticationService } from '../../Utilities/authenticationService'
import { PATHS } from '../../constants'
import { Layout } from '..'
import { Snackbar, Alert } from '@mui/material'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [snackbar, setSnackbar] = React.useState({}) // { message: string, severity: string success|error|warning|info }

    return (
        <Route
            {...rest}
            render={props => {
                const currentUser = authenticationService.currentUserValue
                if (!currentUser) {
                    // not logged in so redirect to login page with the return url
                    return (
                        <Redirect
                            to={{
                                pathname: PATHS.login,
                                state: { from: props.location }
                            }}
                        />
                    )
                }
                return (
                    <Layout>
                        <Snackbar
                            open={snackbar.message?.length > 0}
                            autoHideDuration={10000}
                            onClose={() => setSnackbar({})}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <Alert severity={snackbar.severity || 'success'} onClose={() => setSnackbar({})}>
                                {snackbar.message}
                            </Alert>
                        </Snackbar>
                        <Component {...props} setSnackbar={setSnackbar} />
                    </Layout>
                )
            }}
        />
    )
}

PrivateRoute.propTypes = {
    location: propTypes.object,
    component: propTypes.func,
    disableBorder: propTypes.bool,
    onLogout: propTypes.func
}

export default PrivateRoute
