import React from 'react'
import { TableContainer, TableBody, TableCell, TableHead, Table, TableRow } from '@mui/material'
import { PeopleAlt } from '@mui/icons-material'
import { ERROR_COLOR, PALO_BLUE } from '../../../constants'
import '../checkShift.css'
import moment from 'moment'
import { mobileBaseCellStyle, mobileHeaderColumnStyle, mobileHeaderRowStyle } from './calendarStyles'
import useLongPress from '../../../Utilities/longpress'
import { displayNameWithLimit } from '../../../Utilities/japaneseStringUtils'

const MobileCalendar = ({
    timeChunks,
    getStaffRequired,
    checkIfFullyStaffed,
    staffRequirements,
    employments,
    shifts,
    nonConfirmedShifts,
    computeColor,
    isManagerFor,
    userInfo,
    employmentId,
    canEditShiftFor,
    handleCalendarClick,
    handleLongPress
}) => {
    const [onStart, onEnd] = useLongPress(handleLongPress, 500)

    const specialViewingPerms = isManagerFor[employmentId] || canEditShiftFor[employmentId] || userInfo.view_only
    
    const MobileTimeScale = ({ time }) => {
        let staffRequired
        let isFullyStaffed
        let shiftCount

        if (time) {
            ;[staffRequired] = getStaffRequired(time.getHours())
            ;[isFullyStaffed, shiftCount] = checkIfFullyStaffed(time.getDate(), time.getHours(), staffRequired)
        }
        
        const insufficient = specialViewingPerms && !isFullyStaffed
        const moreThanRequired = specialViewingPerms && shiftCount - staffRequired > 0

        return (
            <TableCell sx={{ ...mobileHeaderColumnStyle }} align="center">
                {time && time.getMinutes() === 0 && <div>{moment(time).format('HH:mm')}</div>}
                {time && staffRequirements.length > 0 && time.getMinutes() === 15 && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '.3rem',
                            height: '1rem'
                        }}
                    >
                        <PeopleAlt
                            fontSize="string"
                            sx={{
                                color:
                                    insufficient && staffRequired
                                        ? ERROR_COLOR
                                        : moreThanRequired && staffRequired
                                        ? PALO_BLUE
                                        : 'inherit'
                            }}
                        />
                        <p
                            style={{
                                marginLeft: 5,
                                color:
                                    insufficient && staffRequired
                                        ? ERROR_COLOR
                                        : moreThanRequired && staffRequired
                                        ? PALO_BLUE
                                        : 'inherit'
                            }}
                        >
                            {shiftCount}/{staffRequired || 0}
                        </p>
                    </div>
                )}
            </TableCell>
        )
    }

    const MobileShift = ({ chunk, employment }) => {
        const employmentShifts = shifts.filter(shift => shift.employment_id === employment.id)
        const minutes = chunk.getMinutes()
        const hours = chunk.getHours()
        let shift = employmentShifts.find(shift => shift.startDate.getTime() === chunk.getTime())
        const isSubmitted = nonConfirmedShifts.find(
            s =>
                s.startDate.getTime() === shift?.startDate.getTime() &&
                s.employment_id === employment.id &&
                s.status === 'submitted'
        )
        if (!shift) {
            shift = {
                id: hours + ':' + minutes + employment.id,
                employment_id: employment.id,
                startDate: new Date(chunk),
                endDate: new Date(new Date(chunk).setMinutes(minutes + 15)),
                title: ''
            }
        }
        const color = computeColor(specialViewingPerms, shift)
        return (
            <TableCell
                key={chunk.getTime() + employment.id}
                data-testid={shift.status}
                sx={{
                    ...mobileBaseCellStyle,
                    backgroundColor: color,
                    borderBottom: chunk.getMinutes() - 45 === 0 ? '1px solid lightgray' : '1px dashed lightgray'
                }}
                onClick={() => handleCalendarClick(shift)}
                onContextMenu={e => {
                    e.preventDefault()
                    handleLongPress(shift)
                }}
                onTouchStart={() => {
                    onStart([shift])
                }}
                onTouchMove={onEnd}
                onTouchEnd={onEnd}
            >
                {shift.status === 'submitted' || shift.originalStatus === 'submitted' || isSubmitted ? (
                    <div
                        style={{ backgroundColor: '#BAC0E4', width: '3px', height: '7px', marginLeft: '2.5rem' }}
                    ></div>
                ) : null}
            </TableCell>
        )
    }

    return (
        <TableContainer sx={{ maxHeight: window.innerHeight * 0.75, maxWidth: window.innerWidth }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                ...mobileHeaderColumnStyle,
                                borderBottom: 0,
                                zIndex: 2
                            }}
                        ></TableCell>
                        {employments &&
                            employments.map(employment => (
                                <TableCell
                                    sx={{
                                        ...mobileHeaderRowStyle
                                    }}
                                    key={employment.id}
                                    align="center"
                                >
                                    <div>
                                        <div>{employment.text.props.children[2].props.children}</div>
                                        {employment.role === 'hall' ? (
                                            <div>ホール</div>
                                        ) : employment.role === 'kitchen' ? (
                                            <div>キッチン</div>
                                        ) : employment.role === 'both' ? (
                                            <div>ﾎｰﾙ&ｷｯﾁﾝ</div>
                                        ) : null}
                                    </div>
                                </TableCell>
                            ))}
                    </TableRow>
                    <TableRow>
                        <TableCell
                            sx={{
                                ...mobileHeaderColumnStyle,
                                borderTop: 0,
                                zIndex: 2
                            }}
                        ></TableCell>
                        {employments &&
                            employments.map(employment => (
                                <TableCell
                                    sx={{
                                        ...mobileHeaderRowStyle
                                    }}
                                    key={employment.id}
                                    align="center"
                                >
                                    <div>{displayNameWithLimit(employment.user)}</div>
                                    <div>{employment.user.emp_cd}</div>
                                </TableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {timeChunks.map((timeChunk, index) => (
                        <TableRow key={index}>
                            {MobileTimeScale({ time: timeChunk })}
                            {employments &&
                                employments.map(employment => {
                                    return MobileShift({ chunk: timeChunk, employment })
                                })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MobileCalendar
