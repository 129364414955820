import React, { useState } from 'react'
import { TextField, Stack, Button } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'
import { validateHiraganaKatakana } from '../../../Utilities/fieldValidators'

const EditKanaName = ({ current, handleReturn, propsId }) => {
    const { userId } = useParams()
    const [newValue, setNewValue] = useState({ nameFirst: current.nameFirst || '', nameLast: current.nameLast || '' })
    const [errors, setErrors] = useState({})

    const handleNameLast = event => {
        const text = event.target.value
        if (!validateHiraganaKatakana(text)) {
            setErrors({ ...errors, nameLast: '全角ひらがな・全角カタカナ' })
        } else {
            // remove error if it exists using destructuring
            //eslint-disable-next-line
            const { nameLast, ...rest } = errors
            setErrors(rest)
        }
        setNewValue({ ...newValue, nameLast: text })
    }

    const handleNameFirst = event => {
        const text = event.target.value
        if (!validateHiraganaKatakana(text)) {
            setErrors({ ...errors, nameFirst: '全角ひらがな・全角カタカナ' })
        } else {
            //eslint-disable-next-line
            const { nameFirst, ...rest } = errors
            setErrors(rest)
        }
        setNewValue({ ...newValue, nameFirst: text })
    }
    return (
        <PageWithTitleLayout title="氏名（かな）の変更">
            <Stack spacing={2} sx={{ m: '1rem' }} className="shrinkable">
                <TextField
                    label="姓（かな）"
                    placeholder={`例：${current.nameLast}`}
                    helperText={errors.name_last || '全角ひらがな・全角カタカナ'}
                    InputLabelProps={{ shrink: true }}
                    value={newValue.nameLast}
                    onChange={e => setNewValue({ ...newValue, nameLast: e.target.value })}
                    error={errors.name_last}
                    inputProps={{ onBlur: handleNameLast }}
                />
                <TextField
                    label="名（かな）"
                    placeholder={`例：${current.nameFirst}`}
                    helperText={errors.name_first || '全角ひらがな・全角カタカナ'}
                    InputLabelProps={{ shrink: true }}
                    value={newValue.nameFirst}
                    onChange={e => setNewValue({ ...newValue, nameFirst: e.target.value })}
                    error={errors.name_first}
                    inputProps={{ onBlur: handleNameFirst }}
                />
            </Stack>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        className="shrinkable"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                    >
                        変更して保存する
                    </Button>
                }
                title="氏名（かな）の変更"
                content="氏名（かな）を変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    AxiosWithAuth.put('/users/' + (userId || propsId), {
                        name_last: newValue.nameLast,
                        name_first: newValue.nameFirst
                    })
                        .then(res => {
                            res.status == 200 && handleReturn()
                        })
                        .catch(error => {
                            const errors = {}
                            for (const [key, value] of Object.entries(error?.response?.data)) {
                                errors[key] = value.join(' ')
                            }
                            setErrors(errors)
                        })
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditKanaName
