import { StyleSheet } from '@react-pdf/renderer'
import { PDF_TABLE_COL_WIDTHS as WIDTHS } from '../constants'

export const styles = StyleSheet.create({
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginTop: '10px'
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
        borderColor: '#000'
    },
    tableColHeader: {
        width: WIDTHS.default,
        borderStyle: 'solid',
        borderColor: '#000',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#E5E5E5'
    },
    tableCol: {
        width: WIDTHS.default,
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColFilled: {
        width: WIDTHS.default,
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#000'
    },
    tableCellHeader: {
        margin: '1px auto',
        fontSize: 8,
        fontWeight: 500,
        borderColor: '#000'
    },
    tableCell: {
        margin: '1px auto',
        fontSize: 8,
        borderColor: '#000'
    },
    page: {
        fontFamily: 'Yu Mincho'
    }
})
