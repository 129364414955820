import { useCallback, useRef } from 'react'

// yoinked this from stackoverflow and replaced the typescript stuff
export default function useLongPress(
    // callback that is invoked at the specified duration or `onEndLongPress`
    callback,
    // long press duration in milliseconds
    ms = 300
) {
    // used to persist the timer state
    // non zero values means the value has never been fired before
    const timerRef = useRef(0)

    // clear timed callback
    const endTimer = () => {
        clearTimeout(timerRef.current || 0)
        timerRef.current = 0
    }

    // init timer
    const onStartLongPress = useCallback(
        args => {
            // stop any previously set timers
            endTimer()

            // set new timeout
            timerRef.current = window.setTimeout(() => {
                callback(...args)
                endTimer()
            }, ms)
        },
        [callback, ms]
    )

    // determine to end timer early and invoke the callback or do nothing
    const onEndLongPress = useCallback(() => {
        // run the callback fn the timer hasn't gone off yet (non zero)
        if (timerRef.current) {
            endTimer()
        }
    }, [callback])

    return [onStartLongPress, onEndLongPress, endTimer]
}
