import React from 'react'

const SubmitSvg = () => {
    return (
        <svg
            width="205"
            height="205"
            viewBox="0 0 205 205"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: '6.5rem' }}
        >
            <g filter="url(#filter0_dd_1337_90279)">
                <rect
                    x="172.709"
                    y="8"
                    width="140.708"
                    height="140.708"
                    rx="13"
                    transform="rotate(90 172.709 8)"
                    fill="white"
                />
            </g>
            <g opacity="0.8">
                <rect
                    x="75.6777"
                    y="57.7329"
                    width="52"
                    height="25"
                    rx="12.5"
                    transform="rotate(45 75.6777 57.7329)"
                    fill="#9BA6FA"
                />
                <rect
                    x="145.041"
                    y="59.1438"
                    width="72.7552"
                    height="25.0751"
                    rx="12.5375"
                    transform="rotate(133.133 145.041 59.1438)"
                    fill="#4D6EFF"
                    fillOpacity="0.9"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_1337_90279"
                    x="0"
                    y="0"
                    width="204.709"
                    height="204.708"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="24" />
                    <feGaussianBlur stdDeviation="16" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"
                    />
                    <feBlend
                        mode="multiply"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1337_90279"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="16" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.196487 0 0 0 0 0.196487 0 0 0 0 0.279476 0 0 0 0.08 0"
                    />
                    <feBlend
                        mode="multiply"
                        in2="effect1_dropShadow_1337_90279"
                        result="effect2_dropShadow_1337_90279"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1337_90279"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default SubmitSvg
