import React, { useState, useContext, useEffect } from 'react'
import { Button, Box, InputAdornment, TextField, IconButton, Alert, Autocomplete } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material/'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import {
    validateHiraganaKatakana,
    validateKanjiHiraganaKatakana,
    validateNumbers,
    validateEmail,
    validatePhone,
    validatePassword
} from '../../Utilities/fieldValidators'
import * as Sentry from '@sentry/react'

const Signup = ({ setSnackbar }) => {
    const [errors, setErrors] = useState({})

    const [formState, setFormState] = useState('start') // start, success

    // managers are signing up for their employees, so the new employee's store is the manager's store
    const { userEmployments } = useContext(EmploymentsContext)

    const [employeeCode, setEmployeeCode] = useState()
    const [password, setPassword] = useState()
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [kanjiFirst, setKanjiFirst] = useState('')
    const [firstName, setFirstName] = useState()
    const [kanjiLast, setKanjiLast] = useState('')
    const [lastName, setLastName] = useState()
    const [middleName, setMiddleName] = useState()
    const [nickname, setNickname] = useState()
    const [showPassword, setShowPassword] = useState(false)
    const [email, setEmail] = useState()
    const [emailConfirmation, setEmailConfirmation] = useState('')
    const [phone, setPhone] = useState()
    const [stores, setStores] = useState([])
    const [selectedWorkType, setSelectedWorkType] = useState({ label: 'ホールとキッチン両方', value: 'both' })
    const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState({
        label: 'パートタイム',
        value: 'part-time'
    })
    const [selectedOpenClose, setSelectedOpenClose] = useState({ label: '開閉担当ではありません', value: false })
    const [selectedStore, setSelectedStore] = useState(
        userEmployments?.find(e => e.id === parseInt(localStorage.selected_emp))?.store || null
    )

    useEffect(() => {
        AxiosWithAuth.get('/stores').then(res => setStores(res.data))
    }, [])

    const allFieldsFilled = () => {
        if (selectedEmploymentStatus.value === 'viewOnly') {
            // skip checking for these if viewOnly user
            return true
        }

        return selectedStore && selectedWorkType && selectedEmploymentStatus && selectedOpenClose
    }
    const handleFieldValidation = () => {
        const newErrors = {}
        if (!firstName) {
            newErrors.name_first = ['めいを入力してください']
        }
        if (!lastName) {
            newErrors.name_last = ['せいを入力してください']
        }
        if (!password) {
            newErrors.password = ['パスワードを入力してください']
        }
        if (!email) {
            newErrors.email = ['メールアドレスを入力してください']
        }
        if (!phone) {
            newErrors.phone = ['電話番号を入力してください']
        }
        if (!selectedStore && selectedEmploymentStatus.value !== 'viewOnly') {
            newErrors.store = ['店舗を選択してください']
        }
        if (!selectedWorkType && selectedEmploymentStatus.value !== 'viewOnly') {
            newErrors.work_type = ['勤務タイプを選択してください']
        }
        if (!selectedEmploymentStatus) {
            newErrors.employment_status = ['雇用形態を選択してください']
        }
        if (!selectedOpenClose && selectedEmploymentStatus.value !== 'viewOnly') {
            newErrors.open_close = ['開閉担当を選択してください']
        }
        if (password !== passwordConfirmation) {
            newErrors.password_confirmation = ['パスワードが一致しません']
        }
        if (email !== emailConfirmation) {
            newErrors.email_confirmation = ['メールアドレスが一致しません']
        }
        if (password && !validatePassword(password)) {
            newErrors.password = ['パスワードは10文字以上で、大文字、小文字、数字を含んでください']
        }
        if (email && !validateEmail(email)) {
            newErrors.email = ['メールアドレスの形式が正しくありません']
        }
        if (phone && !validatePhone(phone)) {
            newErrors.phone = ['電話番号の形式が正しくありません']
        }
        if (kanjiFirst && !validateKanjiHiraganaKatakana(kanjiFirst)) {
            newErrors.kanji_first = ['漢字、ひらがな、カタカナで入力してください']
        }
        if (kanjiLast && !validateKanjiHiraganaKatakana(kanjiLast)) {
            newErrors.kanji_last = ['漢字、ひらがな、カタカナで入力してください']
        }
        if (firstName && !validateHiraganaKatakana(firstName)) {
            newErrors.name_first = ['ひらがな、カタカナで入力してください']
        }
        if (lastName && !validateHiraganaKatakana(lastName)) {
            newErrors.name_last = ['ひらがな、カタカナで入力してください']
        }
        if (middleName && !validateHiraganaKatakana(middleName)) {
            newErrors.name_middle = ['ひらがな、カタカナで入力してください']
        }
        if (nickname && !validateHiraganaKatakana(nickname)) {
            newErrors.nickname = ['ひらがな、カタカナで入力してください']
        }
        if (employeeCode && !validateNumbers(employeeCode)) {
            newErrors.emp_cd = ['数字で入力してください']
        }
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }
    const handleNext = () => {
        if (handleFieldValidation() && allFieldsFilled()) {
            AxiosWithAuth.post('/signup', {
                user: {
                    emp_cd: employeeCode,
                    password: password,
                    password_confirmation: passwordConfirmation,
                    kanji_first: kanjiFirst,
                    name_first: firstName,
                    kanji_last: kanjiLast,
                    name_last: lastName,
                    name_middle: middleName,
                    nickname: nickname,
                    email: email,
                    phone: phone,
                    view_only: selectedEmploymentStatus.value === 'viewOnly',
                    e_type: selectedEmploymentStatus.value === 'viewOnly' ? 'full-time' : selectedEmploymentStatus.value
                },
                employment:
                    selectedEmploymentStatus.value === 'viewOnly'
                        ? null
                        : {
                              store_id: selectedStore?.id,
                              role: selectedWorkType.value,
                              open_close_duty: selectedOpenClose.value
                          }
            })
                .then(res => {
                    if (res.status === 201) {
                        setFormState('success')
                    }
                })
                .catch(err => {
                    Sentry.setContext('Signup', {
                        attempted: {
                            emp_cd: employeeCode,
                            kanji_first: kanjiFirst,
                            name_first: firstName,
                            kanji_last: kanjiLast,
                            name_last: lastName,
                            name_middle: middleName,
                            nickname: nickname,
                            email: email,
                            phone: phone,
                            emailConfirmation: emailConfirmation,
                            store_id: selectedStore?.id,
                            role: selectedWorkType?.value,
                            e_type: selectedEmploymentStatus?.value,
                            open_close_duty: selectedOpenClose?.value
                        }
                    })
                    Sentry.captureException(err)
                    console.log(err.response)
                    let errorMessage = err.response?.data?.exception
                        ? err.response?.data?.exception
                        : err.response.data.message
                    const fieldArr = []
                    if (errorMessage.includes('バリデーションに失敗しました:')) {
                        errorMessage.includes('Emp cd') && fieldArr.push('社員コード')
                        errorMessage.includes('Eメール') && fieldArr.push('メールアドレス')
                        errorMessage =
                            `既に登録されているデータが入力されています。ご確認ください。既に登録されているデータ:` +
                            fieldArr.join(', ')
                    }

                    setSnackbar({
                        message: errorMessage || 'エラーが発生しました',
                        severity: 'error'
                    })
                    setErrors({
                        ...err.response?.data?.errors
                    })
                })
        }
    }

    const handleLastName = event => {
        const text = event.target.value
        setLastName(text)
    }
    const handleKanjiLast = event => {
        const text = event.target.value
        setKanjiLast(text)
    }

    const handleFirstName = event => {
        const text = event.target.value
        setFirstName(text)
    }

    const handleKanjiFirst = event => {
        const text = event.target.value
        setKanjiFirst(text)
    }

    const handleMiddleName = event => {
        const text = event.target.value
        setMiddleName(text)
    }

    const handleNickname = event => {
        const text = event.target.value
        setNickname(text)
    }

    const handleEmployeeCode = event => {
        const text = event.target.value
        setEmployeeCode(text)
    }

    const handleEmail = event => {
        const text = event.target.value
        setEmail(text)
    }

    const handleEmailConfirmation = event => {
        const text = event.target.value
        setEmailConfirmation(text)
    }
    const handlePhone = event => {
        const text = event.target.value
        setPhone(text)
    }

    const handlePassword = event => {
        const text = event.target.value
        setPassword(text)
    }

    const handlePasswordConfirmation = event => {
        const text = event.target.value
        setPasswordConfirmation(text)
    }

    return (
        <>
            <PageWithTitleLayout title="新規会員の登録">
                {formState === 'start' && (
                    <Box
                        className="input-box shrinkable"
                        height="calc(100% - 56px)"
                        component="form"
                        autoComplete="off"
                        onSubmit={handleNext}
                    >
                        <TextField
                            id="last-name"
                            label="せい (Last name)"
                            placeholder="例：やまだ"
                            variant="outlined"
                            sx={{ marginTop: '1rem', width: '100%' }}
                            fullWidth
                            required
                            error={errors?.name_last ? true : false}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ pattern: '[ぁ-ゔゞァ-・ヽヾ゛゜ー]' }}
                            helperText={errors?.name_last || '全角ひらがな・全角カタカナ'}
                            onChange={handleLastName}
                        />
                        <TextField
                            id="first-name"
                            label="めい (First name)"
                            placeholder="例：たろう"
                            variant="outlined"
                            sx={{ marginTop: '1rem' }}
                            fullWidth
                            required
                            error={errors?.name_first ? true : false}
                            InputLabelProps={{ shrink: true }}
                            helperText={errors?.name_first || '全角ひらがな・全角カタカナ'}
                            onChange={handleFirstName}
                        />

                        <TextField
                            id="kanji-last"
                            label="姓 (Last name)"
                            placeholder="例：山田"
                            variant="outlined"
                            sx={{ marginTop: '1rem', width: '100%' }}
                            fullWidth
                            error={errors?.kanji_last ? true : false}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ pattern: '[ぁ-ゔゞァ-・ヽヾ゛゜ー]' }}
                            helperText={errors?.kanji_last || '漢字・全角ひらがな・全角カタカナ'}
                            onChange={handleKanjiLast}
                        />
                        <TextField
                            id="kanji-first"
                            label="名 (First name)"
                            placeholder="例：太郎"
                            variant="outlined"
                            sx={{ marginTop: '1rem' }}
                            fullWidth
                            error={errors?.kanji_first ? true : false}
                            InputLabelProps={{ shrink: true }}
                            helperText={errors?.kanji_first || '漢字・全角ひらがな・全角カタカナ'}
                            onChange={handleKanjiFirst}
                        />
                        <TextField
                            id="middle-name"
                            label="ミドルネーム (Middle name)"
                            placeholder="例：ジョン （ある場合のみ入力）"
                            variant="outlined"
                            sx={{ marginTop: '1rem' }}
                            fullWidth
                            error={errors?.name_middle ? true : false}
                            InputLabelProps={{ shrink: true }}
                            helperText={errors?.name_middle || '全角ひらがな・全角カタカナ'}
                            onChange={handleMiddleName}
                        />
                        <TextField
                            id="nickname"
                            label="ニックネーム (Nickname) "
                            placeholder="例：ジョン"
                            variant="outlined"
                            sx={{ marginTop: '1rem' }}
                            fullWidth
                            error={errors?.nickname ? true : false}
                            InputLabelProps={{ shrink: true }}
                            helperText={errors?.nickname || '全角ひらがな・全角カタカナ'}
                            onChange={handleNickname}
                        />
                        <TextField
                            id="employee-code"
                            label="社員コード (Employee code)"
                            placeholder="例：0000000"
                            variant="outlined"
                            sx={{ marginTop: '1rem', width: '100%' }}
                            fullWidth
                            required={true}
                            onKeyDown={e => {
                                if (e.keyCode === 109 || e.keyCode === 189 || e.keyCode === 69) {
                                    e.preventDefault()
                                }
                            }}
                            error={errors?.emp_cd ? true : false}
                            InputProps={{ inputProps: { min: 0 } }}
                            InputLabelProps={{ shrink: true }}
                            helperText={errors?.emp_cd || '半角数字'}
                            type="number"
                            onChange={handleEmployeeCode}
                        />
                        <TextField
                            id="email"
                            type="email"
                            label="メールアドレス (Email address)"
                            placeholder="例：yamada.taro@gmail.com"
                            variant="outlined"
                            sx={{ marginTop: '1rem' }}
                            fullWidth
                            required
                            error={errors?.email ? true : false}
                            InputLabelProps={{ shrink: true }}
                            helperText={errors?.email || '半角英数字'}
                            onChange={handleEmail}
                        />
                        <TextField
                            id="email"
                            type="email"
                            label="メールアドレス確認 (Email address)"
                            placeholder="例：yamada.taro@gmail.com"
                            variant="outlined"
                            sx={{ marginTop: '1rem' }}
                            fullWidth
                            required
                            error={errors?.email_confirmation ? true : false}
                            InputLabelProps={{ shrink: true }}
                            helperText={errors?.email_confirmation || '半角英数字'}
                            onChange={handleEmailConfirmation}
                        />
                        <TextField
                            id="phone"
                            label="電話番号 (Phone number)"
                            placeholder="例：08012345678"
                            variant="outlined"
                            sx={{ marginTop: '1rem' }}
                            fullWidth
                            required
                            onKeyDown={e => {
                                if (e.keyCode === 109 || e.keyCode === 189 || e.keyCode === 69) {
                                    e.preventDefault()
                                }
                            }}
                            error={errors?.phone ? true : false}
                            InputLabelProps={{ shrink: true }}
                            helperText={errors?.phone || '半角数字(ハイフンなし)'}
                            onChange={handlePhone}
                        />
                        <Autocomplete
                            value={selectedStore}
                            sx={{ marginTop: '.5rem' }}
                            id="select-stores"
                            options={stores}
                            getOptionLabel={option => option.seg_code + ' - ' + option.name}
                            isOptionEqualToValue={(option, value) => option.seg_code === value.seg_code}
                            label="店舗名"
                            placeholder="選択してください"
                            autoComplete
                            noOptionsText="店舗が見つかりません"
                            onChange={(event, newValue) => setSelectedStore(newValue)}
                            disabled={selectedEmploymentStatus?.value === 'viewOnly'}
                            renderInput={params => {
                                return (
                                    <TextField
                                        {...params}
                                        label="店舗名"
                                        placeholder="選択してください"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors?.store ? true : false}
                                        helperText={errors?.store || ''}
                                    />
                                )
                            }}
                        />

                        <Autocomplete
                            value={selectedWorkType}
                            sx={{ marginTop: '1rem' }}
                            id="select-work-type"
                            options={[
                                { label: 'ホール', value: 'hall' },
                                { label: 'キッチン', value: 'kitchen' },
                                { label: 'ホールとキッチン両方', value: 'both' }
                            ]}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            label="勤務タイプ"
                            placeholder="選択してください"
                            onChange={(event, newValue) => setSelectedWorkType(newValue)}
                            disabled={selectedEmploymentStatus?.value === 'viewOnly'}
                            renderInput={params => {
                                return (
                                    <TextField
                                        {...params}
                                        label="勤務タイプ"
                                        placeholder="選択してください"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors?.work_type ? true : false}
                                        helperText={errors?.work_type || ''}
                                    />
                                )
                            }}
                        />
                        <Autocomplete
                            value={selectedEmploymentStatus}
                            sx={{ marginTop: '1rem' }}
                            id="select-employment-status"
                            options={[
                                { label: '正社員', value: 'full-time' },
                                { label: 'パートタイム', value: 'part-time' },
                                {
                                    label: 'パートタイム（外国籍）',
                                    value: 'foreigner'
                                },
                                {
                                    label: '正社員（間接）',
                                    value: 'viewOnly'
                                }
                            ]}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            label="雇用形態"
                            placeholder="選択してください"
                            onChange={(event, newValue) => setSelectedEmploymentStatus(newValue)}
                            renderInput={params => {
                                return (
                                    <TextField
                                        {...params}
                                        label="雇用形態"
                                        placeholder="選択してください"
                                        InputLabelProps={{ shrink: true }}
                                        required
                                        error={errors?.employment_status ? true : false}
                                        helperText={errors?.employment_status || ''}
                                    />
                                )
                            }}
                        />

                        <Autocomplete
                            value={selectedOpenClose}
                            sx={{ marginTop: '1rem' }}
                            id="select-open-close"
                            options={[
                                { label: '開閉担当です', value: true },
                                { label: '開閉担当ではありません', value: false }
                            ]}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            label="開閉担当"
                            placeholder="選択してください"
                            onChange={(event, newValue) => setSelectedOpenClose(newValue)}
                            disabled={selectedEmploymentStatus?.value === 'viewOnly'}
                            renderInput={params => {
                                return (
                                    <TextField
                                        {...params}
                                        label="開閉担当"
                                        placeholder="選択してください"
                                        InputLabelProps={{ shrink: true }}
                                        error={errors?.open_close ? true : false}
                                        helperText={errors?.open_close || ''}
                                    />
                                )
                            }}
                        />
                        <TextField
                            id="password"
                            label="パスワード (Password)"
                            placeholder="例：000abcdABCD"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            sx={{ marginTop: '1rem' }}
                            fullWidth
                            required={true}
                            error={errors?.password ? true : false}
                            InputLabelProps={{ shrink: true }}
                            helperText={
                                errors?.password ||
                                '半角アルファベット大文字・小文字・数字それぞれ一つ以上含む10文字以上'
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowPassword(!showPassword)
                                            }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            onChange={handlePassword}
                        />
                        <TextField
                            id="confirm-password"
                            label="パスワード確認(Confirm password)"
                            placeholder="例：000abcdABCD"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            sx={{ my: '1rem' }}
                            fullWidth
                            required={true}
                            error={errors?.password_confirmation ? true : false}
                            InputLabelProps={{ shrink: true }}
                            helperText={
                                errors?.password_confirmation ||
                                '半角アルファベット大文字・小文字・数字それぞれ一つ以上含む10文字以上'
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowPassword(!showPassword)
                                            }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            onChange={handlePasswordConfirmation}
                        />
                        <Button
                            onClick={e => {
                                e.preventDefault()
                                handleNext()
                            }}
                            type="submit"
                            variant="contained"
                            color="paloBlue"
                            sx={{
                                marginBottom: '1.5rem',
                                marginTop: 'auto',
                                height: '42px',
                                bottom: 0
                            }}
                        >
                            ユーザー登録をする
                        </Button>
                    </Box>
                )}
                {formState === 'success' && (
                    <>
                        <Alert className="shrinkable">
                            ユーザー登録に成功しました。メールを確認して、そこから設定を続けてください。
                        </Alert>
                        <Button
                            onClick={() => {
                                setFormState('start')
                            }}
                            type="submit"
                            variant="contained"
                            color="paloBlue"
                            sx={{
                                marginBottom: '1.5rem',
                                marginTop: 'auto',
                                height: '42px',
                                bottom: 0
                            }}
                            className="shrinkable"
                        >
                            次のユーザーを登録する
                        </Button>
                    </>
                )}
                {formState === 'error' && (
                    <>
                        <Alert severity="error" className="shrinkable">
                            ユーザー登録に失敗しました。入力内容を確認してください。
                        </Alert>
                        <Button
                            onClick={() => {
                                setFormState('start')
                            }}
                            type="submit"
                            variant="contained"
                            color="paloBlue"
                            sx={{
                                marginBottom: '1.5rem',
                                marginTop: 'auto',
                                height: '42px',
                                bottom: 0
                            }}
                            className="shrinkable"
                        >
                            次のユーザーを登録する
                        </Button>
                    </>
                )}
            </PageWithTitleLayout>
        </>
    )
}

export default Signup
