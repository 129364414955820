import React, { useMemo } from 'react'
import { View, Text } from '@react-pdf/renderer'
import { styles } from '../../Utilities/pdfExportStyles'
import { PDF_TABLE_COL_WIDTHS as WIDTHS } from '../../constants'
import moment from 'moment'
import { displayNameWithSpace } from '../../Utilities/japaneseStringUtils'

const PdfEmploymentTable = ({ employmentShifts, shiftDate, startHour, endHour, HOURS, leftColWidth }) => {
    const timeChunks = useMemo(() => {
        let chunks = []
        const [year, month, day] = shiftDate.split('-')
        const shiftDay = new moment([year, month - 1, day])
        for (let i = startHour * 4 * 15; i < endHour * 60; i += 15) {
            const time = shiftDay.toDate()
            time.setHours(0)
            time.setMinutes(i)
            chunks.push(time)
        }
        return chunks
    }, [shiftDate])

    const TableHeaders = (
        <View key={shiftDate} style={{ ...styles.tableRow, height: 20 }}>
            <View style={{ width: leftColWidth, display: 'flex', flexDirection: 'row' }}>
                <View
                    style={{
                        ...styles.tableColHeader,
                        width: WIDTHS.name,
                        borderLeftWidth: 1
                    }}
                >
                    <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>氏名</Text>
                </View>
                <View style={{ ...styles.tableColHeader, width: WIDTHS.cleaning }}>
                    <Text style={{ ...styles.tableCellHeader }}>個人</Text>
                    <Text style={{ ...styles.tableCellHeader }}>衛生</Text>
                </View>
                <View style={{ ...styles.tableColHeader, width: WIDTHS.position }}>
                    <Text style={{ ...styles.tableCellHeader }}>作業</Text>
                    <Text style={{ ...styles.tableCellHeader }}>役割</Text>
                </View>
                <View style={{ ...styles.tableColHeader, width: WIDTHS.estimated }}>
                    <Text style={styles.tableCellHeader}>予定</Text>
                    <Text style={styles.tableCellHeader}>時間</Text>
                </View>
            </View>
            {HOURS.map(hour => (
                <View
                    key={hour}
                    style={{
                        ...styles.tableColHeader,
                        width: '19.0px'
                    }}
                >
                    <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>{hour}</Text>
                </View>
            ))}
            <View style={{ ...styles.tableColHeader, width: WIDTHS.default }}>
                <Text style={styles.tableCellHeader}>実際</Text>
                <Text style={styles.tableCellHeader}>時間</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: WIDTHS.default }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>差異</Text>
            </View>
        </View>
    )

    const TableRow = ({ employment }) => {
        let name = employment?.user?.name_first?.length ? displayNameWithSpace(employment?.user) : ''
        if (name.length > 7) {
            name = name.slice(0, 7) + '...'
        }
        return (
            <View style={{ ...styles.tableRow, height: 18.5 }}>
                <View style={{ width: leftColWidth, display: 'flex', flexDirection: 'row' }}>
                    <View
                        style={{
                            ...styles.tableCol,
                            width: WIDTHS.name,
                            borderLeftWidth: 1
                        }}
                    >
                        <Text style={{ ...styles.tableCell, marginTop: '3.5px' }}>{name}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: WIDTHS.cleaning }}>
                        <Text style={{ ...styles.tableCell, marginTop: '3.5px' }}></Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: WIDTHS.position }}>
                        <Text style={{ ...styles.tableCell, marginTop: '3.5px' }}></Text>
                    </View>
                    <View
                        style={{
                            ...styles.tableCol,
                            width: WIDTHS.estimated
                        }}
                    >
                        <Text style={{ ...styles.tableCellHeader, marginTop: '3.5px' }}>
                            {(employment?.totalShiftHours && employment.totalShiftHours.toFixed(2)) || ''}
                        </Text>
                    </View>
                </View>
                {timeChunks &&
                    timeChunks.map(chunk => {
                        const minutes = chunk.getMinutes()
                        const hours = chunk.getHours()
                        let shift
                        if (employment && employment.shifts) {
                            shift = employment.shifts.find(shift => {
                                let currChunk = moment(chunk)
                                let start = moment(shift.start_time)
                                let end = moment(shift.end_time)
                                return currChunk.isSameOrAfter(start) && currChunk.isBefore(end)
                            })
                        }
                        let color = 'gray'
                        if (!shift) {
                            shift = {
                                id: hours + ':' + minutes,
                                startDate: new Date(chunk),
                                endDate: new Date(new Date(chunk).setMinutes(minutes + 15)),
                                title: ''
                            }
                            color = 'white'
                        }
                        if (employment?.user?.free) {
                            color = 'white'
                            if (shift.missing > 0 && minutes === 0) {
                                color = 'gray'
                                return (
                                    <View
                                        key={chunk.getTime()}
                                        style={{
                                            width: '19.0px',
                                            backgroundColor: color,
                                            borderRight: '1px solid gray',
                                            borderBottom: '1px solid gray'
                                        }}
                                    >
                                        <Text style={styles.tableCell}>{shift?.missing}</Text>
                                    </View>
                                )
                            } else if (minutes === 0) {
                                return (
                                    <View
                                        key={chunk.getTime()}
                                        style={{
                                            width: '19.0px',
                                            borderRight: '1px solid gray',
                                            borderBottom: '1px solid gray',
                                            backgroundColor: color
                                        }}
                                    ></View>
                                )
                            }
                        } else {
                            return (
                                <View
                                    key={chunk.getTime()}
                                    style={{
                                        width: '4.75px',
                                        borderRight: minutes === 45 ? '1px solid black' : '0.5px dotted black',
                                        borderBottom: '1px solid black',
                                        backgroundColor: color
                                    }}
                                ></View>
                            )
                        }
                    })}
                <View
                    style={{
                        ...styles.tableCol,
                        width: WIDTHS.default
                    }}
                >
                    <Text style={styles.tableCell}></Text>
                </View>
                <View
                    style={{
                        ...styles.tableCol,
                        width: WIDTHS.default
                    }}
                ></View>
            </View>
        )
    }

    return (
        <View style={{ ...styles.table, marginTop: 0, borderWidth: 0 }}>
            {TableHeaders}
            {employmentShifts && employmentShifts?.map((employment, i) => <TableRow key={i} employment={employment} />)}
        </View>
    )
}

export default PdfEmploymentTable
