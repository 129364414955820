import React from 'react'
import { List, ListItem, ListItemIcon } from '@mui/material'
import { CalendarTodayOutlined, KeyboardArrowRight } from '@mui/icons-material/'
import moment from 'moment'
import SelectedStore from '../SelectedStore'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'

const CreateShiftMonths = ({ history, location }) => {
    const now = new Date()

    const months = [
        new Date(now.getFullYear(), now.getMonth()),
        new Date(now.getFullYear(), now.getMonth() + 1),
        new Date(now.getFullYear(), now.getMonth() + 2)
    ]
    return (
        <PageWithTitleLayout title="シフト表を作成する">
            <SelectedStore />
            <div className="shrinkable">
                <List sx={{ mx: '1rem' }}>
                    {months.map(e => {
                        const month = e.getMonth() + 1 // js months are 0 index
                        return (
                            <ListItem
                                key={month}
                                onClick={() => history.push(`${location.pathname}/${moment(e).format('YYYY-MM')}`)}
                            >
                                <ListItemIcon>
                                    <CalendarTodayOutlined />
                                </ListItemIcon>
                                {month}月分
                                <KeyboardArrowRight sx={{ ml: 'auto' }} />
                            </ListItem>
                        )
                    })}
                </List>
            </div>
        </PageWithTitleLayout>
    )
}

export default CreateShiftMonths
