import React, { useState } from 'react'
import { TextField, Stack, Button, InputAdornment, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material/'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { authenticationService, AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'

const EditPassword = ({ propsId, handleReturn, emp_cd }) => {
    const { userId } = useParams()
    const [newValue, setNewValue] = useState({ first: '', second: '' })
    const [showPassword, setShowPassword] = useState(false)
    const [errors, setErrors] = useState({})

    return (
        <PageWithTitleLayout title="パスワードの変更">
            <Stack spacing={2} sx={{ m: '1rem' }} className="shrinkable">
                <TextField
                    label="新しいパスワード"
                    placeholder={'例：000abcdABCD'}
                    helperText={
                        errors.password || '半角アルファベット大文字・小文字・数字それぞれ一つ以上含む10文字以上'
                    }
                    InputLabelProps={{ shrink: true }}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setShowPassword(!showPassword)
                                    }}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    value={newValue.first}
                    onChange={e => setNewValue({ ...newValue, first: e.target.value })}
                    error={errors.password ? true : false}
                />
                <TextField
                    label="新しいパスワード"
                    placeholder={'例：000abcdABCD'}
                    helperText={
                        errors.password_confirmation ||
                        '半角アルファベット大文字・小文字・数字それぞれ一つ以上含む10文字以上'
                    }
                    InputLabelProps={{ shrink: true }}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setShowPassword(!showPassword)
                                    }}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    value={newValue.second}
                    onChange={e => setNewValue({ ...newValue, second: e.target.value })}
                    error={errors.password_confirmation ? true : false}
                    sx={{ mt: '1rem' }}
                />
            </Stack>

            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        className="shrinkable"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                    >
                        変更して保存する
                    </Button>
                }
                title="パスワードの変更"
                content="パスワードを変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    AxiosWithAuth.put('/users/' + (userId || propsId), {
                        user: { password: newValue.first, password_confirmation: newValue.second }
                    })
                        .then(res => {
                            if (res.status == 200) {
                                authenticationService.login(emp_cd, newValue.first).then(res => {
                                    if (res.status === 200) {
                                        handleReturn()
                                    }
                                })
                            }
                        })
                        .catch(error => {
                            const errors = {}
                            for (const [key, value] of Object.entries(error?.response?.data)) {
                                errors[key] = value.join(' ')
                            }
                            setErrors(errors)
                        })
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditPassword
