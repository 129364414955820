import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { styles } from '../../Utilities/pdfExportStyles'

const PdfSalesTable = ({ predictedSales, store, dailyNotes }) => {
    const TableHeaders = (
        <View style={{ ...styles.tableRow, height: 20 }}>
            <View style={{ ...styles.tableColHeader, width: '20%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>{store?.seg_code}</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: '20%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>MP売上高</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: '20%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>予定売上高</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: '20%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>売上実績</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: '10%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>店長印</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: '10%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>作成者印</Text>
            </View>
        </View>
    )

    const TableRows = (
        <View style={{ ...styles.tableRow, height: 20 }}>
            <View style={{ ...styles.tableCol, width: '20%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>{store?.name}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '20%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px', marginRight: '5px' }}>{`${
                    predictedSales?.mp_prediction ? predictedSales?.mp_prediction?.toLocaleString('ja-JP') : ''
                } 円`}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '20%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px', marginRight: '5px' }}>{`${
                    predictedSales?.customer_predicted_sales
                        ? predictedSales?.customer_predicted_sales.toLocaleString('ja-JP')
                        : predictedSales?.predicted_sales
                        ? predictedSales?.predicted_sales.toLocaleString('ja-JP')
                        : ''
                } 円`}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '20%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px', marginRight: '5px' }}> 円</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '10%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px', marginRight: '5px' }}></Text>
            </View>
            <View style={{ ...styles.tableCol, width: '10%' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px', marginRight: '5px' }}></Text>
            </View>
        </View>
    )

    const MemoSection = (
        <>
            <View style={{ ...styles.tableColHeader, width: '100%', height: 20, borderBottomColor: '#000' }}>
                <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>メモ</Text>
            </View>
            <View style={{ ...styles.tableRow, height: 75 }}>
                <View
                    style={{
                        ...styles.tableCol,
                        width: '100%',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start'
                    }}
                >
                    <Text style={{ ...styles.tableCellHeader, marginLeft: 10, marginTop: 5, marginBottom: 0 }}>
                        {dailyNotes?.note}
                    </Text>
                </View>
            </View>
        </>
    )

    return (
        <View style={{ ...styles.table, marginTop: 0 }}>
            {TableHeaders}
            {TableRows}
            {MemoSection}
        </View>
    )
}

export default PdfSalesTable
