import React, { useState, useMemo } from 'react'
import { Button, TextField, Stack } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'

const EditStoreName = ({ current }) => {
    const { segCode } = useParams()
    const [newValue, setNewValue] = useState('')
    const [error, setError] = useState()
    const [errorMessage, setErrorMessage] = useState('')

    const sameName = useMemo(() => current === newValue)

    return (
        <PageWithTitleLayout title="店舗名の変更">
            <Stack spacing={2} className="input-box shrinkable">
                <TextField
                    label="現在の店舗名"
                    InputProps={{
                        readOnly: true
                    }}
                    value={current}
                    sx={{ marginTop: '1rem', width: '100%' }}
                />

                <TextField
                    label="新しい店舗名"
                    placeholder="例：テスト店"
                    helperText={errorMessage || '漢字・ひらがな・カタカナ・全角英字・全角数字のみ入力可能です。'}
                    InputLabelProps={{ shrink: true }}
                    value={newValue}
                    onChange={e => {
                        if (!e.target.value.match(/^[ぁ-んァ-ヶ一-龠々ーａ-ｚＡ-Ｚ０-９]+$/)) {
                            setErrorMessage('漢字・ひらがな・カタカナ・全角英字・全角数字のみ入力可能です。')
                            setError(true)
                        } else {
                            setErrorMessage('')
                            setError(false)
                        }
                        setNewValue(e.target.value)
                    }}
                    error={error}
                />
            </Stack>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        disabled={sameName || newValue === '' || error}
                        variant="contained"
                        color="paloBlue"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                        className="shrinkable"
                    >
                        変更して保存する
                    </Button>
                }
                title="店舗名の変更"
                content="店舗名を変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    AxiosWithAuth.put('/stores/' + segCode, {
                        name: newValue
                    })
                        .then(res => {
                            res.status == 200 && location.reload()
                        })
                        .catch(() => {
                            setError(true)
                        })
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditStoreName
