import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typography, TableCell, Tooltip } from '@mui/material'
import { ROLE_MAPPINGS } from '../../constants'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import '../CheckShift/checkShift.css'
import { displayNameWithLimit } from '../../Utilities/japaneseStringUtils'
import { desktopHeaderColumnStyle, desktopMonthlyTotalColumnStyle } from '../CheckShift/Calendars/calendarStyles'
import { NotificationImportantOutlined } from '@mui/icons-material'

const MonthlyShiftLeftmostBlock = ({ employment, monthlyShift, dates, activeTab = '' }) => {
    const { employmentId } = useParams()
    const { isManager, canEditShiftFor, userInfo, isAdmin } = useContext(EmploymentsContext)
    const hasAnyPermissions = isManager || userInfo?.view_only || canEditShiftFor[employmentId]
    const fixedCells = ['workday', 'holiday', 'totalHours', 'totalPay']
    const [shiftAlertString, setShiftAlertString] = useState('')

    useEffect(() => {
        if (!monthlyShift.shiftAlerts || !employment) return ''
        let alertString = ''
        monthlyShift.shiftAlerts.filter(sa => sa.user_id === employment.user_id).forEach(currentEmploymentAlert => {
            if (!currentEmploymentAlert) return ''
            if (currentEmploymentAlert.insufficient_holidays) {
                alertString += '公休日が不足しています (4日未満)'
            }
            if (currentEmploymentAlert.insufficient_breaks_6.length > 0) {
                alertString += '#休憩時間が不足しています (6時間越え勤務, 45分未満) - '
                currentEmploymentAlert.insufficient_breaks_6.map(day => {
                    alertString += day.replace(/-/g, '/') + ', '
                })
                alertString = alertString.substring(0, alertString.length - 2)
            }
            if (currentEmploymentAlert.insufficient_breaks_8.length > 0) {
                alertString += '#休憩時間が不足しています (8時間越え勤務, 60分未満) - '
                currentEmploymentAlert.insufficient_breaks_8.map(day => {
                    alertString += day.replace(/-/g, '/') + ', '
                })
                alertString = alertString.substring(0, alertString.length - 2)
            }
            if (currentEmploymentAlert.eleven_consecutive_work_day) {
                alertString += '#連続勤務日数が超過しています'
            }
            if (currentEmploymentAlert.insufficient_interval.length > 0) {
                alertString += '#勤務間隔が不足しています (11時間未満) - '
                currentEmploymentAlert.insufficient_interval.map(day => {
                    alertString += day.replace(/-/g, '/') + ', '
                })
                alertString = alertString.substring(0, alertString.length - 2)
            }
            if (currentEmploymentAlert.exceeded_work_day) {
                alertString += '#勤務時間が超過しています (週28時間超)'
            }
            if (currentEmploymentAlert.exceeded_work_hour) {
                alertString += '#残業時間が超過しています (36協定)'
            }
            if (currentEmploymentAlert.night_shift_for_student) {
                alertString += '#夜間勤務が割り当てられています'
            }
        })
        setShiftAlertString(alertString)
    }, [monthlyShift.shiftAlerts, employment])

    return (
        <TableCell sx={{ ...desktopHeaderColumnStyle }}>
            <div style={desktopMonthlyTotalColumnStyle}>
                <div style={{ width: '30%' }}>
                    <Typography variant="caption" className="center-text name-box">
                        {employment.user.emp_cd} {displayNameWithLimit(employment.user, 9)}
                    </Typography>
                    <Typography variant="caption" className="center-text">
                        {employment.rank && employment.rank + ' / '}
                        {ROLE_MAPPINGS[employment.role]}
                        {hasAnyPermissions && shiftAlertString.length > 0 && (
                            <Tooltip
                                title={shiftAlertString.split('#').map((alert, index) => (
                                    <div key={index}>{alert}</div>
                                ))}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            maxWidth: '500px'
                                        }
                                    }
                                }}
                            >
                                <NotificationImportantOutlined sx={{ fontSize: 20, color: 'red' }} />
                            </Tooltip>
                        )}
                    </Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        width: '70%',
                        height: '100%'
                    }}
                >
                    {dates.map(date => {
                        const workDays = () => {
                            let workdays = 0
                            if (activeTab === 'actual') {
                                Object.entries(monthlyShift.actualWorkHours).map(awh => {
                                    if (String(awh[0]) === String(employment.id)) {
                                        awh[1].map(actual => {
                                            if (actual['hours'] > 0) {
                                                workdays++
                                            }
                                        })
                                    }
                                })
                            } else {
                                Object.entries(monthlyShift.shifts).map(shifts => {
                                    if (shifts[0] === String(employment.id)) {
                                        if (hasAnyPermissions) {
                                            workdays = Object.entries(shifts[1]).length
                                        } else {
                                            Object.entries(shifts[1]).forEach(shift => {
                                                shift[1]['is_released'] ? workdays++ : null
                                            })
                                        }
                                    }
                                })
                            }
                            return workdays + '日'
                        }
                        const holidays = () => {
                            let workdays = workDays()
                            workdays = workdays.substring(0, workdays.length - 1)
                            return dates.length - 4 - workdays + '日'
                        }
                        // total shift time per employee
                        const employeeMonthlyTotalHours = () => {
                            if (activeTab === 'actual') {
                                const actualMonthlyWorkHour = monthlyShift.actualMonthlyWorkHour[employment.id]
                                if (actualMonthlyWorkHour === 0) return '0h'
                                if (actualMonthlyWorkHour === undefined) return '-'
                                return actualMonthlyWorkHour.toFixed(2) + 'h'
                            }
                            return hasAnyPermissions
                                ? (monthlyShift.confirmedEmployeeMonthlyShiftHours[employment.id] || 0) + 'h'
                                : (monthlyShift.releasedEmployeeMonthlyShiftHours[employment.id] || 0) + 'h'
                        }

                        const employeeMonthlyTotalPay = () => {
                            const hourlyWage = monthlyShift.employeeHourlyWage[employment.id]
                            let totalHours = 0
                            if (isAdmin || isManager || parseInt(employmentId) === employment.id) {
                                if (activeTab === 'actual') {
                                    totalHours = monthlyShift.actualMonthlyWorkHour[employment.id]
                                } else {
                                    totalHours = hasAnyPermissions
                                        ? monthlyShift.confirmedEmployeeMonthlyShiftHours[employment.id]
                                        : monthlyShift.releasedEmployeeMonthlyShiftHours[employment.id]
                                }
                                if (totalHours === 0) return '0円'
                                if (!hourlyWage || !totalHours) return '-'
                                return (hourlyWage * totalHours).toLocaleString() + '円'
                            } else {
                                return '-'
                            }
                        }

                        if (!fixedCells.includes(date)) return null
                        return (
                            <Typography
                                key={date}
                                className="center-text"
                                sx={{
                                    width: '25%',
                                    height: '100%',
                                    boxSizing: 'border-box',
                                    borderLeft: '1px solid lightgray'
                                }}
                            >
                                
                                { hasAnyPermissions || employment.id === parseInt(employmentId) ? <>
                                {date === 'workday' && workDays()}
                                {date === 'holiday' && holidays()}
                                {date === 'totalHours' && employeeMonthlyTotalHours()}
                                {date === 'totalPay' && employeeMonthlyTotalPay()}
                                </>
                                : "-"    
                            }
                            </Typography>
                        )
                    })}
                </div>
            </div>
        </TableCell>
    )
}

export default MonthlyShiftLeftmostBlock
