import {
    Alert,
    Button,
    Dialog,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Box,
    IconButton,
    Tabs,
    Tab,
    TextField,
    CircularProgress
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Add, Edit, Download } from '@mui/icons-material'
import React, { useEffect, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import * as XLSX from 'xlsx'
import iconv from 'iconv-lite'
import { validateNumbers } from '../../Utilities/fieldValidators'
import { HlgColumns } from '../../constants'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: '40rem' }}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

const AdminHLG = () => {
    const [storeTypes, setStoreTypes] = useState([])
    const [stores, setStores] = useState([])
    const [selectedStoreType, setSelectedStoreType] = useState('')
    const [revenueStaffingRows, setRevenueStaffingRows] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [enableSubmit, setEnableSubmit] = useState(false)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [error, setError] = useState(false)
    const [storeHlgError, setStoreHlgError] = useState('')
    const [rows, setRows] = useState([])
    const [storeLevelHLGRows, setStoreLevelHLGRows] = useState([])
    const [tab, setTab] = useState(0)
    const [newType, setNewType] = useState({})
    const [editedType, setEditedType] = useState({})
    const [selectedEditStoreType, setSelectedEditStoreType] = useState('')
    const [editedRows, setEditedRows] = useState([])
    const [errorRev, setErrorRev] = useState({})
    const [hlgError, setHlgError] = useState(false)

    useEffect(() => {
        AxiosWithAuth.get('/store_types').then(res => {
            setStoreTypes(res.data)
        })
        AxiosWithAuth.get('/stores')
            .then(res => setStores(res.data))
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        if (selectedStoreType === '') {
            return
        }
        setLoading(true)
        AxiosWithAuth.get(`/revenueStaffings/storeType/${selectedStoreType}`).then(res => {
            const rowObj = {}
            res.data.forEach(row => {
                if (!rowObj[row.hourly_rev_high]) {
                    rowObj[row.hourly_rev_high] = { daytime: null, night: null }
                }
                if (row.shift === 'Daytime') {
                    rowObj[row.hourly_rev_high].daytime = row
                } else if (row.shift === 'Night') {
                    rowObj[row.hourly_rev_high].night = row
                }
            })
            const rows = Object.keys(rowObj).map(key => {
                return {
                    hourly_rev_high: key,
                    daytime: rowObj[key].daytime,
                    night: rowObj[key].night,
                    store_type_id: selectedStoreType
                }
            })

            setRevenueStaffingRows(rows)
            setLoading(false)
        })
    }, [selectedStoreType])

    const handleHLGEdit = (value, rev_staffing) => {
        if (!validateNumbers(value) || isNaN(value) || parseInt(value) < 0) {
            setErrorRev({ ...errorRev, [rev_staffing.hourly_rev_high + rev_staffing.shift]: true })
            setHlgError(true)
            setEnableSubmit(false)
            return
        } else {
            setEditedRows({
                ...editedRows,
                [rev_staffing.id]: { ...rev_staffing, workers_required: parseFloat(value) }
            })
            setEnableSubmit(true)
            const newErrorRev = { ...errorRev }
            delete newErrorRev[rev_staffing.hourly_rev_high + rev_staffing.shift]
            Object.keys(newErrorRev).length === 0 && setHlgError(false)
            setErrorRev(newErrorRev)
        }
    }

    const handleSubmit = () => {
        setSubmitting(true)
        AxiosWithAuth.put('/revenue_staffings/storeType/multi', { revenue_staffings: Object.values(editedRows) }).then(
            res => {
                if (res.status === 200) {
                    setSuccess(true)
                    setSubmitting(false)
                    setEnableSubmit(false)
                }
            }
        )
    }

    const handleAdd = () => {
        setModalLoading(true)
        AxiosWithAuth.post('/store_types', newType).then(res => {
            if (res.status === 201) {
                setModalOpen(false)
                setSuccess(true)
                setModalLoading(false)
            }
        })
    }

    const handleEdit = () => {
        setModalLoading(true)
        AxiosWithAuth.put(`/store_types/${selectedEditStoreType}`, editedType).then(res => {
            if (res.status === 200) {
                setModalOpen(false)
                setSuccess(true)
                setSelectedEditStoreType('')
                setEditedType({})
                setModalLoading(false)
            }
        })
    }

    const onDropForStoreTypeCsv = useCallback(acceptedFiles => {
        // store_type, sales, daytime workers, night workers
        const mustField = ['店舗タイプ', 'ブランド名', '売上上限', '売上下限', 'モデル日中', 'モデル夜間']

        if (!['xlsx', 'csv'].includes(acceptedFiles[0].name?.split('.')[1])) {
            setError('ファイルの形式が正しくありません。CSVまたはXLSXファイルをアップロードしてください。')
            return
        }
        const f = acceptedFiles[0]
        const reader = new FileReader()
        reader.onload = async evt => {
            const bstr = evt.target.result
            const utf = iconv.decode(bstr, 'utf8')
            const wb = XLSX.read(utf, { type: 'binary' })
            // Get first worksheet
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            // Convert array of arrays
            let data = XLSX.utils.sheet_to_json(ws, { header: 1 })
            data = data.filter(item => {
                return item.length > 0
            })
            let shiftjis = false
            data[0].forEach(col => {
                if (col.includes('')) {
                    shiftjis = true
                }
            })
            // decodes with iconv and reparses if garbled characters found in first row
            if (shiftjis) {
                const decoded = iconv.decode(bstr, 'shiftjis')
                const wb = XLSX.read(decoded, { type: 'binary' })
                const ws = wb.Sheets[wb.SheetNames[0]]
                data = XLSX.utils.sheet_to_json(ws, { header: 1 })
                data = data.filter(item => {
                    return item.length > 0
                })
            }
            // error handling
            const keys = data[0]
            // check if all required fields are present
            const missing_fields = []
            const allFields = mustField.every(field => {
                if (!keys.includes(field)) {
                    missing_fields.push(field)
                }
                return keys.includes(field)
            })
            if (!allFields) {
                setError('ファイルの入力内容が正しくありません。テンプレートをご確認ください。')
                return
            }
            if (error) {
                return
            }
            setRows(data)
        }
        reader.readAsBinaryString(f)
    }, [])

    useEffect(() => {
        if (rows.length === 0) {
            return
        }
        const keys = rows[0]
        const parsedRows = []
        const errors = []
        rows.forEach((row, i) => {
            if (i === 0) {
                return
            }
            const storeType = storeTypes.find(type => {
                return (
                    type.type_name === row[keys.indexOf('店舗タイプ')] &&
                    type.brand_name === row[keys.indexOf('ブランド名')]
                )
            })
            if (!storeType) {
                setError('store type not found')
                errors.push('store type not found')
                return
            }
            parsedRows.push({
                store_type_id: storeType.id,
                hourly_rev_high: row[keys.indexOf('売上上限')],
                hourly_rev_low: row[keys.indexOf('売上下限')],
                workers_required: row[keys.indexOf('モデル日中')],
                shift: 'Daytime'
            })
            parsedRows.push({
                store_type_id: storeType.id,
                hourly_rev_high: row[keys.indexOf('売上上限')],
                hourly_rev_low: row[keys.indexOf('売上下限')],
                workers_required: row[keys.indexOf('モデル夜間')],
                shift: 'Night'
            })
        })
        if (errors.length > 0) {
            return
        }
        AxiosWithAuth.post('/revenue_staffings/storeType', { revenue_staffings: parsedRows }).then(res => {
            if (res.status === 200) {
                setSuccess(true)
            }
        })
    }, [rows])

    const handleSampleDownload = () => {
        const wb = XLSX.utils.book_new()
        const data = [[...HlgColumns]]
        const ws = XLSX.utils.aoa_to_sheet(data)
        XLSX.utils.book_append_sheet(wb, ws)
        XLSX.writeFile(wb, '店舗別HLGテンプレート.xlsx')
    }
    const onDropForStoreCsv = useCallback(acceptedFiles => {
        if (!['xlsx', 'csv'].includes(acceptedFiles[0].name?.split('.')[1])) {
            setError('ファイルの形式が正しくありません。CSVまたはXLSXファイルをアップロードしてください。')
            return
        }
        const f = acceptedFiles[0]
        const reader = new FileReader()
        reader.onload = async evt => {
            const bstr = evt.target.result
            const utf = iconv.decode(bstr, 'utf8')
            const wb = XLSX.read(utf, { type: 'binary' })
            // Get first worksheet
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            // Convert array of arrays
            let data = XLSX.utils.sheet_to_json(ws, { header: 1 })
            data = data.filter(item => {
                return item.length > 0
            })

            // error handling
            const keys = data[0]
            // check if all required fields are present
            const missing_fields = []
            const allFields = HlgColumns.every(field => {
                if (!keys.includes(field)) {
                    missing_fields.push(field)
                }
                return keys.includes(field)
            })
            if (!allFields) {
                setStoreHlgError('ファイルの入力内容が正しくありません。テンプレートをご確認ください。')
                return
            } else {
                setStoreHlgError('')
            }
            if (storeHlgError) {
                return
            }
            setStoreLevelHLGRows(data)
        }
        reader.readAsBinaryString(f)
    }, [])

    useEffect(() => {
        if (storeLevelHLGRows.length === 0) {
            return
        }
        const keys = storeLevelHLGRows[0]
        const parsedRows = []
        const storeNotFoundRows = []
        const errors = []
        storeLevelHLGRows.forEach((row, i) => {
            if (i === 0) {
                return
            }
            const store = stores.find(type => {
                return parseInt(type.seg_code) === row[keys.indexOf('seg_code')]
            })
            if (!store) {
                storeNotFoundRows.push(i)
                return
            }
            const storeType = storeTypes.find(type => {
                return type.id === store.store_type_id
            })
            row.forEach((cell, j) => {
                if (j == 0) {
                    return
                }
                if (cell === '') {
                    return
                }

                parsedRows.push({
                    store_id: store.id,
                    hourly_rev_low: keys[j] - 2500,
                    hourly_rev_high: keys[j],
                    workers_required: cell,
                    shift: 'Daytime',
                    store_type_id: storeType.id
                })
                parsedRows.push({
                    store_id: store.id,
                    hourly_rev_low: keys[j] - 2500,
                    hourly_rev_high: keys[j],
                    workers_required: cell,
                    shift: 'Night',
                    store_type_id: storeType.id
                })
            })
        })
        if (errors.length > 0 || storeNotFoundRows.length > 0) {
            const storeNotFoundErrorMessage =
                storeNotFoundRows.join('行目、 ') + '行目の店舗情報が見つかりませんでした。確認してください。'
            setStoreHlgError(storeNotFoundErrorMessage)
            return
        }
        AxiosWithAuth.post('/revenue_staffings/storeType', { revenue_staffings: parsedRows }).then(res => {
            if (res.status === 200) {
                setSuccess(true)
            }
        })
    }, [storeLevelHLGRows])

    const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropForStoreTypeCsv })
    const { getRootProps: getRootPropsForStore, getInputProps: getInputPropsForStore } = useDropzone({
        onDrop: onDropForStoreCsv
    })

    const selectedForEdit = storeTypes.find(type => {
        return type.id === parseInt(selectedEditStoreType)
    })

    return (
        <div style={{ width: '88rem', margin: '0 auto' }}>
            <PageWithTitleLayout title="HLG管理">
                <Snackbar
                    open={success}
                    autoHideDuration={4000}
                    onClose={() => setSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">変更を保存しました。</Alert>
                </Snackbar>
                <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="subtitle1" margin="1rem" sx={{ width: '45rem' }}>
                        店舗タイプ別HLG管理
                    </Typography>
                    {error && (
                        <Alert severity="error" sx={{ mb: '1rem', width: '40rem' }}>
                            {error}
                        </Alert>
                    )}
                    <Box
                        sx={{
                            p: 2,
                            bgcolor: '#F5F5F5',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gridTemplateColumns: { md: '1fr 1fr' },
                            gap: 2,
                            width: '45rem',
                            height: '10rem',
                            border: '1px dashed #D9D9D9',
                            borderRadius: '2px',
                            color: '#8C8C8C',
                            '&:hover': {
                                color: 'gray',
                                backgroundColor: '#D9D9D9'
                            }
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <IconButton>
                            <Add />
                        </IconButton>
                        <Box>CSVファイルをここにドラッグ＆ドロップ</Box>
                    </Box>
                </div>

                <div className="subtitle">
                    <Typography variant="subtitle1" margin="1rem" sx={{ width: '45rem' }}>
                        店舗タイプ
                    </Typography>
                    <FormControl>
                        <InputLabel id="store-type-select" shrink={false}>
                            {selectedStoreType === '' && '選択してください'}
                        </InputLabel>
                        <Select
                            label="選択してください"
                            labelId="store-type-select"
                            data-testid="store-type-select"
                            value={selectedStoreType}
                            onChange={e => setSelectedStoreType(e.target.value)}
                            input={<OutlinedInput />}
                        >
                            {storeTypes &&
                                storeTypes.map(type => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.type_name} - {type.brand_name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <Button
                        variant="outlined"
                        onClick={() => setModalOpen(true)}
                        sx={{ alignSelf: 'flex-end', marginTop: '1rem' }}
                    >
                        <Edit /> 店舗タイプの追加または編集
                    </Button>
                </div>
                <div className="subtitle">
                    <Typography variant="subtitle1" margin="1rem" sx={{ width: '45rem' }}>
                        店舗別HLG管理
                    </Typography>
                </div>
                <Snackbar
                    open={success}
                    autoHideDuration={4000}
                    onClose={() => setSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">変更を保存しました。</Alert>
                </Snackbar>
                <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {storeHlgError && (
                        <Alert severity="error" sx={{ mb: '1rem', width: '40rem' }}>
                            {storeHlgError}
                        </Alert>
                    )}
                    <Box
                        sx={{
                            p: 2,
                            bgcolor: '#F5F5F5',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gridTemplateColumns: { md: '1fr 1fr' },
                            gap: 2,
                            width: '45rem',
                            height: '10rem',
                            border: '1px dashed #D9D9D9',
                            borderRadius: '2px',
                            color: '#8C8C8C',
                            '&:hover': {
                                color: 'gray',
                                backgroundColor: '#D9D9D9'
                            }
                        }}
                        {...getRootPropsForStore()}
                    >
                        <input {...getInputPropsForStore()} />
                        <IconButton>
                            <Add />
                        </IconButton>
                        <Box>CSVファイルをここにドラッグ＆ドロップ</Box>
                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '48rem' }}>
                        <Button onClick={handleSampleDownload}>
                            <Download /> ファイルのサンプルをダウンロードする
                        </Button>
                    </div>
                </div>

                {selectedStoreType !== '' && !loading ? (
                    <div
                        style={{
                            margin: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '3rem'
                            }}
                        >
                            {hlgError && (
                                <Alert severity="error" onClose={() => setHlgError(false)}>
                                    情報を更新出来ません。1以上の半角数字を入力してください。
                                </Alert>
                            )}
                        </div>
                        <Table sx={{ width: '45rem', marginTop: '5rem' }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '5rem' }} align="center">
                                        売上（円）
                                    </TableCell>
                                    <TableCell sx={{ width: '5rem' }} align="center">
                                        モデル（人数）日中
                                    </TableCell>
                                    <TableCell sx={{ width: '5rem' }} align="center">
                                        モデル（人数）夜間
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {revenueStaffingRows.map(e => {
                                    return (
                                        <TableRow key={e.hourly_rev_high}>
                                            <TableCell align="center">
                                                {e.hourly_rev_high?.toLocaleString('ja-JP')}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Input
                                                    aria-label={'daytime-input-' + e.hourly_rev_high}
                                                    defaultValue={e.daytime.workers_required}
                                                    sx={{
                                                        width: '2rem',
                                                        backgroundColor: errorRev[
                                                            e.daytime.hourly_rev_high + e.daytime.shift
                                                        ]
                                                            ? 'rgba(240, 58, 58, .4)'
                                                            : editedRows[e.daytime.id]
                                                            ? 'rgba(237, 237, 52, 0.3)'
                                                            : null
                                                    }}
                                                    inputProps={{
                                                        style: { textAlign: 'center' }
                                                    }}
                                                    onBlur={event => handleHLGEdit(event.target.value, e.daytime)}
                                                    onKeyPress={event => event.key === 'Enter' && event.target.blur()}
                                                    error={errorRev[e.daytime.hourly_rev_high + e.daytime.shift]}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Input
                                                    aria-label={'night-input-' + e.hourly_rev_high}
                                                    defaultValue={e.night.workers_required}
                                                    sx={{
                                                        width: '2rem',
                                                        backgroundColor: errorRev[
                                                            e.night.hourly_rev_high + e.night.shift
                                                        ]
                                                            ? 'rgba(240, 58, 58, .4)'
                                                            : editedRows[e.night.id]
                                                            ? 'rgba(237, 237, 52, 0.3)'
                                                            : null
                                                    }}
                                                    inputProps={{
                                                        style: { textAlign: 'center' }
                                                    }}
                                                    onBlur={event => handleHLGEdit(event.target.value, e.night)}
                                                    onKeyPress={event => event.key === 'Enter' && event.target.blur()}
                                                    error={errorRev[e.night.hourly_rev_high + e.night.shift]}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                        <LoadingButton
                            variant="contained"
                            color="paloBlue"
                            sx={{ width: '50%', margin: '1rem 1rem' }}
                            onClick={handleSubmit}
                            disabled={!enableSubmit || submitting || Object.keys(errorRev).length > 0}
                            loading={submitting}
                        >
                            変更を保存する
                        </LoadingButton>
                    </div>
                ) : (
                    loading && selectedStoreType !== '' && <CircularProgress style={{ margin: '10rem auto' }} />
                )}
            </PageWithTitleLayout>
            <Dialog
                open={modalOpen}
                fullWidth={true}
                maxWidth="md"
                onClose={() => setModalOpen(false)}
                // PaperProps={{ sx: { top: '10rem' } }}
            >
                <div
                    className="subtitle"
                    style={{
                        marginTop: '1rem',
                        height: '45rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={(e, nv) => setTab(nv)} aria-label="basic tabs example">
                            <Tab label="追加" {...a11yProps(0)} />
                            <Tab label="編集" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tab} index={0}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ alignSelf: 'flex-start' }} variant="subtitle1">
                                追加するブランド名
                            </Typography>
                            <TextField
                                id="brand-name"
                                variant="outlined"
                                placeholder="入力してください"
                                sx={{ margin: '1rem 1rem', width: '100%' }}
                                value={newType.brand_name}
                                onChange={e => setNewType({ ...newType, brand_name: e.target.value })}
                            />
                            <Typography sx={{ alignSelf: 'flex-start' }} variant="subtitle1">
                                追加する店舗タイプ
                            </Typography>
                            <TextField
                                id="type-name"
                                variant="outlined"
                                placeholder="入力してください"
                                sx={{ margin: '1rem 1rem', width: '100%' }}
                                value={newType.type_name}
                                onChange={e => setNewType({ ...newType, type_name: e.target.value })}
                            />
                            <Typography sx={{ alignSelf: 'flex-start' }} variant="subtitle1">
                                ブランド名コード
                            </Typography>
                            <TextField
                                id="brand-seg"
                                variant="outlined"
                                placeholder="入力してください"
                                sx={{ margin: '1rem 1rem', width: '100%' }}
                                value={newType.brand_seg}
                                onChange={e => setNewType({ ...newType, brand_seg: e.target.value })}
                            />
                            <LoadingButton
                                variant="contained"
                                color="paloBlue"
                                sx={{ width: '100%', marginTop: '10rem', marginBottom: '1rem' }}
                                mt={5}
                                onClick={handleAdd}
                                disabled={!(newType.type_name && newType.brand_name) || modalLoading}
                                loading={modalLoading}
                            >
                                追加する
                            </LoadingButton>
                            <Button variant="outlined" sx={{ width: '100%' }} onClick={() => setModalOpen(false)}>
                                キャンセル
                            </Button>
                        </div>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ alignSelf: 'flex-start' }} variant="subtitle1">
                                登録されている店舗タイプの編集
                            </Typography>
                            <FormControl sx={{ width: '100%', margin: '1rem 1rem' }}>
                                <InputLabel id="store-type-select" shrink={false}>
                                    {selectedEditStoreType === '' && '選択してください'}
                                </InputLabel>
                                <Select
                                    label="選択してください"
                                    labelId="store-type-select"
                                    data-testid="store-type-select"
                                    value={selectedEditStoreType}
                                    onChange={e => setSelectedEditStoreType(e.target.value)}
                                    input={<OutlinedInput />}
                                >
                                    {storeTypes &&
                                        storeTypes.map(type => (
                                            <MenuItem key={type.id} value={type.id}>
                                                {type.type_name} - {type.brand_name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            {selectedForEdit && (
                                <>
                                    <Typography sx={{ alignSelf: 'flex-start' }} variant="subtitle1">
                                        追加するブランド名
                                    </Typography>
                                    <TextField
                                        id="brand-name"
                                        variant="outlined"
                                        placeholder="入力してください"
                                        sx={{ margin: '1rem 1rem', width: '100%' }}
                                        value={editedType.brand_name || selectedForEdit.brand_name}
                                        onChange={e => setEditedType({ ...editedType, brand_name: e.target.value })}
                                    />
                                    <Typography sx={{ alignSelf: 'flex-start' }} variant="subtitle1">
                                        追加する店舗タイプ
                                    </Typography>
                                    <TextField
                                        id="type-name"
                                        variant="outlined"
                                        placeholder="入力してください"
                                        sx={{ margin: '1rem 1rem', width: '100%' }}
                                        value={editedType.type_name || selectedForEdit.type_name}
                                        onChange={e => setEditedType({ ...editedType, type_name: e.target.value })}
                                    />
                                    <Typography sx={{ alignSelf: 'flex-start' }} variant="subtitle1">
                                        ブランド名コード
                                    </Typography>
                                    <TextField
                                        id="brand-seg"
                                        variant="outlined"
                                        placeholder="入力してください"
                                        sx={{ margin: '1rem 1rem', width: '100%' }}
                                        value={editedType.brand_seg || selectedForEdit.brand_seg}
                                        onChange={e => setEditedType({ ...editedType, brand_seg: e.target.value })}
                                    />
                                    <LoadingButton
                                        variant="contained"
                                        color="paloBlue"
                                        sx={{ width: '100%', marginTop: '3rem', marginBottom: '1rem' }}
                                        mt={5}
                                        disabled={modalLoading}
                                        loading={modalLoading}
                                        onClick={handleEdit}
                                    >
                                        追加する
                                    </LoadingButton>
                                </>
                            )}
                            <Button variant="outlined" sx={{ width: '100%' }} onClick={() => setModalOpen(false)}>
                                キャンセル
                            </Button>
                        </div>
                    </TabPanel>
                </div>
            </Dialog>
        </div>
    )
}

export default AdminHLG
