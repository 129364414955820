import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { List, ListItem, Box, ListItemText, Alert, CircularProgress } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material/'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { PATHS } from '../../constants'
import SelectedStore from '../SelectedStore'
import moment from 'moment'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { displayNameWithSpace } from '../../Utilities/japaneseStringUtils'

const UserManagementList = ({ history }) => {
    const { employmentId } = useParams()
    const [employments, setEmployments] = useState()
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        AxiosWithAuth.get('/calendars/dailyShifts', {
            params: {
                employment_id: employmentId,
                date: moment()
            }
        })
            .then(res => setEmployments(res?.data.employments.sort((a, b) => a.order - b.order)))
            .catch(err => {
                if (err.response?.status === 403) {
                    setMessage('選択した店舗の店長ではありません。')
                    setEmployments([])
                } else {
                    setMessage('エラーが発生しました。')
                }
            })
            .finally(() => setLoading(false))
    }, [employmentId])

    return (
        <PageWithTitleLayout title="ユーザー情報" message={message} setMessage={setMessage}>
            <SelectedStore />
            <Box className="input-box shrinkable">
                {history.location.pathname.includes('deleted') && (
                    <Alert sx={{ mx: '1rem' }} severity="success">
                        ユーザー{history.location.pathname.split(':')[1]}
                        を削除しました。
                    </Alert>
                )}
                {loading ? (
                    <CircularProgress />
                ) : employments ? (
                    <List sx={{ mx: '1rem' }}>
                        {employments.map(e => {
                            return (
                                <React.Fragment key={e.user.emp_cd}>
                                    <ListItem
                                        sx={{ height: '4rem' }}
                                        onClick={() => {
                                            history.push(
                                                `${PATHS.userManagementStores}${employmentId}/${e.id}/${e.user.id}`
                                            )
                                        }}
                                    >
                                        <ListItemText secondary={e.user.emp_cd}>
                                            {displayNameWithSpace(e.user)}
                                        </ListItemText>
                                        <KeyboardArrowRight sx={{ ml: 'auto' }} />
                                    </ListItem>
                                </React.Fragment>
                            )
                        })}
                    </List>
                ) : null}
            </Box>
        </PageWithTitleLayout>
    )
}

export default UserManagementList
