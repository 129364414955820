import React, { useEffect, useState } from 'react'
import { List, ListItem, ListItemIcon, Autocomplete, TextField } from '@mui/material'
import { KeyboardArrowRight, CalendarTodayOutlined } from '@mui/icons-material/'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { useStickyState } from '../../Utilities/useStickyState'
import { isMobile } from '../../Utilities/isMobile'
import useOrientation from '../../Utilities/useOrientation'
import moment from 'moment'

const ViewOnlyHome = ({ history }) => {
    const [storeOptions, setStoreOptions] = useState([])
    const [selectedStore, setSelectedStore] = useStickyState(null, 'selectedStoreViewOnly')
    const orientation = useOrientation()
    
    const now = new Date()
    const months = [
        new Date(now.getFullYear(), now.getMonth()),
        new Date(now.getFullYear(), now.getMonth() + 1),
        new Date(now.getFullYear(), now.getMonth() + 2)
    ]

    useEffect(() => {
        AxiosWithAuth.get('/stores').then(res => {
            const mappedStoreOptions = res.data
                .filter(e => e?.sample_employment_id)
                .map(store => {
                    return {
                        ...store,
                        label: store.name,
                        value: store.seg_code
                    }
                })
            setStoreOptions(mappedStoreOptions)
        })
    }, [])

    const homeComponent = (
        <PageWithTitleLayout title="ホーム">
            <Autocomplete
                sx={{ mx: '1rem', widht: '100%' }}
                className="shrinkable"
                options={storeOptions}
                value={selectedStore}
                getOptionLabel={option => option.seg_code + ' - ' + option.name}
                isOptionEqualToValue={(option, value) => option.seg_code === value.seg_code}
                label="店舗名"
                placeholder="選択してください"
                autoComplete
                onChange={(event, newValue) => {
                    if (!newValue) return
                    setSelectedStore(newValue)
                }}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            label="店舗名"
                            placeholder="選択してください"
                            InputLabelProps={{ shrink: true }}
                        />
                    )
                }}
            />
            <div className="shrinkable">
                <List>
                    {selectedStore &&
                        months.map(e => {
                            const month = e.getMonth() + 1 // js months are 0 index
                            return (
                                <ListItem
                                    key={month}
                                    onClick={() =>
                                        history.push(
                                            isMobile() && orientation !== 'landscape' ? `${selectedStore.sample_employment_id}/${moment(e).format('YYYY-MM')}/mobile` : `${selectedStore.sample_employment_id}/${moment(e).format('YYYY-MM')}`
                                        )
                                    }
                                >
                                    <ListItemIcon>
                                        <CalendarTodayOutlined />
                                    </ListItemIcon>
                                    {month}月分
                                    <KeyboardArrowRight sx={{ ml: 'auto' }} />
                                </ListItem>
                            )
                        })}
                </List>
            </div>
        </PageWithTitleLayout>
    )

    return homeComponent
}

export default ViewOnlyHome
