import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material'

const ButtonWithConfirmPrompt = ({
    component,
    title,
    content,
    cancelText = 'キャンセル',
    confirmText = 'はい',
    handleConfirm,
    confirmTextDisabled = false,
    loading = false
}) => {
    const [dialogue, setDialogue] = useState(false)

    return (
        <>
            {React.cloneElement(component, {
                onClick: () => setDialogue(true)
            })}
            <Dialog open={dialogue}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>{content}</DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setDialogue(false)}>
                        {cancelText}
                    </Button>
                    {loading ? (
                        <CircularProgress size={36} sx={{ marginRight: '1rem', marginLeft: '1rem' }} />
                    ) : (
                        <Button
                            id="confirmation-prompt-button"
                            onClick={() => {
                                setDialogue(false)
                                handleConfirm()
                            }}
                            disabled={confirmTextDisabled}
                            autoFocus
                        >
                            {confirmText}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ButtonWithConfirmPrompt
