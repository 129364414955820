import React from 'react'
import { Typography, TableContainer, Table, TableHead, TableCell, TableBody } from '@mui/material'
import { PeopleAlt, VpnKeyRounded } from '@mui/icons-material'
import { ERROR_COLOR, PALO_BLUE, ROLE_MAPPINGS } from '../../../constants'
import '../checkShift.css'
import moment from 'moment'
import { displayNameWithLimit } from '../../../Utilities/japaneseStringUtils'
import { desktopBaseCellStyle, desktopHeaderColumnStyle, desktopHeaderRowStyle } from './calendarStyles'

const DesktopCalendar = ({
    timeChunks,
    fullDayStaffed,
    getStaffRequired,
    checkIfFullyStaffed,
    staffRequirements,
    predictedSales,
    employments,
    shifts,
    nonConfirmedShifts,
    computeColor,
    isManagerFor,
    employmentId,
    canEditShiftFor,
    handleCalendarClick,
    handleLongPress,
    collapsedRows,
    viewOnly
}) => {
    const handleDragStart = (e, shift) => {
        const type = shift.status === 'confirmed' ? 'confirmed' : 'non_confirmed'
        window.localStorage.setItem('drag_type', type)
    }

    const handleDragEnter = (e, shift) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.nativeEvent.buttons === 0) {
            return
        }
        const type = window.localStorage.getItem('drag_type')
        const shift_type = shift.status === 'confirmed' ? 'confirmed' : 'non_confirmed'
        if (type === shift_type) {
            handleCalendarClick(shift)
        }
    }

    const desktopTimeScale = ({ time }) => {
        let staffRequired
        let predSales
        let isFullyStaffed
        let shiftCount

        if (time) {
            ;[staffRequired, predSales] = getStaffRequired(time.getHours())
            ;[isFullyStaffed, shiftCount] = checkIfFullyStaffed(time.getDate(), time.getHours(), staffRequired)
        }

        const insufficient =
            (isManagerFor[employmentId] || canEditShiftFor[employmentId] || viewOnly) && !isFullyStaffed

        const moreThanRequired =
            (isManagerFor[employmentId] || canEditShiftFor[employmentId] || viewOnly) && shiftCount - staffRequired > 0

        if (time.getMinutes() === 0) {
            return (
                <TableCell
                    key={time}
                    colSpan={4}
                    style={{
                        ...desktopHeaderRowStyle
                    }}
                >
                    <Typography
                        sx={{
                            height: '2rem',
                            borderBottom: '1px solid lightgray',
                            boxSizing: 'border-box'
                        }}
                        className="center-text"
                    >
                        {moment(time).format('HH:mm')}
                    </Typography>
                    <div
                        style={{
                            height: '2rem',
                            borderBottom: isManagerFor[employmentId] || viewOnly ? '1px solid lightgray' : '',
                            boxSizing: 'border-box'
                        }}
                        className="center-text"
                    >
                        {time && staffRequirements.length > 0 && time.getMinutes() === 0 && (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <PeopleAlt
                                    fontSize="string"
                                    sx={{
                                        color:
                                            insufficient && staffRequired
                                                ? ERROR_COLOR
                                                : moreThanRequired
                                                ? PALO_BLUE
                                                : 'inherit'
                                    }}
                                />
                                <Typography
                                    style={{
                                        marginLeft: 5,
                                        fontSize: 12,
                                        color:
                                            insufficient && staffRequired
                                                ? ERROR_COLOR
                                                : moreThanRequired
                                                ? PALO_BLUE
                                                : 'inherit'
                                    }}
                                >
                                    {shiftCount}/{staffRequired || 0}
                                </Typography>
                            </div>
                        )}
                    </div>
                    {(isManagerFor[employmentId] || viewOnly) && (
                        <Typography className="center-text" sx={{ height: '2rem', textAlign: 'center' }}>
                            {Math.round(predSales || 0).toLocaleString('ja-JP')}
                        </Typography>
                    )}
                </TableCell>
            )
        }
    }
    const totalHours = id => {
        let total = 0
        shifts.forEach(shift => {
            shift.employment_id === id && shift.status === 'confirmed' ? (total += 0.25) : null
        })
        return (
            <TableCell
                sx={{
                    ...desktopBaseCellStyle,
                    height: collapsedRows ? '.5rem' : '3rem'
                }}
            >
                {total + 'h'}
            </TableCell>
        )
    }
    return (
        <TableContainer sx={{ border: '1px solid lightgray', margin: '0 auto', maxHeight: 700, maxWidth: '95%' }}>
            <Table>
                <TableHead>
                    <tr>
                        <td
                            style={{
                                ...desktopHeaderRowStyle,
                                left: 0,
                                zIndex: 3
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                className="center-text"
                                sx={{
                                    height: '2rem',
                                    minWidth: '4rem',
                                    textAlign: 'center',
                                    borderBottom: '1px solid lightgray',
                                    boxSizing: 'border-box'
                                }}
                            >
                                合計
                            </Typography>

                            <div
                                style={{
                                    height: '2rem',
                                    borderBottom: isManagerFor[employmentId] ? '1px solid lightgray' : '',
                                    boxSizing: 'border-box'
                                }}
                                className="center-text"
                            >
                                スタッフ：{fullDayStaffed()}
                            </div>
                            {(isManagerFor[employmentId] || viewOnly) && (
                                <Typography
                                    className="center-text"
                                    sx={{ height: '2rem', textAlign: 'center', boxSizing: 'border-box' }}
                                >
                                    {Math.round(predictedSales || 0).toLocaleString('ja-JP')}円
                                </Typography>
                            )}
                        </td>
                        <td
                            style={{
                                borderRight: '1px solid lightgray',
                                borderBottom: '1px solid lightgray',
                                minWidth: '4rem',
                                position: 'sticky',
                                top: 0,
                                background: 'white'
                            }}
                        >
                            <Typography variant="subtitle2" className="center-text">
                                予定時間
                            </Typography>
                        </td>
                        {timeChunks.map(chunk => {
                            return desktopTimeScale({ time: chunk })
                        })}
                    </tr>
                </TableHead>
                <TableBody>
                    {employments.map(employment => {
                        const employmentShifts = shifts.filter(shift => shift.employment_id === employment.id)
                        return (
                            <tr key={employment.id}>
                                <td
                                    style={{
                                        ...desktopHeaderColumnStyle,
                                        height: collapsedRows ? '.5rem' : '3rem'
                                    }}
                                >
                                    <Typography variant="caption" className="center-text name-box">
                                        {employment.user.emp_cd}{' '}
                                        {displayNameWithLimit(employment.user, 17 - employment.user.emp_cd?.length)}
                                    </Typography>
                                    {!collapsedRows && (
                                        <Typography variant="caption" className="center-text">
                                            {ROLE_MAPPINGS[employment.role] &&
                                                `${ROLE_MAPPINGS[employment.role] || ''} / `}
                                            {`${employment.rank || ''}${
                                                employment.open_close_duty && employment.rank ? ' / ' : ''
                                            }`}
                                            {employment.open_close_duty ? (
                                                <VpnKeyRounded style={{ fontSize: 12 }} />
                                            ) : (
                                                ''
                                            )}
                                        </Typography>
                                    )}
                                </td>
                                {totalHours(employment.id)}
                                {timeChunks.map(chunk => {
                                    const minutes = chunk.getMinutes()
                                    const hours = chunk.getHours()
                                    let shifts = employmentShifts.filter(
                                        shift => shift.startDate.getTime() === chunk.getTime()
                                    )

                                    let shift = shifts.find(s => s.status === 'blocked') || shifts[0]
                                    const isSubmitted = nonConfirmedShifts.find(
                                        s =>
                                            s.startDate.getTime() === shift?.startDate.getTime() &&
                                            s.employment_id === employment.id &&
                                            s.status === 'submitted'
                                    )
                                    if (!shift) {
                                        shift = {
                                            id: hours + ':' + minutes + employment.id,
                                            employment_id: employment.id,
                                            startDate: new Date(chunk),
                                            endDate: new Date(new Date(chunk).setMinutes(minutes + 15)),
                                            title: ''
                                        }
                                    }
                                    const color = computeColor(
                                        isManagerFor[employmentId] || canEditShiftFor[employmentId] || viewOnly,
                                        shift
                                    )
                                    return (
                                        <td
                                            style={{
                                                ...desktopBaseCellStyle,
                                                backgroundColor: color,
                                                height: collapsedRows ? '.5rem' : '3rem',
                                                borderRight:
                                                    chunk.getMinutes() - 45 === 0
                                                        ? '1px solid lightgray'
                                                        : '1px dashed lightgray'
                                            }}
                                            key={chunk.getTime()}
                                            data-testid={shift.status}
                                            id={
                                                employment.user.emp_cd +
                                                '-' +
                                                chunk.getHours() +
                                                ':' +
                                                chunk.getMinutes()
                                            }
                                            draggable={true}
                                            onClick={() => handleCalendarClick(shift)}
                                            onDragStart={e => handleDragStart(e, shift)}
                                            onDragEnterCapture={e => handleDragEnter(e, shift)}
                                            onContextMenu={e => {
                                                e.preventDefault()
                                                handleLongPress(shift)
                                            }}
                                        >
                                            {shift.status === 'submitted' ||
                                            shift.originalStatus === 'submitted' ||
                                            isSubmitted ? (
                                                <div
                                                    style={{
                                                        backgroundColor: '#BAC0E4',
                                                        marginLeft: '4px',
                                                        height: '5px',
                                                        width: '8px'
                                                    }}
                                                ></div>
                                            ) : null}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DesktopCalendar
