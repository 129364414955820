import React, { useState } from 'react'
import { TextField, Button, Autocomplete, Alert } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'

const typeOptions = [
    { label: '正社員', value: 'full-time' },
    { label: 'パートタイム', value: 'part-time' },
    {
        label: 'パートタイム（外国籍）',
        value: 'foreigner'
    }
]
const EditPartFulltime = ({ current, handleReturn, info }) => {
    const { userId } = useParams()
    const [newValue, setNewValue] = useState(typeOptions.find(option => option.value === current))

    return (
        <PageWithTitleLayout title="雇用形態の変更">
            {current === 'full-time' && newValue.value === 'part-time' && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Alert severity="warning" sx={{ m: '1rem' }}>
                        このユーザーは正社員です。パートタイムに変更すると、正社員の権限が剥奪されます。
                    </Alert>
                </div>
            )}
            <Autocomplete
                sx={{ m: '1rem' }}
                id="select-work-type"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={typeOptions}
                label="雇用形態"
                placeholder="選択してください"
                value={newValue}
                disableClearable
                onChange={(event, nv) => setNewValue(nv)}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            label="雇用形態"
                            placeholder="選択してください"
                            InputLabelProps={{ shrink: true }}
                        />
                    )
                }}
                className="input-box shrinkable"
            />
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                        disabled={!newValue}
                        className="shrinkable"
                    >
                        変更して保存する
                    </Button>
                }
                title="雇用形態の変更"
                content="雇用形態を変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    AxiosWithAuth.put('/users/' + (info ? info.user_id : userId), {
                        e_type: newValue.value
                    }).then(res => {
                        res.status == 200 && handleReturn()
                    })
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditPartFulltime
