import React, { useState } from 'react'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { Table, TableHead, TableRow, TableCell, Button, TableContainer } from '@mui/material'
import { PATHS } from '../../constants'
import { AxiosWithAuth } from '../../Utilities/authenticationService'

const AdminStoreBulkUpload = ({ history }) => {
    const [newStores] = useState(history?.location?.state?.data)
    const handleSubmit = () => {
        let stores = []
        let keys = newStores[1]
        for (let i = 2; i < newStores.length; i++) {
            let store = {}
            for (let j = 0; j < keys.length; j++) {
                store[keys[j]] = newStores[i][j]
            }
            stores.push(store)
        }
        let submitted = 0
        stores.forEach(store => {
            AxiosWithAuth.post('/stores', {
                store: {
                    seg_code: store?.seg_code,
                    name: store?.name,
                    store_type_id: store?.store_type_id
                }
            })
                .then(() => {
                    submitted++
                    if (stores.length === submitted) {
                        history.push({
                            pathname: PATHS.adminStoreManagements,
                            state: { message: '店舗を一括登録しました。' }
                        })
                    }
                })
                .catch(err => console.log(err))
        })
    }
    return (
        <PageWithTitleLayout title="店舗の一括登録">
            <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <TableContainer
                    sx={{ border: '1px solid lightgray', margin: '0 auto', maxHeight: 700, maxWidth: '55rem' }}
                >
                    {newStores && (
                        <Table sx={{ marginTop: '1rem' }}>
                            <TableHead sx={{ height: '1.5rem' }}>
                                <TableRow>
                                    {newStores[0] &&
                                        newStores[0].map((header, index) => (
                                            <TableCell style={{ minWidth: '7rem' }} key={index}>
                                                {header}
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>

                            {newStores.map((store, idx) => {
                                if (idx >= 2) {
                                    return (
                                        <TableRow key={idx}>
                                            {newStores[0].map((e, i) => {
                                                return <TableCell key={i}>{store[i]}</TableCell>
                                            })}
                                        </TableRow>
                                    )
                                }
                            })}
                        </Table>
                    )}
                </TableContainer>
            </div>
            <Button
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                color="paloBlue"
                sx={{
                    marginBottom: '1.5rem',
                    marginTop: 'auto',
                    height: '42px',
                    bottom: 0
                }}
                className="shrinkable"
            >
                一括登録する
            </Button>
            <Button
                onClick={() => {
                    history.push(PATHS.adminStoreManagements)
                }}
                type="submit"
                variant="outlined"
                color="paloBlue"
                sx={{
                    marginBottom: '1.5rem',
                    marginTop: 'auto',
                    height: '42px',
                    bottom: 0
                }}
                className="shrinkable"
            >
                キャンセル
            </Button>
        </PageWithTitleLayout>
    )
}
export default AdminStoreBulkUpload
