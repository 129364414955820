import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { CircularProgress, TableRow, TableCell, TableBody } from '@mui/material'
import '../CheckShift/checkShift.css'
import { desktopMonthlyBaseCellStyle } from '../CheckShift/Calendars/calendarStyles'
import { computeBackgroundColor } from './MonthlyShiftHeader'
import MonthlyShiftLeftmostBlock from './MonthlyShiftLeftmostBlock'

const CheckMonthlyShiftBody = ({ employments, monthlyShift, dates }) => {
    const { isManager, canEditShiftFor, userInfo } = useContext(EmploymentsContext)
    const { employmentId } = useParams()
    const hasAnyPermissions = isManager || userInfo?.view_only || canEditShiftFor[employmentId]

    const fixedCells = ['workday', 'holiday', 'totalHours', 'totalPay']

    if (monthlyShift && Object.entries(monthlyShift).length > 0) {
        return (
            <TableBody>
                {employments.map((employment, idx) => {
                    // employees list
                    return (
                        <TableRow key={employment.id}>
                            <MonthlyShiftLeftmostBlock
                                employment={employment}
                                dates={dates}
                                monthlyShift={monthlyShift}
                            />
                            {dates.map(date => {
                                // daily shift time range
                                let timeToReturn = null
                                let isBlocker = false
                                let shift = monthlyShift?.shifts?.[employment.id]?.[date] || null
                                const blocker = monthlyShift?.shiftBlockers?.[employment.id]?.[date] || null
                                if (blocker) {
                                    shift = blocker
                                    isBlocker = true
                                }
                                if (shift) {
                                    let start = shift['shift_time'][0]
                                    let end = shift['shift_time'][1]
                                    if (start[0] === '0') {
                                        start = start.slice(1)
                                    }
                                    if (end[0] === '0') {
                                        end = end.slice(1)
                                    }
                                    timeToReturn =
                                        `${start} - ${end}` + (shift['break'] ? ` (休憩)${shift['break']}h` : '')
                                    if (!hasAnyPermissions && !monthlyShift.releasedShiftDates.includes(date)) {
                                        timeToReturn = null
                                    }
                                }
                                if (fixedCells.includes(date)) return null
                                return (
                                    <TableCell
                                        key={date}
                                        sx={{
                                            ...desktopMonthlyBaseCellStyle,
                                            borderRight: '1px solid lightgray',
                                            borderBottom: '1px solid lightgray',
                                            backgroundColor: computeBackgroundColor(
                                                date,
                                                monthlyShift,
                                                hasAnyPermissions,
                                                isBlocker
                                            )
                                        }}
                                        id={idx}
                                    >
                                        {timeToReturn}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    } else {
        return <CircularProgress />
    }
}

export default CheckMonthlyShiftBody
