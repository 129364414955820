import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import { styles } from '../../Utilities/pdfExportStyles'
import { priceFormatter } from './PdfExportButton'

const PdfFooter = ({
    preds,
    predictedSales,
    nextPage,
    hoursWithStaffReq,
    HOURS,
    leftColWidth,
    hourlyEmpSalesPred,
    staffPerHour,
    totalActualStaff
}) => {
    return (
        <View break={nextPage} style={{ ...styles.table, margin: '0 auto 0 0', borderWidth: 0 }}>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flexDirection: 'column', width: leftColWidth }}>
                    <View
                        style={{
                            ...styles.tableColHeader,
                            width: '100%',
                            height: 20,
                            borderLeftWidth: 1
                        }}
                    >
                        <Text style={{ ...styles.tableCellHeader }}></Text>
                    </View>
                    <View
                        style={{
                            ...styles.tableCol,
                            width: '100%',
                            height: 20,
                            borderLeftWidth: 1,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Text
                            style={{ ...styles.tableCell, marginTop: '5px', marginLeft: '2px', width: '17.5%' }}
                        ></Text>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', width: '82.5%' }}>
                            予定 時間帯売上(千円)
                        </Text>
                    </View>
                    <View
                        style={{
                            ...styles.tableCol,
                            width: '100%',
                            height: 20,
                            borderLeftWidth: 1,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Text style={{ ...styles.tableCell, marginTop: '5px', marginLeft: '2px', width: '17.5%' }}>
                            Ａ
                        </Text>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', width: '82.5%' }}>モデル 時間（h）</Text>
                    </View>
                    <View
                        style={{
                            ...styles.tableCol,
                            width: '100%',
                            height: 20,
                            borderLeftWidth: 1,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Text style={{ ...styles.tableCell, marginTop: '5px', marginLeft: '2px', width: '17.5%' }}>
                            Ｂ
                        </Text>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', width: '82.5%' }}>予定 時間（h）</Text>
                    </View>
                    <View
                        style={{
                            ...styles.tableCol,
                            width: '100%',
                            height: 20,
                            borderLeftWidth: 1,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Text style={{ ...styles.tableCell, marginTop: '5px', marginLeft: '2px', width: '17.5%' }}>
                            Ａ-Ｂ
                        </Text>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', width: '82.5%' }}>モデル差異（h）</Text>
                    </View>
                    <View
                        style={{
                            ...styles.tableCol,
                            width: '100%',
                            backgroundColor: '#E5E5E5',
                            height: 20,
                            borderLeftWidth: 1,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Text
                            style={{ ...styles.tableCell, marginTop: '5px', marginLeft: '2px', width: '17.5%' }}
                        ></Text>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', width: '82.5%' }}>
                            実際 時間帯別売上(千円)
                        </Text>
                    </View>
                    <View
                        style={{
                            ...styles.tableCol,
                            width: '100%',
                            backgroundColor: '#E5E5E5',
                            height: 20,
                            borderLeftWidth: 1,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Text style={{ ...styles.tableCell, marginTop: '5px', marginLeft: '2px', width: '17.5%' }}>
                            Ｃ
                        </Text>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', width: '82.5%' }}>モデル時間（h）</Text>
                    </View>
                    <View
                        style={{
                            ...styles.tableCol,
                            width: '100%',
                            backgroundColor: '#E5E5E5',
                            height: 20,
                            borderLeftWidth: 1,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Text style={{ ...styles.tableCell, marginTop: '5px', marginLeft: '2px', width: '17.5%' }}>
                            ＣーＡ
                        </Text>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', width: '82.5%' }}>モデル差異（h）</Text>
                    </View>
                    <View
                        style={{
                            ...styles.tableCol,
                            width: '100%',
                            backgroundColor: '#E5E5E5',
                            height: 20,
                            borderLeftWidth: 1,
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <Text
                            style={{ ...styles.tableCell, marginTop: '5px', marginLeft: '2px', width: '17.5%' }}
                        ></Text>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', width: '82.5%' }}>実績時間（h）</Text>
                    </View>
                </View>
                {HOURS.map(hour => {
                    const hourlyPredSales = priceFormatter(preds[hour]?.pred_sales)
                    const hourlyStaffReq = preds[hour]?.staff_required ? preds[hour].staff_required.toFixed(2) : 0.0
                    const hourlyActualStaff = staffPerHour[hour] ? ((staffPerHour[hour] * 10) / 10).toFixed(2) : 0.0
                    const hourlyDiff = (((hourlyStaffReq - hourlyActualStaff) * 10) / 10).toFixed(2)
                    return (
                        <View key={hour} style={{ flexDirection: 'column' }}>
                            <View
                                style={{
                                    ...styles.tableColHeader,
                                    width: '19.0px',
                                    height: 20
                                }}
                            >
                                <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>{hour}</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '19.0px', height: 20 }}>
                                <Text style={{ ...styles.tableCell, marginTop: '5px', alignItems: 'right' }}>
                                    {hourlyPredSales}
                                </Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '19.0px', height: 20 }}>
                                <Text style={{ ...styles.tableCell, marginTop: '5px', alignItems: 'right' }}>
                                    {hourlyStaffReq === 0 ? '0.00' : hourlyStaffReq}
                                </Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '19.0px', height: 20 }}>
                                <Text style={{ ...styles.tableCell, marginTop: '5px', alignItems: 'right' }}>
                                    {hourlyActualStaff === 0 ? '0.00' : hourlyActualStaff}
                                </Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '19.0px', height: 20 }}>
                                <Text style={{ ...styles.tableCell, marginTop: '5px', alignItems: 'right' }}>
                                    {hourlyDiff}
                                </Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '19.0px', height: 20 }}>
                                <Text style={{ ...styles.tableCell, marginTop: '5px' }}></Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '19.0px', height: 20 }}>
                                <Text style={{ ...styles.tableCell, marginTop: '5px' }}></Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '19.0px', height: 20 }}>
                                <Text style={{ ...styles.tableCell, marginTop: '5px' }}></Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '19.0px', height: 20 }}>
                                <Text style={{ ...styles.tableCell, marginTop: '5px' }}></Text>
                            </View>
                        </View>
                    )
                })}
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ ...styles.tableColHeader, width: '46px', height: 20 }}>
                        <Text style={{ ...styles.tableCellHeader, marginTop: '5px' }}>合計</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', alignItems: 'right' }}>
                            {priceFormatter(
                                predictedSales?.customer_predicted_sales || predictedSales?.predicted_sales
                            )}
                        </Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', alignItems: 'right' }}>
                            {hoursWithStaffReq?.fullCount}
                        </Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                        <Text style={{ ...styles.tableCell, marginTop: '5px', alignItems: 'right' }}>
                            {((totalActualStaff * 10) / 10).toFixed(2)}
                        </Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                        <Text style={{ ...styles.tableCell, marginTop: '5px' }}>{'-'}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                        <Text style={{ ...styles.tableCell, marginTop: '5px' }}></Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                        <Text style={{ ...styles.tableCell, marginTop: '5px' }}></Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                        <Text style={{ ...styles.tableCell, marginTop: '5px' }}></Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                        <Text style={{ ...styles.tableCell, marginTop: '5px' }}></Text>
                    </View>
                </View>
            </View>
            <View style={{ alignItems: 'flex-end' }}>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flexDirection: 'column' }}>
                        <View
                            style={{
                                ...styles.tableCol,
                                width: '68.8px',
                                height: 20,
                                backgroundColor: '#E5E5E5',
                                borderLeftWidth: 1
                            }}
                        >
                            <Text style={{ ...styles.tableCell, marginTop: '5px' }}>予定人時売上高</Text>
                        </View>
                        <View
                            style={{
                                ...styles.tableCol,
                                width: '68.8px',
                                height: 20,
                                backgroundColor: '#E5E5E5',
                                borderLeftWidth: 1
                            }}
                        >
                            <Text style={{ ...styles.tableCell, marginTop: '5px' }}>実績人時売上高</Text>
                        </View>
                        <View
                            style={{
                                ...styles.tableCol,
                                width: '68.8px',
                                height: 20,
                                backgroundColor: '#E5E5E5',
                                borderLeftWidth: 1
                            }}
                        >
                            <Text style={{ ...styles.tableCell, marginTop: '5px' }}>差異</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'column' }}>
                        <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                            <Text style={{ ...styles.tableCell, marginTop: '5px', alignItems: 'center' }}>
                                {hourlyEmpSalesPred || ''}
                            </Text>
                        </View>
                        <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                            <Text style={{ ...styles.tableCell, marginTop: '5px' }}></Text>
                        </View>
                        <View style={{ ...styles.tableCol, width: '46px', height: 20 }}>
                            <Text style={{ ...styles.tableCell, marginTop: '5px' }}></Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default PdfFooter
