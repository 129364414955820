import React, { useEffect, useState, useCallback } from 'react'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { useDropzone } from 'react-dropzone'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import {
    Alert,
    Button,
    Box,
    Typography,
    Table,
    TableRow,
    TableCell,
    TextField,
    InputAdornment,
    IconButton
} from '@mui/material'
import {
    Search as SearchIcon,
    KeyboardArrowRight,
    Add as AddIcon,
    Download as DownloadIcon,
    LocationOnOutlined
} from '@mui/icons-material'
import { PATHS } from '../../constants'
import * as XLSX from 'xlsx'
import iconv from 'iconv-lite'

const AdminStoreManagements = ({ history }) => {
    const [searchResults, setSearchResults] = useState([])
    const [search, setSearch] = useState('')
    const [stores, setStores] = useState([])
    const [message, setMessage] = useState('')
    const [uploadError, setUploadError] = useState('')
    const [newStores, setNewStores] = useState([])

    const handleSearch = value => {
        if (value === '') {
            setSearchResults([])
            return
        }
        const results = stores.filter(store => {
            return store.name.includes(value)
        })
        setSearchResults(results)
    }

    const handleSampleDownload = () => {
        const japaneseHeader = ['部署コード', '店舗名', '店舗タイプID']
        const englishHeader = ['seg_code', 'name', 'store_type_id']
        const wb = XLSX.utils.book_new()
        const data = [[...japaneseHeader], [...englishHeader]]
        const ws = XLSX.utils.aoa_to_sheet(data)
        XLSX.utils.book_append_sheet(wb, ws)
        XLSX.writeFile(wb, '店舗一括登録＿サンプル.xlsx')
    }
    const mustField = ['name', 'seg_code', 'store_type_id']
    const formatError =
        'XLSXファイルをアップロードできませんでした。ファイルのフォーマットが正しくありません。もう一度確認してください。'
    const sizeError =
        'XLSXファイルをアップロードできませんでした。ファイルが大きすぎます。10,000行以下のファイルをアップロードしてください。'
    const storePresenceError = 'XLSXファイルをアップロードできませんでした。店舗コードが既に登録されています。'
    const handleError = (key, value) => {
        if (mustField.includes(key) && !value) {
            return true
        }
        return false
    }
    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles[0].name?.split('.')[1] !== 'xlsx') {
            setUploadError(formatError)
            return
        }
        const f = acceptedFiles[0]
        const reader = new FileReader()
        reader.onload = async evt => {
            const bstr = evt.target.result
            const wb = XLSX.read(bstr, { type: 'binary' })
            // Get first worksheet
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            // Convert array of arrays
            let data = XLSX.utils.sheet_to_json(ws, { header: 1 })
            data = data.filter(item => {
                return item.length > 0
            })
            let shiftjis = false
            data[0].forEach(col => {
                if (col.includes('')) {
                    shiftjis = true
                }
            })
            // decodes with iconv and reparses if garbled characters found in first row
            if (shiftjis) {
                const decoded = iconv.decode(bstr, 'shiftjis')
                const wb = XLSX.read(decoded, { type: 'binary' })
                const ws = wb.Sheets[wb.SheetNames[0]]
                data = XLSX.utils.sheet_to_json(ws, { header: 1 })
                data = data.filter(item => {
                    return item.length > 0
                })
            }
            // error handling
            const keys = data[1]
            const segCodeIndex = keys.indexOf('seg_code')
            const segCodeList = []
            let error = false
            // check if file is too large
            if (data.length > 10000) {
                setUploadError(sizeError)
                return
            }
            // check if all required fields are present
            const allFields = mustField.every(field => {
                return keys.includes(field)
            })
            if (!allFields) {
                setUploadError(formatError)
                return
            }

            for (let i = 2; i < data.length; i++) {
                keys.forEach((key, j) => {
                    if (handleError(keys[j], data[i][j])) {
                        setUploadError(formatError)
                        error = true
                    }
                })
                if (!segCodeList.includes(data[i][segCodeIndex])) {
                    segCodeList.push(data[i][segCodeIndex])
                }
            }
            let noStores = true
            await AxiosWithAuth.get('/stores/presence/' + `${segCodeList.join(',')}`).then(res => {
                if (res?.data?.message === 'Some stores not found' || res?.data?.message === 'All stores found') {
                    setUploadError(storePresenceError)
                    noStores = false
                }
            })
            if (!noStores) {
                return
            }
            if (error) {
                return
            }
            setNewStores(data)
        }
        reader.readAsBinaryString(f)
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    useEffect(() => {
        AxiosWithAuth.get('/stores')
            .then(res => setStores(res.data))
            .catch(err => console.log(err))
        if (history?.location?.state?.message) {
            setMessage(history.location.state.message)
            delete history.location.state.message
            history.replace(history.location)
        }
    }, [])

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage('')
            }, 5000)
        }
    }, [message])

    useEffect(() => {
        if (newStores.length === 0) return
        history.push({ pathname: PATHS.adminStoreBulkUpload, state: { data: newStores } })
    }, [newStores])

    return (
        <PageWithTitleLayout title="店舗情報">
            <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <TextField
                    sx={{ width: '45rem', marginTop: '1rem' }}
                    placeholder="店舗検索"
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={search}
                    onChange={e => {
                        setSearch(e.target.value)
                        handleSearch(e.target.value)
                    }}
                    data-testid="store-search-box"
                    onKeyDown={e => (e.key === 'Enter' ? handleSearch(e.target.value) : null)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {searchResults.length > 0 && (
                    <Table sx={{ width: '45em', marginTop: '1rem' }} size="small">
                        {searchResults.map(store => (
                            <TableRow key={store.id}>
                                <TableCell>
                                    <IconButton>
                                        <LocationOnOutlined />
                                    </IconButton>
                                    {store.name || ''}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        onClick={() =>
                                            history.push(PATHS.editStore.replace(':segCode', store.seg_code))
                                        }
                                    >
                                        <KeyboardArrowRight />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                )}
                <div className="title-layout">
                    <div className="title-bar">
                        <Typography variant="subtitle1" margin="1rem" sx={{ width: '45rem' }}>
                            店舗の一括登録
                        </Typography>
                    </div>
                    <div
                        style={{
                            margin: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        {uploadError && (
                            <Alert
                                severity="error"
                                sx={{ width: '45rem', margin: '1rem' }}
                                onClose={() => setUploadError('')}
                            >
                                {uploadError}
                            </Alert>
                        )}
                        {message && (
                            <Alert
                                severity="success"
                                sx={{ width: '45rem', margin: '1rem', whiteSpace: 'pre-line' }}
                                onClose={() => setMessage('')}
                            >
                                {message}
                            </Alert>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '48rem' }}>
                            <Button onClick={handleSampleDownload}>
                                <DownloadIcon /> XLSX形式ファイルのサンプルをダウンロードする
                            </Button>
                        </div>
                        <Box
                            sx={{
                                p: 2,
                                bgcolor: '#F5F5F5',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gridTemplateColumns: { md: '1fr 1fr' },
                                gap: 2,
                                width: '45rem',
                                height: '10rem',
                                border: '1px dashed #D9D9D9',
                                borderRadius: '2px',
                                color: '#8C8C8C',
                                '&:hover': {
                                    color: 'gray',
                                    backgroundColor: '#D9D9D9'
                                }
                            }}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            <Box>ファイルをここにドラッグ＆ドロップ</Box>
                        </Box>
                    </div>
                </div>
            </div>
        </PageWithTitleLayout>
    )
}
export default AdminStoreManagements
