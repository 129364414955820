import { Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BehaviorSubject } from 'rxjs'
import { PATHS } from '../../constants'
import { AxiosWithAuth, signupWithToken } from '../../Utilities/authenticationService'
import Layout from '../Layout/Layout'
import * as Sentry from '@sentry/react'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))

const EmailConfirmation = ({ history }) => {
    const { token } = useParams()
    const [signupSuccess, setSignupSuccess] = useState(null)
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(() => {
        if (token) {
            // when there is a token in the url, it means the user is signing up for the first time
            // so we need to logout any current logged in users, and sign up the new user with the token
            // then setup the employments
            localStorage.removeItem('currentUser')
            currentUserSubject.next(null)
            AxiosWithAuth.delete('/logout').then(() => {
                signupWithToken(token)
                    .then(res => {
                        if (res.status === 200) {
                            setSignupSuccess(true)
                            console.log('Confirmed email')
                            setTimeout(() => {
                                // wait 3 seconds before redirecting to login
                                history.push(PATHS.login)
                            }, 3000)
                        }
                    })
                    .catch(e => {
                        Sentry.captureException(e)
                        if (e.response?.data.confirmation_token) {
                            setErrorMsg('トークンが失効しましたので、トークンの再発行をお願いします')
                        }
                        if (e.response?.data.email) {
                            setErrorMsg('ユーザー登録は既に完了済みです。以下のリンクからログインしてください')
                        }
                        setSignupSuccess(false)
                        console.log('Signup failed...')
                    })
            })
        }
    }, [])

    if (signupSuccess === null) {
        return <div></div>
    }
    if (signupSuccess === false) {
        return (
            <Layout>
                {' '}
                <Alert severity="error">
                    {errorMsg.length > 0 ? errorMsg : '登録に失敗しました。しばらくしてから再度お試しください'}
                </Alert>{' '}
                <br />
                <a
                    style={{ color: 'blue', textDecoration: 'underline', marginLeft: '1rem', fontSize: '1.2rem' }}
                    href={PATHS.login}
                >
                    {' '}
                    ログイン画面へ
                </a>
            </Layout>
        )
    }
    if (signupSuccess === true) {
        return (
            <Layout>
                {' '}
                <Alert severity="success">登録が完了しました。ログインしてください</Alert>{' '}
            </Layout>
        )
    }
}

export default EmailConfirmation
