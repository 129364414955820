import React from 'react'
import { useParams } from 'react-router-dom'
import { Radio, Drawer, Typography, Divider, IconButton, Button, TextField } from '@mui/material'
import { AddOutlined, Close, DeleteOutline } from '@mui/icons-material'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../ButtonWithConfirmPrompt'

const EditDailyNoteTemplateDrawer = ({
    templates,
    setTemplates,
    editTemplate,
    setEditTemplate,
    setSnackbarOpen,
    setSnackbarMessage
}) => {
    const { employmentId } = useParams()

    const saveTemplate = async () => {
        const templatesToSend = templates.filter(template => template?.note?.split(' ').join('').length !== 0)
        await AxiosWithAuth.post('/daily_note_templates', {
            employment_id: employmentId,
            templates: templatesToSend
        })
            .then(() => {
                setEditTemplate(false)
                setSnackbarOpen(true)
                setSnackbarMessage('メモテンプレートを保存しました')
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Drawer anchor="right" open={editTemplate} onClose={() => setEditTemplate(false)}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '20vw',
                    margin: '1rem'
                }}
            >
                <Typography>メモテンプレートを編集</Typography>
                <IconButton onClick={() => setEditTemplate(false)}>
                    <Close />
                </IconButton>
            </div>
            <Divider />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '20vw',
                    margin: '1rem'
                }}
            >
                <Button
                    variant="text"
                    startIcon={<AddOutlined />}
                    onClick={() => setTemplates([...templates, { note: '', is_default: false }])}
                >
                    テンプレートを追加
                </Button>
                {templates.map((template, index) => (
                    <div key={index}>
                        <TextField
                            label="メモテンプレート"
                            variant="outlined"
                            style={{ margin: '1rem', width: '18vw' }}
                            value={template?.note}
                            disabled={template.is_default}
                            multiline
                            onChange={e => {
                                const newTemplates = [...templates]
                                newTemplates[index].note = e.target.value
                                setTemplates(newTemplates)
                            }}
                        />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Radio
                                checked={template.is_default}
                                value={template.is_default}
                                onClick={() => {
                                    const newTemplates = [...templates]
                                    newTemplates[index].is_default = !template.is_default
                                    setTemplates(newTemplates)
                                }}
                            />
                            <Typography>デフォルトに設定する</Typography>
                            <IconButton
                                onClick={() => {
                                    const newTemplates = [...templates]
                                    newTemplates.splice(index, 1)
                                    setTemplates(newTemplates)
                                    if (template.id) {
                                        AxiosWithAuth.delete(`/daily_note_templates/${template.id}`)
                                    }
                                }}
                            >
                                <DeleteOutline />
                            </IconButton>
                        </div>
                    </div>
                ))}
                <ButtonWithConfirmPrompt
                    component={
                        <Button variant="contained" style={{ margin: '1rem', width: '18vw' }}>
                            保存する
                        </Button>
                    }
                    title="メモテンプレートを保存"
                    content="メモテンプレートを保存しますか？"
                    handleConfirm={saveTemplate}
                />
            </div>
        </Drawer>
    )
}

export default EditDailyNoteTemplateDrawer
