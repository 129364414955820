import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Layout } from '..'
import { TextField, Stack, Box, Button, InputAdornment, IconButton, Typography, Alert } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material/'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import SubmitSvg from '../SubmitShift/SubmitSvg'
import { validatePassword } from '../../Utilities/fieldValidators'

const ResetPassword = ({ history }) => {
    const { token } = useParams()
    const [newPass, setNewPass] = useState('')
    const [newConfirmation, setNewConfirmation] = useState('')
    const [resetPassword, setResetPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState(false)

    const handleSubmit = () => {
        if (newPass !== newConfirmation) {
            setError('mismatched')
            return
        }
        if (!validatePassword(newPass)) {
            setError('invalid')
            return
        }
        setError(false)
        AxiosWithAuth.post('/changePassword', {
            token,
            password: newPass,
            confirmation: newConfirmation
        }).then(res => {
            res.status == 200 && setResetPassword(true)
        })
    }

    if (resetPassword) {
        return (
            <Layout>
                <div
                    style={{
                        height: 'calc(100vh - 56px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '0 1rem'
                    }}
                >
                    <SubmitSvg />
                    <Typography variant="header1" sx={{ fontSize: '20px' }}>
                        パスワードを再設定しました。
                    </Typography>
                    <Button
                        onClick={() => {
                            setResetPassword(null)
                            history.push('/login')
                        }}
                        variant="contained"
                        color="paloBlue"
                        fullWidth
                        sx={{ mt: 'auto', mb: '2rem' }}
                    >
                        ログイン画面へ
                    </Button>
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <PageWithTitleLayout title="パスワード再設定">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    {error === 'mismatched' && (
                        <Alert sx={{ mx: '1rem' }} severity="error">
                            パスワードが一致しません。
                        </Alert>
                    )}
                    {error === 'invalid' && (
                        <Alert sx={{ mx: '1rem' }} severity="error">
                            パスワードのフォーマットが正しくありません。
                        </Alert>
                    )}
                </div>
                <Box className="input-box shrinkable" component="form" autoComplete="off">
                    <Stack spacing={2} sx={{ m: '1rem' }}>
                        <TextField
                            label="新しいパスワード"
                            placeholder={'例：000abcdABCD'}
                            helperText="半角アルファベット大文字・小文字・数字それぞれ一つ以上含む10文字以上"
                            InputLabelProps={{ shrink: true }}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowPassword(!showPassword)
                                            }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            value={newPass}
                            onChange={e => setNewPass(e.target.value)}
                            error={error}
                        />
                        <TextField
                            label="新しいパスワード確認"
                            placeholder={'例：000abcdABCD'}
                            helperText="半角アルファベット大文字・小文字・数字それぞれ一つ以上含む10文字以上"
                            InputLabelProps={{ shrink: true }}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowPassword(!showPassword)
                                            }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            value={newConfirmation}
                            onChange={e => setNewConfirmation(e.target.value)}
                            error={error}
                            sx={{ mt: '1rem' }}
                        />
                    </Stack>
                    <Button
                        variant="contained"
                        color="paloBlue"
                        sx={{ mt: '1rem', height: '42px' }}
                        disabled={!newPass || !newConfirmation}
                        onClick={() => {
                            handleSubmit()
                        }}
                    >
                        送信する
                    </Button>
                </Box>
            </PageWithTitleLayout>
        </Layout>
    )
}
export default ResetPassword
