import React, { useEffect, useState, useContext } from 'react'
import { Alert, List, ListItem, ListItemIcon, Typography, Button, Collapse } from '@mui/material'
import { KeyboardArrowRight, SendOutlined, EventNoteOutlined, EditOutlined } from '@mui/icons-material/'
import { PATHS } from '../../constants'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import SelectedStore from '../SelectedStore'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'

const Home = ({ history }) => {
    const [submitted, setSubmitted] = useState(true)
    const [notifications, setNotifications] = useState([])
    const { setUserEmployments, isManager, canEditShiftFor, isAdmin, userInfo } = useContext(EmploymentsContext)
    const [alerts, setAlerts] = useState([])
    const now = new Date()
    const [isEmployee, setIsEmployee] = useState(true)

    const firstHalf = now.getDate() < 16
    const currentShiftMonthDate = new Date()
    currentShiftMonthDate.setMonth(now.getMonth() + (firstHalf ? 1 : 2))
    const currentShiftMonth = currentShiftMonthDate.toISOString().slice(0, 7) // YYYY-MM
    // if it's earlier than the 16th, 'current' month is next month
    // later than the 16th -> month after next

    const handleNotificationClose = n => {
        AxiosWithAuth.put(`/notifications/${n.id}`, {
            is_read: true
        })
            .then(() => {
                const newNotifications = notifications.filter(not => not.id !== n.id)
                const updatedNotification = notifications.find(not => not.id === n.id)
                updatedNotification.is_read = true
                setNotifications([...newNotifications, updatedNotification])
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        AxiosWithAuth.get('/employments').then(res => {
            if (res.data.length === 0) {
                setIsEmployee(false)
            }
            let submit = true
            let notice = []
            setUserEmployments(res.data)
            res.data.forEach(e => {
                if (!(e.submitted.includes(currentShiftMonth) || e.confirmed.includes(currentShiftMonth))) {
                    // if any of the employments is missing a submitted shift for the current month
                    submit = false
                }
                if (e.notifications.length > 0) {
                    e.notifications.forEach(n => {
                        if (n.is_read === false) {
                            notice.push(n)
                        }
                    })
                }
            })
            if (!submit) setSubmitted(false)
            if (notice.length > 0) setNotifications(notice)
        })
        AxiosWithAuth.get('/alerts').then(res => {
            let combinedAlerts = []
            for (const [, value] of Object.entries(res.data)) {
                if (value.length > 0) {
                    combinedAlerts = [...combinedAlerts, ...value]
                }
            }
            setAlerts(combinedAlerts)
        })
    }, [firstHalf, currentShiftMonth])

    if (userInfo?.view_only) {
        return <Redirect to={PATHS.viewOnly.home} />
    }

    const homeComponent = (
        <PageWithTitleLayout title="ホーム">
            <SelectedStore />
            <div className="shrinkable">
                {notifications.length > 0 &&
                    notifications.map((n, i) => (
                        <Collapse in={!n.is_read} key={n.id}>
                            <Alert
                                key={i}
                                severity="info"
                                onClose={() => handleNotificationClose(n)}
                                sx={{ display: !isManager ? 'flex' : 'none' }}
                            >
                                お知らせ
                                <br />
                                {n.message}
                            </Alert>
                        </Collapse>
                    ))}
                <Alert severity="error" sx={{ display: !submitted && firstHalf ? 'flex' : 'none' }}>
                    お知らせ
                    <br />
                    {((currentShiftMonthDate.getMonth() + 11) % 12) + 1}月16日までに
                    {currentShiftMonthDate.getMonth() + 1}月のシフトを提出してください。
                </Alert>
                {alerts.length > 0 &&
                    alerts.map(alert => (
                        <Alert severity={alert.severity} key={alert.message}>
                            {alert.message}
                        </Alert>
                    ))}
                <List>
                    {isManager || canEditShiftFor[localStorage.selected_emp] ? (
                        <ListItem
                            key="createShift"
                            disabled={!isEmployee}
                            onClick={() =>
                                isEmployee ? history.push(PATHS.managerStores + localStorage.selected_emp) : null
                            }
                        >
                            <ListItemIcon>
                                <EditOutlined />
                            </ListItemIcon>
                            シフト表を作成する
                            <KeyboardArrowRight sx={{ ml: 'auto' }} />
                        </ListItem>
                    ) : null}
                    <ListItem
                        key="check"
                        onClick={() => (isEmployee ? history.push(PATHS.check) : null)}
                        disabled={!isEmployee}
                    >
                        <ListItemIcon>
                            <EventNoteOutlined />
                        </ListItemIcon>
                        シフトを確認する
                        <KeyboardArrowRight sx={{ ml: 'auto' }} />
                    </ListItem>
                    <ListItem
                        key="submitShift"
                        onClick={() => (isEmployee ? history.push(PATHS.stores + localStorage.selected_emp) : null)}
                        disabled={!isEmployee}
                    >
                        <ListItemIcon>
                            <SendOutlined />
                        </ListItemIcon>
                        希望シフトを提出する
                        <KeyboardArrowRight sx={{ ml: 'auto' }} />
                    </ListItem>
                </List>
            </div>
            {(isManager || isAdmin) && (
                <div className="shrinkable" style={{ marginTop: 'auto', marginBottom: '4.5rem' }}>
                    <Typography variant="subtitle1" margin="1rem">
                        新規会員の登録はこちらから
                    </Typography>
                    <Button
                        onClick={e => {
                            e.preventDefault()
                            history.push(PATHS.signup)
                        }}
                        variant="contained"
                        color="paloBlue"
                        sx={{
                            marginLeft: '1rem',
                            marginRight: '1rem',
                            marginBottom: '1.5rem',
                            height: '42px',
                            width: 'calc(100% - 2rem)'
                        }} // weird width is because 100% and fullWidth make it go off the page
                    >
                        新規会員登録
                    </Button>
                </div>
            )}
        </PageWithTitleLayout>
    )

    return homeComponent
}

export default Home
