import { useState, useEffect } from 'react'

export const useStickyState = (defaultValue, key, params) => {
    const [value, setValue] = useState(() => {
        // If params is passed, use it instead of localStorage
        if (params) {
            return params
        }
        const stickyValue = window.localStorage.getItem(key)
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
    })

    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return [value, setValue]
}
