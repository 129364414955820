import moment from 'moment'
import { HOURS_COEFF } from '../constants'
import { TextField } from '@mui/material'

export const timezoneOffsets = {
    'Pacific Time (US & Canada)': -8,
    'Central Time (US & Canada)': -6,
    'Eastern Time (US & Canada)': -5,
    Japan: 9,
    Hawaii: -10,
    UTC: 0
}

export const stitchShifts = (shifts, tzOffset = 0, skipOffset = false) => {
    let submittedShifts = [...shifts]
    // sort by time
    submittedShifts = submittedShifts.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
    // group by employment id
    let groupedShifts = submittedShifts.reduce((acc, shift) => {
        acc[shift.employment_id] = acc[shift.employment_id] || []
        acc[shift.employment_id].push(shift)
        return acc
    }, {})

    // iterate through each employment and stitch together the shifts
    const allShifts = []
    for (const [, employeeShifts] of Object.entries(groupedShifts)) {
        let start = null
        let end = null
        let prevShift = null
        const stitchedShifts = []
        const offsetShifts = employeeShifts.map(shift => {
            const offsetStart = shift.startDate.getTimezoneOffset() / 60 + tzOffset
            const offsetEnd = shift.endDate.getTimezoneOffset() / 60 + tzOffset

            const offsetStartDate = new Date(shift.startDate) // avoiding editing the original shift in state
            const offsetEndDate = new Date(shift.endDate)

            if (!skipOffset) {
                offsetStartDate.setHours(shift.startDate.getHours() - offsetStart)
                offsetEndDate.setHours(shift.endDate.getHours() - offsetEnd)
            }

            return { ...shift, startDate: offsetStartDate, endDate: offsetEndDate }
        })
        offsetShifts.forEach((shift, index) => {
            if (start === null) {
                start = shift.startDate
                end = shift.endDate
            } else if (shift.startDate.getTime() === prevShift.endDate.getTime() && shift.status === prevShift.status) {
                end = shift.endDate
            } else {
                stitchedShifts.push({
                    start_time: start,
                    end_time: end,
                    employment_id: shift.employment_id,
                    status: prevShift.status
                })
                start = shift.startDate
                end = shift.endDate
            }

            if (index === employeeShifts.length - 1) {
                end = shift.endDate
                stitchedShifts.push({
                    start_time: start,
                    end_time: end,
                    employment_id: shift.employment_id,
                    status: 'confirmed'
                })
            }
            prevShift = shift
        })
        allShifts.push(...stitchedShifts)
    }
    return allShifts
}

export const splitShiftsIntoBlocks = shifts => {
    const splitShifts = []
    shifts.forEach(shift => {
        const startHour = shift.startDate.getHours()
        let endHour = shift.endDate.getHours()
        const startMinutes = shift.startDate.getMinutes()
        const endMinutes = shift.endDate.getMinutes()
        let breakStartHour = shift.breakStartDate ? shift.breakStartDate.getHours() : null
        const breakStartMinutes = shift.breakStartDate ? shift.breakStartDate.getMinutes() : null
        let breakEndHour = shift.breakEndDate ? shift.breakEndDate.getHours() : null
        const breakEndMinutes = shift.breakEndDate ? shift.breakEndDate.getMinutes() : null
        if (shift.endDate.getDate() !== shift.startDate.getDate()) {
            endHour += 24
        }
        if (breakEndHour < startHour) {
            breakEndHour += 24
        }
        if (breakStartHour < startHour) {
            breakStartHour += 24
        }

        for (let i = startHour * 60 + startMinutes; i < endHour * 60 + endMinutes; i += 15) {
            if (
                breakStartHour &&
                breakEndHour &&
                i >= breakStartHour * 60 + breakStartMinutes &&
                i < breakEndHour * 60 + breakEndMinutes
            ) {
                continue
            } else {
                const currentHour = Math.floor(i / 60)
                const currentMinutes = i % 60
                const nextHour = Math.floor((i + 15) / 60)
                const nextMinutes = (i + 15) % 60
                const newShift = {
                    ...shift,
                    originalStatus: shift.status,
                    id: `${shift.id} ${i}`,
                    startDate: new Date(new Date(shift.startDate).setHours(currentHour, currentMinutes)),
                    endDate: new Date(new Date(shift.startDate).setHours(nextHour, nextMinutes))
                }
                delete newShift.created_at
                delete newShift.updated_at
                splitShifts.push(newShift)
            }
        }
    })
    return splitShifts
}

export const parseTimeBlock = (timeblock, tzOffset = 0) => {
    const dates = timeblock.replaceAll(' UTC', '').split('..')
    const [startDate, endDate] = dates.map(e => moment.utc(e).toDate())
    const startDateTokyoOffset = startDate.getTimezoneOffset() / 60 + tzOffset
    const endDateTokyoOffset = endDate.getTimezoneOffset() / 60 + tzOffset
    startDate.setHours(startDate.getHours() + startDateTokyoOffset)
    endDate.setHours(endDate.getHours() + endDateTokyoOffset)

    return [startDate, endDate]
}

export const getTotalShiftHours = shifts => {
    return shifts.reduce((acc, shift) => {
        if (shift.end_time < shift.start_time) {
            return acc
        }
        return acc + (shift.end_time - shift.start_time) / HOURS_COEFF
    }, 0)
}

export const getAllXDays = (yearMonth = moment().format('YYYY-MM'), dayOfWeek) => {
    // yearMonth is string in format YYYY-MM
    // dayOfWeek is integer 0-6, sunday to saturday
    const days = []
    const date = moment(yearMonth, 'YYYY-MM').startOf('month')
    const month = date.month()
    while (date.month() === month) {
        if (date.day() === dayOfWeek) {
            days.push(date.date())
            date.add(7, 'day')
        } else {
            date.add(1, 'day')
        }
    }
    return days
}

export const getDays = yearMonth => {
    const days = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] }
    const date = moment(yearMonth, 'YYYY-MM').startOf('month')
    const month = date.month()
    while (date.month() === month) {
        days[date.day()].push(date.date())
        date.add(1, 'day')
    }
    return days
}

export const InputTime = ({
    startValue,
    endValue,
    onfocus,
    startOnChange,
    endOnChange,
    sx,
    startError,
    endError,
    disabled = false,
    handleKeyPress = () => {},
    startTestId,
    endTestId,
    addBreak = false,
    breakStartTime = '',
    breakEndTime = '',
    setBreakStartTime = () => {},
    setBreakEndTime = () => {},
    breakStartError = false,
    breakEndError = false
}) => {
    const startHelperText = startError ? '15分単位でのスケジュールが可能です。' : null
    const endHelperText = endError ? '15分単位でのスケジュールが可能です。' : null
    const breakStartHelperText = breakStartError ? '15分単位でのスケジュールが可能です。' : null
    const breakEndHelperText = breakEndError ? '15分単位でのスケジュールが可能です。' : null
    return (
        <>
            <TextField
                type="time"
                id="start-time"
                label="開始時間"
                sx={sx}
                value={startValue}
                onChange={startOnChange}
                onFocus={onfocus}
                error={startError}
                helperText={startHelperText}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 900, 'data-testid': startTestId }}
                disabled={disabled}
                onKeyPress={handleKeyPress}
            />
            <TextField
                type="time"
                id="end-time"
                label="終了時間"
                sx={sx}
                value={endValue}
                onChange={endOnChange}
                onFocus={onfocus}
                error={endError}
                helperText={endHelperText}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 900, 'data-testid': endTestId }}
                disabled={disabled}
                onKeyPress={handleKeyPress}
            />
            {addBreak && (
                <>
                    <TextField
                        type="time"
                        id="break-start-time"
                        label="休憩開始時間"
                        sx={sx}
                        value={breakStartTime}
                        onChange={setBreakStartTime}
                        onFocus={onfocus}
                        error={breakStartError}
                        helperText={breakStartHelperText}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 900 }}
                        disabled={disabled}
                        onKeyPress={handleKeyPress}
                    />
                    <TextField
                        type="time"
                        id="break-end-time"
                        label="休憩終了時間"
                        sx={sx}
                        value={breakEndTime}
                        onChange={setBreakEndTime}
                        onFocus={onfocus}
                        error={breakEndError}
                        helperText={breakEndHelperText}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 900 }}
                        disabled={disabled}
                        onKeyPress={handleKeyPress}
                    />
                </>
            )}
        </>
    )
}
