import React from 'react'
import { Typography, Alert } from '@mui/material'

const PageWithTitleLayout = ({ title, message, setMessage, children, style }) => (
    <div className="whole-contents">
        <div className="title-layout" style={{ ...style }}>
            <div className="title-bar">
                <Typography variant="subtitle1" margin="1rem">
                    {title}
                </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {message?.length > 0 && (
                    <Alert
                        style={{ width: '390px', alignSelf: 'center' }}
                        onClose={() => setMessage('')}
                        severity="error"
                    >
                        {message}
                    </Alert>
                )}
            </div>

            {children}
        </div>
    </div>
)

export default PageWithTitleLayout
