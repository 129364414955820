import React, { useContext, useEffect, useMemo, useState } from 'react'
import { TextField, Stack, CircularProgress, Checkbox, FormControlLabel } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material/'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { ROLE_MAPPINGS, PART_FULLTIME_MAPPINGS, OPEN_CLOSE_MAPPINGS } from '../../constants'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import EditKanaName from './UserEditFlows/EditKanaName'
import EditKanjiName from './UserEditFlows/EditKanjiName'
import EditMiddleName from './UserEditFlows/EditMiddleName'
import EditNickname from './UserEditFlows/EditNickname'
import EditEmail from './UserEditFlows/EditEmail'
import EditPhone from './UserEditFlows/EditPhone'
import EditPassword from './UserEditFlows/EditPassword'
import EditCode from '../ManagerSettings/EditFlows/EditCode'
import EditHallKitchen from '../ManagerSettings/EditFlows/EditHallKitchen'
import EditPartFulltime from '../ManagerSettings/EditFlows/EditPartFulltime'
import EditOpenClose from '../ManagerSettings/EditFlows/EditOpenClose'
import EditStores from '../ManagerSettings/EditFlows/EditStores'
import EditRank from '../ManagerSettings/EditFlows/EditRank'
import EditHourlyWage from '../ManagerSettings/EditFlows/EditHourlyWage'

const UserSettings = ({ history }) => {
    const { fetchAndSetEmployments, isAdmin } = useContext(EmploymentsContext)
    const [info, setInfo] = useState()
    const [employments, setEmployments] = useState([])
    const [editing, setEditing] = useState(false)
    const employmentIds = useMemo(() => employments.map(e => e.id), [employments])

    const fetch = async () => {
        const userInfo = await AxiosWithAuth.get('/users/me')
        const userEmploymentsInfo = await AxiosWithAuth.get('/employments/')
        setEmployments(userEmploymentsInfo.data)
        setInfo({
            ...userEmploymentsInfo?.data[0],
            id: userInfo.data.id,
            employmentId: userEmploymentsInfo?.data[0]?.id,
            ...userInfo.data
        })
    }

    useMemo(() => {
        if (!history.location.pathname.includes('editing')) {
            setEditing(false)
        }
    }, [history.location.pathname])

    const handleEditing = field => {
        if (!history.location.pathname.includes('editing')) {
            history.push(history.location.pathname + '/editing')
        }
        setEditing(field)
    }

    const handleReturn = () => {
        fetch()
        fetchAndSetEmployments()
        setEditing(false)
    }

    useEffect(() => {
        fetch()
    }, [])

    if (!info) {
        return <CircularProgress sx={{ mx: '50%', mt: '10%' }} />
    }

    if (editing) {
        return editing === 'kana-name' ? (
            <EditKanaName
                current={{
                    nameLast: info.name_last,
                    nameFirst: info.name_first
                }}
                handleReturn={handleReturn}
                propsId={info.id}
            />
        ) : editing === 'kanji-name' ? (
            <EditKanjiName
                current={{
                    kanjiLast: info.kanji_last,
                    kanjiFirst: info.kanji_first
                }}
                handleReturn={handleReturn}
                propsId={info.id}
            />
        ) : editing === 'name-middle' ? (
            <EditMiddleName
                current={{
                    nameMiddle: info.name_middle
                }}
                handleReturn={handleReturn}
                propsId={info.id}
            />
        ) : editing === 'nickname' ? (
            <EditNickname current={{ nickname: info.nickname }} handleReturn={handleReturn} propsId={info.id} />
        ) : editing === 'email' ? (
            <EditEmail current={info.email} handleReturn={handleReturn} propsId={info.id} history={history} />
        ) : editing === 'phone' ? (
            <EditPhone current={info.phone} handleReturn={handleReturn} propsId={info.id} />
        ) : editing === 'password' ? (
            <EditPassword handleReturn={handleReturn} propsId={info.id} emp_cd={info.emp_cd} />
        ) : editing === 'stores' ? (
            <EditStores
                current={employments}
                handleReturn={handleReturn}
                employmentInfo={info}
                fetchAndSetEmployments={fetchAndSetEmployments}
                history={history}
            />
        ) : editing === 'emp_cd' ? (
            <EditCode current={info.emp_cd} handleReturn={handleReturn} propsId={info.id} />
        ) : editing === 'kitchen/hall' ? (
            <EditHallKitchen current={info.role} handleReturn={handleReturn} employments={employmentIds} />
        ) : editing === 'part/fulltime' ? (
            <EditPartFulltime current={info.e_type} handleReturn={handleReturn} info={info} />
        ) : editing === 'open/close' ? (
            <EditOpenClose current={info.open_close_duty} handleReturn={handleReturn} employments={employmentIds} />
        ) : editing === 'rank' ? (
            <EditRank current={info.rank} handleReturn={handleReturn} employments={employmentIds} />
        ) : editing === 'hourly_wage' ? (
            <EditHourlyWage current={info.hourly_wage} handleReturn={handleReturn} propsId={info.id} />
        ) : null
    }

    return (
        <PageWithTitleLayout title="あなたのアカウント">
            <Stack spacing={2} margin="1rem" className="shrinkable">
                <TextField
                    id="kana name"
                    label="氏名（かな）"
                    onClick={() => handleEditing('kana-name')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={`${info.name_last} ${info.name_first}` || '選択してください'}
                />
                <TextField
                    id="kanji name"
                    label="氏名（漢字）"
                    onClick={() => handleEditing('kanji-name')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={
                        (info.kanji_last ? `${info.kanji_last} ` : '') +
                            (info.kanji_first ? `${info.kanji_first}` : '') || '入力されていません'
                    }
                />
                <TextField
                    id="middle name"
                    label="ミドルネーム"
                    onClick={() => handleEditing('name-middle')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={info.name_middle ? `${info.name_middle} ` : '' || '入力されていません'}
                />
                <TextField
                    id="nickname"
                    label="ニックネーム"
                    onClick={() => handleEditing('nickname')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={info.nickname || '入力されていません'}
                />
                <TextField
                    id="email"
                    type="email"
                    label="メールアドレス"
                    onClick={() => handleEditing('email')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={info.email || '選択してください'}
                />
                <TextField
                    id="phone"
                    label="電話番号"
                    onClick={() => handleEditing('phone')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={info.phone || '選択してください'}
                />
                <TextField
                    id="password"
                    label="パスワード"
                    onClick={() => handleEditing('password')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={'パスワードを変更する'}
                />
                <TextField
                    id="stores"
                    label="店舗情報"
                    onClick={() => info.is_manager || (info.view_only && handleEditing('stores'))}
                    InputProps={{
                        readOnly: true,
                        endAdornment: info.is_manager || (info.view_only && <KeyboardArrowRight htmlColor="grey" />)
                    }}
                    variant="standard"
                    value={employments.length ? employments.map(e => ` ${e.store.name}`) : '選択されていません'}
                />
                <TextField
                    id="emp_code"
                    label="社員コード"
                    onClick={() => isAdmin === true && handleEditing('emp_cd')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: isAdmin === true && <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={info.emp_cd || '選択されていません'}
                />
                <TextField
                    id="part/fulltime"
                    label="雇用形態"
                    onClick={() => isAdmin && handleEditing('part/fulltime')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: isAdmin && <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={
                        PART_FULLTIME_MAPPINGS[info.e_type || (info.is_manager && 'manager')] || '選択されていません'
                    }
                />
                <TextField
                    id="kitchen/hall"
                    label="勤務タイプ"
                    onClick={() => info.is_manager && handleEditing('kitchen/hall')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: info.is_manager && <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={ROLE_MAPPINGS[info.role] || '選択されていません'}
                />
                <TextField
                    id="open/close"
                    label="開閉担当者"
                    onClick={() => info.is_manager && handleEditing('open/close')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: info.is_manager && <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={OPEN_CLOSE_MAPPINGS[info.open_close_duty] || '選択されていません'}
                />
                <TextField
                    id="rank"
                    label="ランク"
                    onClick={() => info.is_manager && handleEditing('rank')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: info.is_manager && <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={info.rank || '選択されていません'}
                />
                <TextField
                    id="hourly_wage"
                    label="時給"
                    onClick={() => handleEditing('hourly_wage')}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <KeyboardArrowRight htmlColor="grey" />
                    }}
                    variant="standard"
                    value={info.hourly_wage || '入力されていません'}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={!info.is_manager || info.e_type !== 'full-time'}
                            style={{ marginLeft: '-0.75rem' }}
                            enabled={info.is_manager || isAdmin}
                            checked={info.view_only}
                            onChange={e => {
                                AxiosWithAuth.put('/users/' + info.id, {
                                    view_only: e.target.checked
                                }).then(res => {
                                    res.status == 200 && location.reload()
                                })
                            }}
                        />
                    }
                    label="正社員(間接)"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            style={{ marginLeft: '-0.75rem' }}
                            checked={info.is_student}
                            onChange={e => {
                                AxiosWithAuth.put('/users/' + info.id, {
                                    is_student: e.target.checked
                                }).then(res => {
                                    res.status == 200 && location.reload()
                                })
                            }}
                        />
                    }
                    label="高校生"
                />
            </Stack>
        </PageWithTitleLayout>
    )
}

export default UserSettings
