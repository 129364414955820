import React, { useState, useEffect } from 'react'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { Table, TableHead, TableRow, TableCell, Button, TableContainer, Alert, CircularProgress } from '@mui/material'
import { PATHS } from '../../constants'
import { AxiosWithAuth } from '../../Utilities/authenticationService'

const AdminUserBulkUpload = ({ history }) => {
    const [newEmployments] = useState(history?.location?.state?.data)
    const [newEmploymentObjs, setNewEmploymentObjs] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const handleBulkSubmit = () => {
        setLoading(true)
        const users = newEmploymentObjs.map(emp => {
            return {
                emp_cd: emp?.emp_code,
                password: emp?.password,
                password_confirmation: emp?.password,
                kanji_first: emp?.kanji_first,
                name_first: emp?.name_first,
                kanji_last: emp?.kanji_last,
                name_last: emp?.name_last,
                name_middle: emp?.name_middle,
                email: emp?.email,
                phone: emp?.phone,
                e_type: emp?.e_type
            }
        })
        const employments = newEmploymentObjs.map(emp => {
            return {
                is_manager: emp?.is_manager === 'YES' ? true : false,
                seg_code: emp?.seg_code,
                role: emp?.role,
                open_close_duty: emp?.open_close_duty === 'YES' ? true : false
            }
        })
        AxiosWithAuth.post('/bulkSignup', {
            users,
            employments
        })
            .then(() => {
                setLoading(false)
                history.push({
                    pathname: PATHS.adminUserManagements,
                    state: { message: 'ユーザーを一括登録しました。' }
                })
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                const error = err?.response?.data?.errors?.email[0] || err?.response?.data?.errors?.emp_cd[0] || ''
                setError(error.length ? error : 'ユーザーの一括登録に失敗しました。')
            })
    }

    useEffect(() => {
        if (newEmployments.length > 0) {
            let emps = []
            let keys = newEmployments[1]
            for (let i = 2; i < newEmployments.length; i++) {
                let emp = {}
                for (let j = 0; j < keys.length; j++) {
                    emp[keys[j]] = newEmployments[i][j]
                }
                emps.push(emp)
            }
            setNewEmploymentObjs(emps)
        }
    }, [newEmployments])

    return (
        <PageWithTitleLayout title="ユーザーの一括登録">
            <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {error && (
                    <Alert severity="error" sx={{ mb: '1rem', width: '40rem' }}>
                        {error}
                    </Alert>
                )}
                <TableContainer
                    sx={{ border: '1px solid lightgray', margin: '0 auto', maxHeight: 700, maxWidth: '55rem' }}
                >
                    {newEmployments && (
                        <Table sx={{ marginTop: '1rem' }}>
                            <TableHead sx={{ height: '1.5rem' }}>
                                <TableRow>
                                    {newEmployments[0] &&
                                        newEmployments[0].map((header, index) => (
                                            <TableCell style={{ minWidth: '7rem' }} key={index}>
                                                {header}
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>

                            {newEmployments.map((emp, idx) => {
                                if (idx >= 2) {
                                    return (
                                        <TableRow key={idx}>
                                            {newEmployments[0].map((e, i) => {
                                                return <TableCell key={i}>{emp[i]}</TableCell>
                                            })}
                                        </TableRow>
                                    )
                                }
                            })}
                        </Table>
                    )}
                </TableContainer>
            </div>
            <Button
                onClick={handleBulkSubmit}
                type="submit"
                variant="contained"
                color="paloBlue"
                disabled={loading}
                sx={{
                    marginBottom: '1.5rem',
                    marginTop: 'auto',
                    height: '42px',
                    bottom: 0
                }}
                className="shrinkable"
            >
                {!loading ? '一括登録する' : <CircularProgress size={20} />}
            </Button>
            <Button
                onClick={() => {
                    history.push(PATHS.adminUserManagements)
                }}
                type="submit"
                variant="outlined"
                color="paloBlue"
                sx={{
                    marginBottom: '1.5rem',
                    marginTop: 'auto',
                    height: '42px',
                    bottom: 0
                }}
                className="shrinkable"
            >
                キャンセル
            </Button>
        </PageWithTitleLayout>
    )
}

export default AdminUserBulkUpload
