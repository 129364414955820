import React, { useState } from 'react'
import { TextField, Stack, Button } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'
import { validateHiraganaKatakana } from '../../../Utilities/fieldValidators'

const EditMiddleName = ({ current, handleReturn, propsId }) => {
    const { userId } = useParams()
    const [newValue, setNewValue] = useState({ nameMiddle: current.nameMiddle || '' })
    const [errors, setErrors] = useState({})

    const handleNameMiddle = event => {
        const text = event.target.value
        if (!validateHiraganaKatakana(text)) {
            setErrors({ ...errors, nameMiddle: '全角ひらがな・全角カタカナ' })
        } else {
            //eslint-disable-next-line
            const { nameMiddle, ...rest } = errors
            setErrors(rest)
        }
        setNewValue({ ...newValue, nameMiddle: text })
    }
    return (
        <PageWithTitleLayout title="ミドルネームの変更">
            <Stack spacing={2} sx={{ m: '1rem' }} className="shrinkable">
                <TextField
                    label="ミドルネーム"
                    placeholder={`例：${current.nameMiddle || 'ジョン'}`}
                    helperText={errors.name_middle || '全角ひらがな・全角カタカナ'}
                    InputLabelProps={{ shrink: true }}
                    value={newValue.nameMiddle}
                    onChange={e => setNewValue({ ...newValue, nameMiddle: e.target.value })}
                    error={errors.name_middle}
                    inputProps={{ onBlur: handleNameMiddle }}
                />
            </Stack>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        className="shrinkable"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                    >
                        変更して保存する
                    </Button>
                }
                title="ミドルネームの変更"
                content="ミドルネームを変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    AxiosWithAuth.put('/users/' + (userId || propsId), {
                        name_middle: newValue.nameMiddle
                    })
                        .then(res => {
                            res.status == 200 && handleReturn()
                        })
                        .catch(error => {
                            const errors = {}
                            for (const [key, value] of Object.entries(error?.response?.data)) {
                                errors[key] = value.join(' ')
                            }
                            setErrors(errors)
                        })
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditMiddleName
