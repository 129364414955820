import React, { useEffect, useState } from 'react'
import { authenticationService, AxiosWithAuth } from '../../Utilities/authenticationService'
import { PATHS } from '../../constants'
import { Layout } from '..'
import { Typography, Button, Box, InputAdornment, TextField, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material/'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'

const Login = ({ history, location, fetchAndSetUserInfo, fetchAndSetEmployments }) => {
    const [error, setError] = useState({})
    const [employeeCode, setEmployeeCode] = useState()
    const [password, setPassword] = useState()
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        AxiosWithAuth.get()
        if (authenticationService.currentUserValue) {
            history.push(PATHS.home)
        } else {
            localStorage.clear()
        }
    }, [])

    const handleLogin = (code, password) => {
        authenticationService
            .login(code, password)
            .then(res => {
                const postSignInPath = location?.state?.from?.pathname ? location.state.from.pathname : PATHS.home
                if (res.status === 200) {
                    history.push(postSignInPath)
                    fetchAndSetUserInfo()
                    fetchAndSetEmployments()
                }
            })
            .catch(error => {
                if (error.response?.status === 401) {
                    // if unauthorized
                    setError({
                        text: error.response?.data[0]['message'] || 'ログインできませんでした。'
                    })
                } else {
                    // any other status
                    setError({ text: 'ログインできませんでした。' })
                }
            })

        // Resets scroll position to top of page after logging in.
        // This was not working properly on mobile.
        window.scrollTo(0, 0)
    }

    return error.code ? (
        <h1>error</h1>
    ) : (
        <>
            <Layout>
                <PageWithTitleLayout title="ログイン">
                    <Box
                        className="input-box shrinkable"
                        component="form"
                        autoComplete="off"
                        onSubmit={() => {
                            handleLogin(employeeCode, password)
                        }}
                    >
                        <TextField
                            id="employee-code"
                            label="社員コード (Employee code)"
                            placeholder="例：0000000"
                            type="number"
                            variant="outlined"
                            sx={{ marginTop: '1rem', width: '100%' }}
                            fullWidth
                            required={true}
                            error={error.text ? true : false}
                            InputLabelProps={{ shrink: true }}
                            helperText={error.text ? error.text : '半角数字'}
                            onChange={event => {
                                setEmployeeCode(event.target.value)
                            }}
                        />
                        <TextField
                            id="password"
                            label="パスワード (Password)"
                            placeholder="例：000abcd"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            sx={{ marginTop: '1rem' }}
                            fullWidth
                            required={true}
                            error={error.text ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowPassword(!showPassword)
                                            }}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            helperText={error.text ? error.text : '半角英数字を含む8文字以上'}
                            onChange={event => {
                                setPassword(event.target.value)
                            }}
                        />
                        <Button
                            onClick={e => {
                                e.preventDefault()
                                handleLogin(employeeCode, password)
                            }}
                            aria-label="login"
                            type="submit"
                            variant="contained"
                            color="paloBlue"
                            sx={{ marginTop: '1rem', height: '42px' }}
                        >
                            ログイン
                        </Button>
                        <a
                            onClick={() => {
                                history.push(PATHS.forgotPassword)
                            }}
                        >
                            <Typography
                                sx={{
                                    height: '2rem',
                                    marginTop: '.5rem',
                                    textAlign: 'right',
                                    textDecoration: 'underline',
                                    fontSize: '.75rem'
                                }}
                                variant="subtitle2"
                            >
                                パスワードを忘れた方はこちら
                            </Typography>
                        </a>
                        <a
                            onClick={() => {
                                history.push(PATHS.resendConfirmation)
                            }}
                        >
                            <Typography
                                sx={{
                                    height: '2rem',
                                    textAlign: 'right',
                                    textDecoration: 'underline',
                                    fontSize: '.75rem'
                                }}
                                variant="subtitle2"
                            >
                                確認メールの再送
                            </Typography>
                        </a>
                    </Box>
                </PageWithTitleLayout>
            </Layout>
        </>
    )
}

export default Login
