import React, { useState, useEffect, useContext } from 'react'
import { List, ListItem, Box, ListItemIcon, Alert } from '@mui/material'
import { KeyboardArrowRight, LocationOnOutlined } from '@mui/icons-material/'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { PATHS } from '../../constants'

const StoreManagement = ({ history }) => {
    const { userEmployments } = useContext(EmploymentsContext)
    const [stores, setStores] = useState([])
    const [successMessageVisible, setSuccessMessageVisible] = useState(false)
    const [errorMessageVisible, setErrorMessageVisible] = useState(false)

    useEffect(() => {
        if (userEmployments.length) {
            setStores(userEmployments.filter(employment => employment.is_manager).map(employment => employment.store))
            setErrorMessageVisible(userEmployments.some(employment => employment.store?.store_hours?.length < 7))
        }
    }, [userEmployments])

    useEffect(() => {
        if (history?.location?.message) {
            setSuccessMessageVisible(true)
        }
    }, [])

    return (
        <PageWithTitleLayout title="店舗情報">
            <Box className="shrinkable">
                <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                    {successMessageVisible && (
                        <Alert severity="success" onClose={() => setSuccessMessageVisible(false)}>
                            {history.location.message}
                        </Alert>
                    )}
                    {errorMessageVisible && (
                        <Alert severity="error" onClose={() => setErrorMessageVisible(false)}>
                            {'店舗の営業時間が設定されていません。'}
                        </Alert>
                    )}
                    <List>
                        {stores.map(store => (
                            <ListItem
                                key={store.id}
                                onClick={() => history.push(PATHS.editStore.replace(':segCode', store.seg_code))}
                            >
                                <ListItemIcon>
                                    <LocationOnOutlined />
                                </ListItemIcon>
                                {store.name}
                                <KeyboardArrowRight sx={{ ml: 'auto' }} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Box>
        </PageWithTitleLayout>
    )
}

export default StoreManagement
