import React, { useState } from 'react'
import { Table, TableHead, TableRow, TableCell, TextField, InputAdornment, IconButton } from '@mui/material'
import { Search as SearchIcon, KeyboardArrowRight } from '@mui/icons-material'

import { displayNameWithSpace } from '../../Utilities/japaneseStringUtils'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import useDebounce from '../../useDebounce'

const UserSearch = ({ path, history }) => {
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])

    const searchRequest = () => {
        AxiosWithAuth.post('/users/search', { user: { search } }).then(res => {
            setSearchResults(
                res.data.map(user => {
                    const component = (
                        <TableRow key={user.id}>
                            <TableCell>{displayNameWithSpace(user)}</TableCell>
                            <TableCell>{user.emp_cd}</TableCell>
                            <TableCell align="right">
                                <IconButton
                                    onClick={() => {
                                        history.push(path + user.id)
                                    }}
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    return { ...user, component }
                })
            )
        })
    }

    const debouncedSearchRequest = useDebounce(searchRequest)

    return (
        <>
            <TextField
                sx={{ width: 'min(45rem, 90vw)', marginTop: '1rem' }}
                placeholder="ユーザー検索"
                InputLabelProps={{
                    shrink: true
                }}
                value={search}
                onChange={e => {
                    debouncedSearchRequest()
                    setSearch(e.target.value)
                }}
                data-testid="user-search-box"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            {searchResults.length > 0 && (
                <Table sx={{ width: 'min(45rem, 90vw)', marginTop: '1rem' }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>氏名</TableCell>
                            <TableCell>社員番号</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    {searchResults.slice(0, 100).map(u => u.component)}
                </Table>
            )}
        </>
    )
}

export default UserSearch
