import React, { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import {
    Typography,
    TableContainer,
    TextField,
    CircularProgress,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Link,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Checkbox,
    FormControlLabel,
    Drawer,
    Divider,
    Tooltip,
    Snackbar,
    Alert
} from '@mui/material'
import { PATHS } from '../../constants'
import { CheckCircleOutlined, Close, CropSquare, PictureAsPdf, FileDownloadOutlined } from '@mui/icons-material'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import '../CheckShift/checkShift.css'
import moment from 'moment'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { WEEKDAY_MAPPINGS } from '../../constants'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import SelectedStore from '../SelectedStore'
import { desktopHeaderRowStyle } from '../CheckShift/Calendars/calendarStyles'
import SubmitSvg from '../SubmitShift/SubmitSvg'
import { isMobile } from '../../Utilities/isMobile'
import XLSX from 'xlsx'
import EditMonthlyShiftBody from './EditMonthlyShiftBody'
import CheckMonthlyShiftBody from './CheckMonthlyShiftBody'
import ActualMonthlyShiftBody from './ActualMonthlyShiftBody'
import ButtonWithConfirmPrompt from '../ButtonWithConfirmPrompt'
import PdfBulkExportButton from '../ShiftPdfExport/PdfBulkExportButton'

export const computeBackgroundColor = (date, monthlyShift, hasAnyPermissions, isBlocker) => {
    const nonGrayFields = ['workday', 'holiday', 'totalHours', 'totalPay', 'monthlyTotalHours']
    const isSatuarday = moment(date).format('dddd') === 'Saturday'
    const isSunday = moment(date).format('dddd') === 'Sunday'
    const isHoliday = monthlyShift?.holidays?.find(holiday => holiday.date === date)
    let colorToReturn = ''
    if (isSunday || isHoliday) {
        colorToReturn = '#FCDDDB' // light red
    } else if (isSatuarday) {
        colorToReturn = '#B8E8FF' // light blue
    } else {
        colorToReturn = 'white' // Make others white o.w. the header will be transparent
    }
    if (hasAnyPermissions) {
        colorToReturn = isBlocker ? '#DADADA' : colorToReturn
    } else if (isBlocker && monthlyShift?.releasedShiftDates.includes(date)) {
        colorToReturn = '#DADADA'
    } else if (!monthlyShift?.releasedShiftDates.includes(date) && !nonGrayFields.includes(date)) {
        colorToReturn = 'whitesmoke'
    }
    return colorToReturn
}

const MonthlyShiftHeader = ({ history }) => {
    const [monthlyShift, setMonthlyShift] = useState({})
    const [storeId, setStoreId] = useState('')
    const [employments, setEmployments] = useState([])
    const [salePreds, setSalePreds] = useState({})
    const [customerPreds, setCustomerPreds] = useState({})
    const { isManager, isManagerFor, canEditShiftFor, userInfo } = useContext(EmploymentsContext)
    const { employmentId, calendarYearMonth } = useParams()
    const [dates, setDates] = useState([])
    const [closerDays, setCloserDays] = useState([])
    const [monthlySalesPreds, setMonthlySalesPreds] = useState(0)
    const [monthlyCustomerPreds, setMonthlyCustomerPreds] = useState(0)
    const [year, month] = calendarYearMonth.split('-')
    const [submitted, setSubmitted] = useState(false)
    const [resubmit, setResubmit] = useState(false)
    const [checkShift, setCheckShift] = useState(false)
    const [activeTab, setActiveTab] = useState('confirmed')
    const [store, setStore] = useState({})
    const [selectedDates, setSelectedDates] = useState([])
    const [loading, setLoading] = useState(false)
    const [fusokuDays, setFusokuDays] = useState([])
    const [exportShiftsDrawerOpen, setExportShiftsDrawerOpen] = useState(false)
    const [selectedDatesForPdfExport, setSelectedDatesForPdfExport] = useState([])
    const [selectedDatesForAutoSchedule, setSelectedDatesForAutoSchedule] = useState([])
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const day = moment(calendarYearMonth, 'YYYY-MM').daysInMonth()

    const hasAnyPermissions = isManager || userInfo?.view_only || canEditShiftFor[employmentId]
    const isTestStore = [602, 412, 1411, 1434, 1512].includes(storeId) | store.name?.includes("AIテスト")

    const isReleased = date => {
        return monthlyShift.releasedShiftDates.includes(date)
    }

    const handleFinalize = () => {
        if (selectedDates.length === 0) {
            setErrorMessage('送信する日付を選択してください')
            return
        }
        setLoading(true)
        AxiosWithAuth.post('/finalizeShifts', {
            month_number: month,
            employment_id: employmentId,
            year: year,
            selected_dates: selectedDates
        }).then(res => {
            setLoading(false)
            if (res.status === 200) {
                setSubmitted(true)
            }
        })
    }

    const fetchMonthlyShifts = (dateStr, employmentId) => {
        AxiosWithAuth.get('/shifts/monthly', {
            params: { employment_id: employmentId, date: dateStr }
        })
            .then(res => {
                setMonthlyShift({
                    shifts: res.data.confirmed_or_released_shifts,
                    shiftHourPerDay: res.data.daily_shift_hour,
                    confirmedEmployeeMonthlyShiftHours: res.data.confirmed_employee_monthly_shift_hour,
                    releasedEmployeeMonthlyShiftHours: res.data.released_employee_monthly_shift_hour,
                    releasedShiftDates: res.data.released_shift_dates,
                    confirmedShiftDays: res.data.confirmed_shift_dates,
                    releasedShifts: res.data.released_shifts,
                    submittedShifts: res.data.submitted_shifts,
                    actualWorkHours: res.data.actual_work_hours,
                    actualWorkHoursPerDay: res.data.daily_actual_work_hours,
                    actualMonthlyWorkHour: res.data.actual_monthly_work_hour,
                    employeeHourlyWage: res.data.employee_hourly_wage,
                    datesWithActualWorkHours: Object.keys(res.data.daily_actual_work_hours),
                    shiftBlockers: res.data.shift_blockers_for_month,
                    shiftAlerts: res.data.shift_alerts_for_month,
                    holidays: res.data.holidays,
                    fusokuHours: res.data.fusoku_hours
                })
                setFusokuDays(res.data.fusoku_dates)
            })
            .catch(error => {
                // Handle error
                console.error('Error fetching monthly shifts:', error)
            })
    }

    // Helper Function to handle unchecking the schedule_days checkbox
    const uncheckAutoScheduleCheckbox = (schedule_day, setSelectedDatesForAutoSchedule) => {
        setSelectedDatesForAutoSchedule(prev => {
            return prev.filter(v => {
                return v !== schedule_day
            })
        })
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            selectedDatesForAutoSchedule.forEach(schedule_day => {
                AxiosWithAuth.get(`/autoschedule_requests/${employmentId}/${schedule_day}`)
                    .then(res => {
                        console.log(res?.data)
                        if (res?.data?.status == 'success') {
                            console.log('autoschedule request success')
                            const dateStr = calendarYearMonth + '-01'
                            fetchMonthlyShifts(dateStr, employmentId)
                            console.log('Updated monthly shift')
                            // pop message to notify updates
                            setSnackbarMessage(`自動スケジュールを完了しました ${schedule_day}`)
                            uncheckAutoScheduleCheckbox(schedule_day, setSelectedDatesForAutoSchedule)
                        } else if (res?.data?.status === 'failed') {
                            console.log('autoschedule request failed')
                            // Pop message to notify request failed
                            setSnackbarMessage(`自動スケジュールを実行しました (ブロッカーあり) ${schedule_day}`)
                            uncheckAutoScheduleCheckbox(schedule_day, setSelectedDatesForAutoSchedule)
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 404) {
                            setSnackbarMessage(`自動スケジュールに失敗しました ${schedule_day}`)
                            console.log(`No autoschedule requests found for ${schedule_day}`)
                        } else {
                            console.error('Error fetching autoschedule requests:', error)
                        }
                    })
            })
        }, 5000)

        return () => clearInterval(intervalId)
    }, [selectedDatesForAutoSchedule, employmentId])

    const handleAutoSchedule = () => {
        const postData = {
            message: {
                employment_id: parseInt(employmentId),
                schedule_days: selectedDatesForAutoSchedule,
                store_id: parseInt(storeId)
            }
        }

        AxiosWithAuth.post('/queue/push_message', postData)
            .then(res => {
                if (res.status === 200) {
                    console.log('Push Message Success')
                }
            })
            .catch(error => {
                console.error('Error occurred:', error)
            })
    }

    const handleSaveCustomerPreds = async (date, val) => {
        let monthlyTotal = 0
        let newCustomerPreds = { ...customerPreds }
        if (Object.keys(customerPreds.ids).includes(date)) {
            let predId = customerPreds.ids[date]
            const res = await AxiosWithAuth.put(`/customer_predictions/${predId}`, {
                prediction: parseInt(val)
            })
            newCustomerPreds = { ...newCustomerPreds, [res.data.date]: parseInt(res.data.prediction) }
            setCustomerPreds(newCustomerPreds)
        } else {
            const res = await AxiosWithAuth.post('/customer_predictions', {
                store_id: storeId,
                date: date,
                prediction: parseInt(val)
            })
            newCustomerPreds = {
                ...newCustomerPreds,
                [res.data.date]: parseInt(res.data.prediction),
                ids: { ...newCustomerPreds.ids, [res.data.date]: res.data.id }
            }
            setCustomerPreds(newCustomerPreds)
        }
        Object.keys(newCustomerPreds).forEach(key => {
            if (key !== 'ids') {
                monthlyTotal += parseInt(newCustomerPreds[key])
            }
        })
        setMonthlyCustomerPreds(monthlyTotal)
    }

    const handleExportExcelStats = () => {
        const header = [
            '日付',
            '店舗コード',
            '店名',
            'シフト合計時間',
            '不足シフト合計時間',
            '不足人数',
            'AI予測',
            '店長予測'
        ]
        const segCode = store.seg_code
        const storeName = store.name

        // Prepare data rows
        const filteredDates = dates.filter( d =>
                d !== 'workday' &&
                d !== 'holiday' &&
                d !== 'totalHours' &&
                d !== 'totalPay'
        )
        console.log(filteredDates)
        const data = filteredDates.map(date => {
            const totalShiftHours = monthlyShift.shiftHourPerDay[date] || 0;
            const totalFusokuHours = monthlyShift.fusokuHours[date] || 0;
            const totalFusoku = fusokuDays.includes(date) ? 1 : 0;
            const prediction = salePreds[date] || 0;
            const customerPrediction = customerPreds[date] || 0;
            
            return [
                date,
                segCode,
                storeName,
                totalShiftHours,
                totalFusokuHours,
                totalFusoku,
                prediction,
                customerPrediction
            ];
        });
        
        const dataToExport = [header, ...data];
        
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(dataToExport);
        XLSX.utils.book_append_sheet(wb, ws, 'Stats');
        XLSX.writeFile(wb, `${year}年${month}月分＿${storeName}＿確定シフト統計.xlsx`);
    }

    const handleExportExcel = () => {
        const header = [
            '部署コード',
            '店名',
            '社員コード',
            '氏名',
            '開閉担当有無',
            'ランク',
            '役割',
            '日付',
            '開始時間',
            '終了時間'
        ]
        const segCode = store.seg_code
        const storeName = store.name
        const data = []
        const rowBase = {}
        employments.forEach(emp => {
            rowBase[emp.id] = [
                segCode,
                storeName,
                emp.user.emp_cd,
                `${emp.user.name_last} ${emp.user.name_first}`,
                emp.open_close_duty,
                emp.rank,
                emp.role
            ]
        })

        Object.entries(monthlyShift.shifts).forEach(shifts => {
            const employmentId = shifts[0]
            const employeeMonthlyShifts = shifts[1]
            Object.entries(employeeMonthlyShifts).forEach(shift => {
                let row = [...rowBase[employmentId]]
                const date = shift[0]
                const shiftStart = shift[1].shift_time[0]
                const shiftEnd = shift[1].shift_time[1]
                row.push(date)
                row.push(shiftStart)
                row.push(shiftEnd)
                data.push(row)
            })
        })
        data.sort((a, b) => {
            const dateA = moment(a[7])
            const dateB = moment(b[7])
            if (dateA.isBefore(dateB)) {
                return -1
            } else if (dateA.isAfter(dateB)) {
                return 1
            } else {
                return 0
            }
        })

        const wb = XLSX.utils.book_new()
        const dataToExport = [[...header], ...data]
        const ws = XLSX.utils.aoa_to_sheet(dataToExport)
        XLSX.utils.book_append_sheet(wb, ws)
        XLSX.writeFile(wb, `${year}年${month}月分＿${storeName}＿確定シフト.xlsx`)
    }

    useEffect(() => {
        const dateStr = calendarYearMonth + '-01'
        fetchMonthlyShifts(dateStr, employmentId)
        if (employmentId && dateStr) {
            AxiosWithAuth.get('/calendars/dailyShifts', {
                params: { employment_id: employmentId, date: dateStr }
            }).then(res => {
                let employments = res.data.employments.sort((a, b) => a.order - b.order)
                for (let i = 1; i <= 5; i++) {
                    employments.push({
                        id: 'fusoku' + i,
                        emp_cd: i,
                        user: {
                            name_first: `${i}`,
                            name_last: '不足シフト'
                        }
                    })
                }
                setEmployments(employments)
                if (Object.keys(res.data).includes('predicted_sales')) {
                    setCloserDays(res.data.days_with_closers)
                    setStore(res.data.store)
                    setStoreId(res.data.store.id)
                }
            })
        }
        const days = Array.from(Array(day).keys()).map(day => {
            return moment(calendarYearMonth + '-' + (day + 1), 'YYYY-MM-DD').format('YYYY-MM-DD')
        })
        setDates([...days, 'workday', 'holiday', 'totalHours', 'totalPay'])
    }, [calendarYearMonth, employmentId])

    useEffect(async () => {
        if (employmentId && storeId !== '' && (isManagerFor[employmentId] || userInfo?.view_only)) {
            let predsTotal = {}
            let predsCount = 0
            let staffRequirements = await AxiosWithAuth.get(`/stores/${storeId}/staff_requirements`)
            staffRequirements.data.map(req => {
                if (moment(req.day).format('YYYY-MM') == calendarYearMonth) {
                    if (predsTotal[req.day]) {
                        predsTotal[req.day] += req.pred_sales
                    } else {
                        predsTotal[req.day] = req.pred_sales
                    }
                }
            })
            Object.entries(predsTotal).map(total => {
                predsTotal[total[0]] = Math.round(total[1])
                predsCount += total[1]
            })
            setSalePreds(predsTotal)
            setMonthlySalesPreds(Math.round(predsCount))

            let customerPredsTotal = { ...predsTotal }
            let ids = {}
            let customerPredsCount = 0
            let customerPredictions = await AxiosWithAuth.get('/customer_predictions', {
                params: { store_id: storeId, date: calendarYearMonth + '-01' }
            })
            customerPredictions.data.map(total => {
                ids[total['date']] = total['id']
                if (total['prediction'] === null) return
                customerPredsTotal[total['date']] = total['prediction']
            })
            Object.values(customerPredsTotal).forEach(key => {
                customerPredsCount += key
            })
            setCustomerPreds({ ...customerPredsTotal, ids })
            setMonthlyCustomerPreds(customerPredsCount)
        }
    }, [employmentId, isManagerFor, storeId])

    useEffect(() => {
        if (history?.location?.state?.resubmit) {
            setResubmit(true)
        }
        const pathname = history?.location?.pathname
        if (pathname.includes('check') || pathname.includes('viewOnly')) {
            setCheckShift(true)
        }
    }, [history])

    const timeScale = () => {
        const daysInMonth = moment(calendarYearMonth, 'YYYY-MM').daysInMonth()
        return Array.from(Array(daysInMonth).keys()).map(day => {
            const dow = moment(calendarYearMonth + '-' + (day + 1), 'YYYY-MM-DD')
                .format('dddd')
                .toLowerCase()
            let d = day + 1
            if (d < 10) {
                d = '0' + d
            }
            const date = moment(calendarYearMonth, 'YYYY-MM').format('YYYY-MM') + '-' + d
            // total shift time per day
            const totalShiftTime = () => {
                let timeToReturn = 0
                if (activeTab === 'actual' && monthlyShift.actualWorkHoursPerDay[date]) {
                    Object.entries(monthlyShift.actualWorkHoursPerDay).map(shifts => {
                        if (shifts[0] === date) {
                            timeToReturn = shifts[1]
                        }
                    })
                } else {
                    Object.entries(monthlyShift.shiftHourPerDay).map(shifts => {
                        if (shifts[0] === date) {
                            timeToReturn = shifts[1]
                        }
                    })
                }

                return hasAnyPermissions || isReleased(date) ? timeToReturn.toFixed(2) + 'h' : '0h'
            }
            return (
                <TableCell
                    key={day}
                    style={{
                        ...desktopHeaderRowStyle,
                        backgroundColor: computeBackgroundColor(date, monthlyShift, hasAnyPermissions)
                    }}
                >
                    <Typography
                        sx={{ height: '2rem', borderBottom: '1px solid lightgray', boxSizing: 'border-box' }}
                        className="center-text"
                    >
                        <Link
                            href={
                                checkShift
                                    ? `/checkshiftday/${employmentId}/${date}`
                                    : `/createshiftday/${employmentId}/${date}`
                            }
                            color="inherit"
                        >
                            {day + 1 + ' (' + WEEKDAY_MAPPINGS[dow] + ')'}
                        </Link>
                        {hasAnyPermissions && !monthlyShift?.confirmedShiftDays?.includes(date) && (
                            <Tooltip title="まだシフトが作成されていません">
                                <ReportProblemOutlinedIcon sx={{ fontSize: 20, color: 'red' }} />
                            </Tooltip>
                        )}
                    </Typography>
                    {(isManager || userInfo?.view_only) && (
                        <>
                            <Typography
                                sx={{ height: '2rem', borderBottom: '1px solid lightgray', boxSizing: 'border-box' }}
                                className="center-text"
                            >
                                {fusokuDays.includes(date) ? 'あり' : 'なし'}
                            </Typography>
                            <Typography
                                sx={{
                                    height: '2rem',
                                    borderBottom: '1px solid lightgray',
                                    boxSizing: 'border-box'
                                }}
                                className="center-text"
                            >
                                {salePreds[date] ? salePreds[date].toLocaleString('ja-JP') : 0}
                            </Typography>
                            <div
                                style={{
                                    height: '2rem',
                                    borderBottom: '1px solid lightgrey',
                                    boxSizing: 'border-box'
                                }}
                            >
                                <TextField
                                    id={date}
                                    variant="outlined"
                                    sx={{ width: '90%', height: '90%', marginTop: '2.5%' }}
                                    InputProps={{ sx: { height: 25 } }}
                                    maxRows={Infinity}
                                    inputProps={{ tabIndex: day + 1, style: { textAlign: 'center' } }}
                                    value={customerPreds[date]?.toLocaleString('ja-JP')}
                                    onChange={e => {
                                        const re = /^[0-9\b]+$/
                                        if (re.test(e.target.value)) {
                                            setCustomerPreds({ ...customerPreds, [date]: e.target.value })
                                        } else {
                                            setCustomerPreds({ ...customerPreds, [date]: '' })
                                        }
                                    }}
                                    onFocus={e => {
                                        e.target.select()
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter' || e.key === 'Tab') {
                                            handleSaveCustomerPreds(
                                                date,
                                                e.target.value === '' ? 0 : e.target.value.replaceAll(',', '')
                                            )
                                            e.target.blur()
                                        }
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {hasAnyPermissions ? (
                        <Typography
                            sx={{
                                height: '2rem',
                                boxSizing: 'border-box',
                                borderBottom: '1px solid lightgray'
                            }}
                            className="center-text"
                        >
                            {closerDays?.find(e => e === date) ? (
                                <CheckCircleOutlined color="success" />
                            ) : (
                                <CheckCircleOutlined color="disabled" />
                            )}
                        </Typography>
                    ) : null}
                    {(isManager || canEditShiftFor[employmentId]) && (
                        <div
                            style={{
                                height: '2rem',
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: '1px solid lightgray'
                            }}
                        >
                            <Typography sx={{ width: '33%' }}></Typography>
                            <Checkbox
                                key={date}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setSelectedDates([...selectedDates, date])
                                    } else {
                                        setSelectedDates(selectedDates.filter(d => d !== date))
                                    }
                                }}
                                checked={selectedDates.includes(date)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                sx={{ width: '33%' }}
                            />
                            <Typography sx={{ width: '33%', color: 'gray' }}>
                                {monthlyShift?.releasedShiftDates.includes(date) ? '済' : ''}
                            </Typography>
                        </div>
                    )}
                    {isTestStore ? (
                        (isManager || canEditShiftFor[employmentId]) && (
                            <>
                                <div
                                    style={{
                                        height: '2rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderBottom: '1px solid lightgray'
                                    }}
                                >
                                    <Typography sx={{ width: '33%' }}></Typography>
                                    <Checkbox
                                        key={date}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                setSelectedDatesForAutoSchedule([...selectedDatesForAutoSchedule, date]);
                                            } else {
                                                setSelectedDatesForAutoSchedule(
                                                    selectedDatesForAutoSchedule.filter(d => d !== date)
                                                );
                                            }
                                        }}
                                        checked={selectedDatesForAutoSchedule.includes(date)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        sx={{ width: '33%' }}
                                    />
                                </div>
                            </>
                        )
                    ) : null}
                    <div
                        style={{
                            height: '2rem',
                            boxSizing: 'border-box',
                            backgroundColor:
                                activeTab === 'actual' && monthlyShift.actualWorkHoursPerDay[date] !== undefined
                                    ? '#E6F4FF'
                                    : computeBackgroundColor(date, monthlyShift, hasAnyPermissions)
                        }}
                        className="center-text"
                        key={date}
                        id={date}
                    >
                        <Typography>{totalShiftTime()}</Typography>
                    </div>
                </TableCell>
            )
        })
    }

    const totalCountColumn = () => {
        let numOfRows = isManager || userInfo?.view_only ? 8 : 2
        numOfRows = isTestStore && hasAnyPermissions ? numOfRows + 6 : hasAnyPermissions ? numOfRows + 4 : numOfRows
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                <div style={{ display: 'flex', width: '100%', height: `${numOfRows}rem` }}>
                    <Typography
                        className="center-text"
                        sx={{
                            width: '25%',
                            height: '100%',
                            textAlign: 'center',
                            boxSizing: 'border-box',
                            borderLeft: '1px solid lightgray',
                            borderBottom: '1px solid lightgray'
                        }}
                    >
                        出勤日数
                    </Typography>
                    <Typography
                        className="center-text"
                        sx={{
                            width: '25%',
                            height: '100%',
                            textAlign: 'center',
                            boxSizing: 'border-box',
                            borderLeft: '1px solid lightgray',
                            borderBottom: '1px solid lightgray'
                        }}
                    >
                        公休日数
                    </Typography>
                    <Typography
                        className="center-text"
                        sx={{
                            width: '25%',
                            height: '100%',
                            textAlign: 'center',
                            boxSizing: 'border-box',
                            borderLeft: '1px solid lightgray',
                            borderBottom: '1px solid lightgray'
                        }}
                    >
                        個人月間時間
                    </Typography>
                    <Typography
                        className="center-text"
                        sx={{
                            width: '25%',
                            height: '100%',
                            textAlign: 'center',
                            boxSizing: 'border-box',
                            borderLeft: '1px solid lightgray',
                            borderBottom: '1px solid lightgray'
                        }}
                    >
                        個人月間金額
                    </Typography>
                </div>
                <div style={{ width: '100%', height: '2rem', display: 'flex' }}>
                    <Typography
                        className="center-text name-box"
                        sx={{ width: '50%', borderLeft: '1px solid lightgray' }}
                    >
                        月間総時間計
                    </Typography>
                    <Typography
                        className="center-text name-box"
                        sx={{ width: '50%', borderLeft: '1px solid lightgray' }}
                    >
                        {monthlyTotalHours()}
                    </Typography>
                </div>
            </div>
        )
    }
    const formatCurrentDate = () => {
        return moment(calendarYearMonth, 'YYYY-MM').format('YYYY年M月')
    }

    if (submitted) {
        return (
            <div
                style={{
                    height: 'calc(100vh - 56px)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '0 1rem'
                }}
            >
                <SubmitSvg />
                <Typography variant="header1" sx={{ fontSize: '20px' }}>
                    シフトを送信しました。
                </Typography>
                <Button
                    onClick={() => history.push(PATHS.home)}
                    variant="contained"
                    color="paloBlue"
                    sx={{ mt: '2rem', mb: '2rem', width: isMobile() ? '100%' : '20%' }}
                >
                    ホームに戻る
                </Button>
            </div>
        )
    }
    
    // total shift time per month
    const monthlyTotalHours = () => {
        let workHours = 0
        if (activeTab === 'actual') {
            Object.entries(monthlyShift.actualWorkHoursPerDay).map(shifts => {
                workHours += shifts[1]
            })
            workHours = workHours.toFixed(2)
        } else {
            hasAnyPermissions
                ? Object.entries(monthlyShift.confirmedEmployeeMonthlyShiftHours).map(hour => {
                      workHours += hour[1]
                  })
                : Object.entries(monthlyShift.releasedEmployeeMonthlyShiftHours).map(hour => {
                      workHours += hour[1]
                  })
        }
        return workHours + 'h'
    }

    if (Object.entries(monthlyShift).length > 0) {
        return (
            <PageWithTitleLayout title={checkShift ? '月間シフトを確認する' : 'シフトを作成する'}>
                <Snackbar
                    open={snackbarMessage.length}
                    autoHideDuration={2000}
                    onClose={() => setSnackbarMessage('')}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={() => setSnackbarMessage('')}>{snackbarMessage}</Alert>
                </Snackbar>
                {errorMessage && (
                    <Snackbar
                        open={errorMessage.length}
                        onClose={() => setErrorMessage('')}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="error" onClose={() => setErrorMessage('')}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                )}
                <div style={{ margin: '0% 8% 2% 8%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ margin: '1% 20%' }}>
                        {userInfo?.view_only ? (
                            <TextField
                                size="small"
                                sx={{ border: 'none', mx: 'auto', width: '30%', display: 'block' }}
                                fullWidth
                                disabled
                                value={
                                    JSON.parse(localStorage.selectedStoreViewOnly || '')?.seg_code +
                                    ' - ' +
                                    JSON.parse(localStorage.selectedStoreViewOnly || '')?.name
                                }
                            />
                        ) : (
                            <SelectedStore disabled />
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            margin: '1rem 2.5%'
                        }}
                    >
                        <Typography variant="h5" sx={{ marginRight: 'auto' }}>
                            {formatCurrentDate()}
                        </Typography>
                        {activeTab === 'actual' && (
                            <div className="legend-key">
                                <CropSquare fontSize="8px" style={{ color: '#E6F4FF', backgroundColor: '#E6F4FF' }} />
                                <Typography sx={{ mx: 1 }}>実績</Typography>
                            </div>
                        )}
                        {hasAnyPermissions ? (
                            <ToggleButtonGroup
                                value={activeTab}
                                exclusive
                                onChange={(e, v) => setActiveTab(v)}
                                color="paloBlue"
                            >
                                <ToggleButton value="editing">希望シフト</ToggleButton>
                                <ToggleButton value="confirmed">確定シフト</ToggleButton>
                                <ToggleButton value="actual">実績シフト</ToggleButton>
                            </ToggleButtonGroup>
                        ) : null}
                        {hasAnyPermissions && (
                            <Button
                                variant="text"
                                color="paloBlue"
                                sx={{ marginLeft: '1rem' }}
                                onClick={() => setExportShiftsDrawerOpen(true)}
                                startIcon={<PictureAsPdf />}
                            >
                                日別スケジュール
                            </Button>
                        )}
                        {hasAnyPermissions && (
                            <Button
                                variant="text"
                                color="paloBlue"
                                sx={{ marginLeft: '1rem' }}
                                startIcon={<FileDownloadOutlined />}
                                onClick={handleExportExcelStats}
                            >
                                日別統計レポート
                            </Button>
                        )}
                        {hasAnyPermissions && (
                            <Button
                                variant="text"
                                color="paloBlue"
                                sx={{ marginLeft: '1rem' }}
                                startIcon={<FileDownloadOutlined />}
                                onClick={handleExportExcel}
                            >
                                確定シフトレポート
                            </Button>
                        )}
                        {(isManager || canEditShiftFor[employmentId]) && isTestStore ? (
                            <Button
                                variant="text"
                                color="paloBlue"
                                sx={{ marginLeft: '1rem' }}
                                startIcon={<SupportAgentIcon />}
                                onClick={handleAutoSchedule}
                            >
                                AIスケジュール (テスト中)
                            </Button>
                        ) : null}
                        {exportShiftsDrawerOpen && (
                            <Drawer
                                anchor="right"
                                open={exportShiftsDrawerOpen}
                                onClose={() => setExportShiftsDrawerOpen(false)}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '20vw',
                                        margin: '1rem'
                                    }}
                                >
                                    <Typography>シフト表の出力</Typography>
                                    <Close onClick={() => setExportShiftsDrawerOpen(false)} />
                                </div>
                                <Divider />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '20vw',
                                        margin: '1rem'
                                    }}
                                >
                                    <Typography>シフト表をPDF出力したい日にちを選択してください。</Typography>
                                    {calendarYearMonth && (
                                        <>
                                            <div>
                                                <Checkbox
                                                    onChange={
                                                        // select all dates bellow
                                                        e => {
                                                            e.target.checked
                                                                ? setSelectedDatesForPdfExport([
                                                                      ...selectedDatesForPdfExport,
                                                                      ...dates.filter(
                                                                          d =>
                                                                              d !== 'workday' &&
                                                                              d !== 'holiday' &&
                                                                              d !== 'totalHours' &&
                                                                              d !== 'totalPay' &&
                                                                              !selectedDatesForPdfExport.includes(d)
                                                                      )
                                                                  ])
                                                                : setSelectedDatesForPdfExport([])
                                                        }
                                                    }
                                                />
                                                全て
                                            </div>
                                            {dates.map((date, idx) => {
                                                if (
                                                    date === 'workday' ||
                                                    date === 'holiday' ||
                                                    date === 'totalHours' ||
                                                    date === 'totalPay'
                                                ) {
                                                    return null
                                                }
                                                const dow = moment(date, 'YYYY-MM-DD').format('dddd').toLowerCase()
                                                return (
                                                    <div key={idx}>
                                                        <Checkbox
                                                            onChange={e =>
                                                                e.target.checked
                                                                    ? setSelectedDatesForPdfExport([
                                                                          ...selectedDatesForPdfExport,
                                                                          date
                                                                      ])
                                                                    : setSelectedDatesForPdfExport(
                                                                          selectedDatesForPdfExport.filter(
                                                                              d => d !== date
                                                                          )
                                                                      )
                                                            }
                                                            checked={selectedDatesForPdfExport.includes(date)}
                                                        />
                                                        {moment(date, 'YYYY-MM-DD').format('D')}
                                                        {' (' + WEEKDAY_MAPPINGS[dow] + ')'}
                                                    </div>
                                                )
                                            })}
                                            <PdfBulkExportButton
                                                shiftDates={selectedDatesForPdfExport}
                                                setSelectedDates={setSelectedDatesForPdfExport}
                                                setDrawerOpen={setExportShiftsDrawerOpen}
                                                buttonText="出力する"
                                            />
                                        </>
                                    )}
                                </div>
                            </Drawer>
                        )}
                    </div>
                    <TableContainer
                        sx={{
                            border: '1px solid lightgray',
                            margin: '0 auto',
                            maxHeight: 700,
                            maxWidth: '95%'
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            ...desktopHeaderRowStyle,
                                            left: 0,
                                            zIndex: 3
                                        }}
                                    >
                                        <div style={{ display: 'flex', width: '40rem' }}>
                                            <div style={{ width: '30%' }}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="center-text"
                                                    sx={{
                                                        height: '2rem',
                                                        minWidth: '4rem',
                                                        textAlign: 'center',
                                                        borderBottom: '1px solid lightgray',
                                                        boxSizing: 'border-box'
                                                    }}
                                                >
                                                    日付(曜日)
                                                </Typography>
                                                {(isManager || userInfo?.view_only) && (
                                                    <>
                                                        <Typography
                                                            className="center-text"
                                                            style={{
                                                                height: '2rem',
                                                                borderBottom: '1px solid lightgray',
                                                                boxSizing: 'border-box'
                                                            }}
                                                        >
                                                            人員不足あり/なし
                                                        </Typography>
                                                        <Typography
                                                            className="center-text"
                                                            style={{
                                                                height: '2rem',
                                                                borderBottom: '1px solid lightgray',
                                                                boxSizing: 'border-box'
                                                            }}
                                                        >
                                                            AI予測売上 :{' '}
                                                            {monthlySalesPreds.toLocaleString('ja-JP') + '円'}
                                                        </Typography>
                                                        <Typography
                                                            className="center-text"
                                                            sx={{
                                                                height: '2rem',
                                                                textAlign: 'center',
                                                                boxSizing: 'border-box',
                                                                borderBottom: '1px solid lightgray'
                                                            }}
                                                        >
                                                            予定 : {monthlyCustomerPreds.toLocaleString('ja-JP') + '円'}
                                                        </Typography>
                                                    </>
                                                )}
                                                {hasAnyPermissions ? (
                                                    <Typography
                                                        className="center-text"
                                                        sx={{
                                                            height: '2rem',
                                                            textAlign: 'center',
                                                            boxSizing: 'border-box',
                                                            borderBottom: '1px solid lightgray'
                                                        }}
                                                    >
                                                        開閉担当有無
                                                    </Typography>
                                                ) : null}
                                                {(isManager || canEditShiftFor[employmentId]) && (
                                                    <div
                                                        className="center-text"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-evenly',
                                                            alignItems: 'center',
                                                            height: '2rem',
                                                            borderBottom: '1px solid lightgray'
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={e => {
                                                                        if (e.target.checked) {
                                                                            const day = moment(
                                                                                calendarYearMonth,
                                                                                'YYYY-MM'
                                                                            ).daysInMonth()

                                                                            const days = Array.from(
                                                                                Array(day).keys()
                                                                            ).map(day => {
                                                                                return moment(
                                                                                    calendarYearMonth + '-' + (day + 1),
                                                                                    'YYYY-MM-DD'
                                                                                ).format('YYYY-MM-DD')
                                                                            })
                                                                            setSelectedDates(days)
                                                                        } else {
                                                                            setSelectedDates([])
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label="シフト送信日選択"
                                                        />
                                                    </div>
                                                )}
                                                {(isManager || canEditShiftFor[employmentId]) && isTestStore ? (
                                                    <div
                                                        className="center-text"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-evenly',
                                                            alignItems: 'center',
                                                            height: '2rem',
                                                            borderBottom: '1px solid lightgray'
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={e => {
                                                                        if (e.target.checked) {
                                                                            const day = moment(
                                                                                calendarYearMonth,
                                                                                'YYYY-MM'
                                                                            ).daysInMonth()

                                                                            const days = Array.from(
                                                                                Array(day).keys()
                                                                            ).map(day => {
                                                                                return moment(
                                                                                    calendarYearMonth + '-' + (day + 1),
                                                                                    'YYYY-MM-DD'
                                                                                ).format('YYYY-MM-DD')
                                                                            })
                                                                            setSelectedDatesForAutoSchedule(days)
                                                                        } else {
                                                                            setSelectedDatesForAutoSchedule([])
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label="AIシフト作成"
                                                        />
                                                    </div>
                                                ) : null}
                                                <div
                                                    className="center-text"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-evenly',
                                                        alignItems: 'center',
                                                        height: '2rem'
                                                    }}
                                                >
                                                    <Typography variant="subtitle2" className="center-text name-box">
                                                        就業時間計(１日計)
                                                    </Typography>
                                                </div>
                                            </div>
                                            {totalCountColumn()}
                                        </div>
                                    </TableCell>
                                    {timeScale()}
                                </TableRow>
                            </TableHead>
                            {activeTab === 'editing' ? (
                                <EditMonthlyShiftBody
                                    submitted={submitted}
                                    setSubmitted={setSubmitted}
                                    employments={employments}
                                    monthlyShift={monthlyShift}
                                    dates={dates}
                                    setMonthlyShift={setMonthlyShift}
                                />
                            ) : activeTab === 'confirmed' ? (
                                <CheckMonthlyShiftBody
                                    employments={employments}
                                    monthlyShift={monthlyShift}
                                    dates={dates}
                                />
                            ) : (
                                <ActualMonthlyShiftBody
                                    employments={employments}
                                    monthlyShift={monthlyShift}
                                    dates={dates}
                                />
                            )}
                        </Table>
                    </TableContainer>
                    {!userInfo?.view_only && (isManager || canEditShiftFor[employmentId]) && (
                        <ButtonWithConfirmPrompt
                            component={
                                <Button
                                    variant="contained"
                                    color="paloBlue"
                                    className="main-button"
                                    sx={{ alignSelf: 'center', mt: '3rem', mb: '2rem' }}
                                >
                                    {resubmit ? 'シフトを再送信する' : 'シフトを送信する'}
                                </Button>
                            }
                            confirmText="送信する"
                            title={resubmit ? 'シフトの再送信' : 'シフトの送信'}
                            content={
                                resubmit ? 'シフトを再送信します。よろしいですか？' : 'シフトを送信します。よろしいですか？'
                            }
                            handleConfirm={handleFinalize}
                            loading={loading}
                        />
                    )}
                </div>
            </PageWithTitleLayout>
        )
    } else {
        return <CircularProgress sx={{ mx: '50%', mt: '10%' }} />
    }
}

export default MonthlyShiftHeader
