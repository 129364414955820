import React, { useState, useEffect } from 'react'
import { Stack, Button, Alert, IconButton, DialogTitle, Typography } from '@mui/material'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { parseTimeBlock } from '../../Utilities/shiftUtils'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { AddOutlined, ContentCopy, ContentPaste, DeleteOutlined, CloseOutlined } from '@mui/icons-material'
import { isMobile } from '../../Utilities/isMobile'
import { timezoneOffsets } from '../../Utilities/shiftUtils'
import { InputTime } from '../../Utilities/shiftUtils'

const SubmitShift = ({ shiftDate, setSubmitShiftDialog, setAllShifts, store, submitFor, setSnackbar, suggestedStartEnd }) => {
    const { employmentId } = useParams()
    const [year, month, day] = shiftDate.split('-')
    const [copied, setCopied] = useState(false)
    const date = new Date(year, month - 1, day)
    const [errors, setErrors] = useState({ startTime: false, endTime: false })
    const [overlapError, setOverlapError] = useState(false)
    const [shifts, setShifts] = useState([])

    const allowCreate = shifts.length < 5

    moment.updateLocale('en', {
        weekdaysShort: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
    })

    const handleChange = (index, field, value) => {
        const newShifts = [...shifts]
        newShifts[index][field] = value
        newShifts[index].edited = true
        const newErrors = { ...errors }
        if (value.split(':')[1] % 15 > 0) {
            newErrors[field] = true
        } else {
            newErrors[field] = false
        }
        setErrors(newErrors)
        setShifts(newShifts)
        if (overlapError) {
            setOverlapError(false)
        }
    }

    const handleDelete = index => {
        if (!shifts[index].new) {
            AxiosWithAuth.delete('/shifts/' + shifts[index].id)
        }
        setShifts(shifts.filter((e, i) => i != index)) //removes the element at that index
        if (overlapError) {
            setOverlapError(false)
        }
    }

    const isOverlapping = () => {
        const newShifts = [...shifts]
        let isOverlapping = false
        newShifts.forEach((shift, index) => {
            const [startHourA] = shift.startTime.split(':')
            const [endHourA] = shift.endTime.split(':')
            newShifts.forEach((shift2, index2) => {
                if (index !== index2) {
                    const [startHourB] = shift2.startTime.split(':')
                    const [endHourB] = shift2.endTime.split(':')
                    if (
                        (startHourA < startHourB && endHourA > startHourB) ||
                        (startHourA < endHourB && endHourA > endHourB) ||
                        (startHourA === startHourB && endHourA === endHourB)
                    ) {
                        isOverlapping = true
                        newShifts[index].overlapping = true
                        newShifts[index2].overlapping = true
                    }
                }
            })
        })
        setShifts(newShifts)
        return isOverlapping
    }

    const handleSubmit = () => {
        const responses = []
        const shiftsWithRequests = []

        if (isOverlapping()) {
            setOverlapError(true)
            return
        } else {
            setOverlapError(false)
        }

        const newShifts = [...shifts]
        newShifts.forEach((shift, index) => {
            newShifts.forEach((shift2, index2) => {
                if (index !== index2 && shift.endTime === shift2.startTime) {
                    shift.endTime = shift2.endTime
                    shift.edited = true
                    newShifts.splice(index2, 1)
                }
            })
        })

        newShifts.forEach(s => {
            if (s.edited || s.new) {
                shiftsWithRequests.push(s)
            }
        })

        shiftsWithRequests.forEach(async shift => {
            const [startHour, startMinute] = shift.startTime.split(':')
            const [endHour, endMinute] = shift.endTime.split(':')

            const startTimeDate = new Date(year, month - 1, day, startHour, startMinute)
            const startTokyoHourOffset = startTimeDate.getTimezoneOffset() / 60 + timezoneOffsets[store.time_zone]

            const endTimeDate = new Date(year, month - 1, day, endHour, endMinute)
            const endTokyoHourOffset = endTimeDate.getTimezoneOffset() / 60 + timezoneOffsets[store.time_zone]

            if (startTimeDate.getHours() > endTimeDate.getHours()) {
                // night shift
                endTimeDate.setDate(endTimeDate.getDate() + 1)
            }

            // timezone adjustment
            startTimeDate.setHours(startTimeDate.getHours() - startTokyoHourOffset)
            endTimeDate.setHours(endTimeDate.getHours() - endTokyoHourOffset)

            // local time is shown in the TimePicker, but server gets sent UTC time
            if (shift.new) {
                const res = await AxiosWithAuth.post('/shifts', {
                    employment_id: submitFor,
                    start_time: startTimeDate,
                    end_time: endTimeDate,
                    status: suggestedStartEnd ? 'submitted' : 'available',
                    submitted_by: employmentId,
                    ops_date: shiftDate
                }).catch(err => {
                    responses.push(err.response)
                    if (err.response.status === 422 && err.response.data?.time_block) {
                        setOverlapError(true)
                    }
                    if (err.response.data.base) {
                        setSnackbar({ message: err.response.data.base[0], severity: 'error' })
                    }
                })
                if (res) {
                    responses.push(res)
                }
            } else if (shift.edited) {
                const res = await AxiosWithAuth.put('/shifts/' + shift.id, {
                    employment_id: submitFor,
                    start_time: startTimeDate,
                    end_time: endTimeDate,
                    status: 'available',
                    submitted_by: employmentId
                }).catch(err => {
                    responses.push(err.response)
                    if (err.response.status === 422 && err.response.data?.time_block) {
                        setOverlapError(true)
                    }
                    if (err.response.data.base) {
                        setSnackbar({ message: err.response.data.base[0], severity: 'error' })
                    }
                })
                if (res) {
                    responses.push(res)
                }
            }
            const ids = responses.map(res => res.data?.id)

            if (responses.length === shiftsWithRequests.length) {
                AxiosWithAuth.get('/shifts', { params: { employment_id: submitFor } }).then(res => {
                    const filteredIds = ids.filter(id => !res.data.shifts.some(shift => shift.id === id))
                    if (res.status === 200 && filteredIds.length === 0) {
                        setAllShifts(submitFor)
                        setSubmitShiftDialog(false)
                        setSnackbar({ message: '希望シフトに保存されました。', severity: 'success' })
                    }
                })
            }
        })
    }

    const copy = () => {
        localStorage.setItem('copiedShifts', JSON.stringify(shifts))
        setCopied(true)
    }

    const paste = () => {
        const copiedShifts = JSON.parse(localStorage.getItem('copiedShifts'))
        if (copiedShifts) {
            const newShifts = [...shifts]
            copiedShifts.forEach(shift => {
                newShifts.push({
                    id: shift.id,
                    startTime: shift.startTime,
                    endTime: shift.endTime,
                    new: true,
                    edited: false
                })
            })
            setShifts(newShifts)
        }
    }

    useEffect(() => {
        const newShifts = [...shifts]
        shifts.forEach((shift, index) => {
            if (shift.startTime.split(':')[1] % 15 > 0) {
                // if startTime doesn't end in 0, 15, 30, 45
                newShifts[index].startError = true
            } else {
                delete newShifts[index].startError
            }
            if (shift.endTime.split(':')[1] % 15 > 0) {
                newShifts[index].endError = true
            } else {
                delete newShifts[index].endError
            }
        })
    }, [shifts])
    useEffect(() => {
        if (!submitFor) return
        AxiosWithAuth.get('/shifts/', { params: { employment_id: submitFor } }).then(res => {
            let resShifts = res.data.shifts
            resShifts.forEach(s => {
                const [start, end] = parseTimeBlock(s.time_block, timezoneOffsets[store.time_zone])
                s.startDate = start
                s.endDate = end
                s.startTime = moment(start).format('HH:mm')
                s.endTime = moment(end).format('HH:mm')
            })
            resShifts = resShifts.filter(s => {
                if (s.startDate.toDateString() !== date.toDateString()) return false
                if (s.status === 'available') {
                    return true
                }
                if (s.status === 'confirmed' && s.is_released) {
                    return true
                }
                return false
            })
            const shifts = []
            if (suggestedStartEnd) {
                shifts.push({ startTime: suggestedStartEnd[0], endTime: suggestedStartEnd[1], new: true })
            } else {
                shifts.push(...resShifts)
            }
            setShifts(shifts)
        })
    }, [submitFor])

    return (
        <div className="input-box">
            <Stack spacing={3} sx={{ mx: isMobile() ? '' : '5rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle
                        sx={{ mt: '1rem', ml: '-.5rem' }}
                        data-testid={moment(shiftDate, 'YYYY-MM-DD').format('YYYY年M月D日 ddd')}
                    >
                        {moment(shiftDate, 'YYYY-MM-DD').format('YYYY年M月D日 ddd')}
                    </DialogTitle>
                    <div style={{ marginLeft: 'auto', paddingTop: '.5rem', position: 'absolute', right: 8, top: 8 }}>
                        <IconButton onClick={copy}>
                            <ContentCopy fontSize="small" sx={{ color: copied ? 'lightblue' : '#808080' }} />
                        </IconButton>
                        <IconButton onClick={paste}>
                            <ContentPaste fontSize="small" sx={{ color: '#808080' }} />
                        </IconButton>
                        <IconButton onClick={() => setSubmitShiftDialog(false)}>
                            <CloseOutlined />
                        </IconButton>
                    </div>
                </div>
                {overlapError && <Alert severity="error">シフトが重複しています</Alert>}
                { suggestedStartEnd && <Typography variant="body1" sx={{ color: 'text.secondary', mt: '1rem' }}> 以下の不足シフトに入りますか？</Typography>}
                {shifts.map((shift, index) => (
                    <Stack spacing={1.5} sx={{ display: 'flex', flexDirection: 'column' }} key={index}>
                        <InputTime
                            startValue={shift.startTime}
                            endValue={shift.endTime}
                            startOnChange={e => handleChange(index, 'startTime', e.target.value)}
                            endOnChange={e => handleChange(index, 'endTime', e.target.value)}
                            startError={errors.startTime}
                            endError={errors.endTime}
                        />
                        {!suggestedStartEnd && <Button
                            onClick={() => handleDelete(index)}
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteOutlined />}
                        >
                            このシフトを削除する
                        </Button>}
                    </Stack>
                ))}
                { !suggestedStartEnd && <Button
                    onClick={() => setShifts([...shifts, { startTime: '09:00', endTime: '17:00', new: true }])}
                    variant="outlined"
                    color="paloBlue"
                    sx={{ mx: '1rem', mb: '2rem' }}
                    startIcon={allowCreate && <AddOutlined />}
                    disabled={!allowCreate}
                >
                    {allowCreate ? '追加する' : '分割されたシフトは1日あたり5つまで入力可能です。'}
                </Button>}
            </Stack>
            <Button
                data-testid="submit_shift_button"
                disabled={errors.startTime || errors.endTime || overlapError}
                onClick={handleSubmit}
                variant="contained"
                color="paloBlue"
                sx={{ mx: isMobile() ? '' : '5rem', mb: '2rem', mt: '1.5rem' }}
            >
                保存する
            </Button>
        </div>
    )
}

export default SubmitShift
