import React, { useState } from 'react'
import { TextField, Stack, Button } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'
import { validateKanjiHiraganaKatakana } from '../../../Utilities/fieldValidators'

const EditKanjiName = ({ current, handleReturn, propsId }) => {
    const { userId } = useParams()
    const [newValue, setNewValue] = useState({
        kanjiFirst: current.kanjiFirst || '',
        kanjiLast: current.kanjiLast || ''
    })
    const [errors, setErrors] = useState({})

    const handleKanjiLast = event => {
        const text = event.target.value
        if (!validateKanjiHiraganaKatakana(text)) {
            setErrors({ ...errors, kanjiLast: '漢字・全角ひらがな・全角カタカナのみ(スペースを除く)' })
        } else {
            //eslint-disable-next-line
            const { kanjiLast, ...rest } = errors
            setErrors(rest)
        }
        setNewValue({ ...newValue, kanjiLast: text })
    }

    const handleKanjiFirst = event => {
        const text = event.target.value
        if (!validateKanjiHiraganaKatakana(text)) {
            setErrors({ ...errors, kanjiFirst: '漢字・全角ひらがな・全角カタカナのみ(スペースを除く)' })
        } else {
            //eslint-disable-next-line
            const { kanjiFirst, ...rest } = errors
            setErrors(rest)
        }
        setNewValue({ ...newValue, kanjiFirst: text })
    }
    return (
        <PageWithTitleLayout title="氏名（漢字）の変更">
            <Stack spacing={2} sx={{ m: '1rem' }} className="shrinkable">
                <TextField
                    label="姓（漢字）"
                    placeholder={`例：${current.kanjiLast || '山田'}`}
                    helperText={errors.kanjiLast || '漢字・全角ひらがな・全角カタカナ'}
                    InputLabelProps={{ shrink: true }}
                    value={newValue.kanjiLast}
                    onChange={e => setNewValue({ ...newValue, kanjiLast: e.target.value })}
                    error={errors.kanjiLast}
                    inputProps={{ onBlur: handleKanjiLast }}
                />
                <TextField
                    label="名（漢字）"
                    placeholder={`例：${current.kanjiFirst || '太郎'}`}
                    helperText={errors.kanjiFirst || '漢字・全角ひらがな・全角カタカナ'}
                    InputLabelProps={{ shrink: true }}
                    value={newValue.kanjiFirst}
                    onChange={e => setNewValue({ ...newValue, kanjiFirst: e.target.value })}
                    error={errors.kanjiFirst}
                    inputProps={{ onBlur: handleKanjiFirst }}
                />
            </Stack>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        className="shrinkable"
                        disabled={Object.keys(errors).length > 0}
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                    >
                        変更して保存する
                    </Button>
                }
                title="氏名（漢字）の変更"
                content="氏名（漢字）を変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    AxiosWithAuth.put('/users/' + (userId || propsId), {
                        kanji_last: newValue.kanjiLast,
                        kanji_first: newValue.kanjiFirst
                    })
                        .then(res => {
                            res.status == 200 && handleReturn()
                        })
                        .catch(error => {
                            const errors = {}
                            for (const [key, value] of Object.entries(error?.response?.data)) {
                                errors[key] = value.join(' ')
                            }
                            setErrors(errors)
                        })
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditKanjiName
