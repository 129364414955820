import React, { useContext } from 'react'
import { List, ListItem, ListItemIcon } from '@mui/material'
import { LocationOnOutlined, KeyboardArrowRight } from '@mui/icons-material/'
import { PATHS } from '../../constants'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { changeSelectedStore } from '../../Utilities/authenticationService'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'

import UserSearch from '../AdminSettings/UserSearch'

const UserManagementStores = ({ history }) => {
    const { userEmployments: employments } = useContext(EmploymentsContext)

    const currentEmpId = JSON.parse(localStorage.getItem('currentUser'))?.selected_emp

    return (
        <PageWithTitleLayout title="ユーザー情報">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <UserSearch path={`${PATHS.userManagementStores}${currentEmpId}/noEmployment/`} history={history} />
            </div>
            <div className="shrinkable">
                <List sx={{ mx: '1rem' }}>
                    {employments.map(e =>
                        e.is_manager ? (
                            <ListItem
                                key={e.id}
                                onClick={() => {
                                    changeSelectedStore(e.id)
                                    history.push(PATHS.userManagementStores + e.id)
                                }}
                            >
                                <ListItemIcon>
                                    <LocationOnOutlined />
                                </ListItemIcon>
                                {e.store.name}
                                <KeyboardArrowRight sx={{ ml: 'auto' }} />
                            </ListItem>
                        ) : null
                    )}
                </List>
            </div>
        </PageWithTitleLayout>
    )
}

export default UserManagementStores
