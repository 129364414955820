import React, { useState } from 'react'
import { Layout } from '..'
import { TextField, Button, Typography, Box } from '@mui/material'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import SubmitSvg from '../SubmitShift/SubmitSvg'
import { isMobile } from '../../Utilities/isMobile'

const ForgotPassword = ({ history }) => {
    const [email, setEmail] = useState('')
    const [resetPassword, setResetPassword] = useState(false)

    if (resetPassword) {
        return (
            <Layout>
                <div
                    style={{
                        height: 'calc(100vh - 56px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '0 1rem'
                    }}
                >
                    <SubmitSvg />
                    <Typography variant="header1" sx={{ fontSize: '20px' }}>
                        パスワード再設定のリンクを <br />
                        送信しました。
                    </Typography>
                    <Button
                        onClick={() => {
                            setResetPassword(null)
                            history.push('/login')
                        }}
                        variant="contained"
                        color="paloBlue"
                        fullWidth
                        sx={{ mt: 'auto', mb: '2rem', width: isMobile() ? '95%' : '620px' }}
                    >
                        ログイン画面へ
                    </Button>
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <PageWithTitleLayout title="パスワード再設定">
                <Box className="input-box shrinkable" component="form" autoComplete="off">
                    <Typography
                        variant="subtitle2"
                        sx={{
                            height: '2rem',
                            marginBottom: '.5rem'
                        }}
                    >
                        ご利用中のメールアドレスを入力してください。パスワード再設定のURLをお送ります。
                    </Typography>
                    <TextField
                        sx={{ marginTop: '1rem' }}
                        label="メールアドレス (Email address) "
                        type="email"
                        placeholder="例：pai@gmail.com"
                        helperText="半角英数字"
                        InputLabelProps={{ shrink: true }}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="paloBlue"
                        sx={{ marginTop: '1rem', height: '42px' }}
                        disabled={!email}
                        onClick={() => {
                            AxiosWithAuth.post('/forgotPassword', { email }).then(res => {
                                res.status == 200 && setResetPassword(true)
                            })
                        }}
                    >
                        送信する
                    </Button>
                </Box>
            </PageWithTitleLayout>
        </Layout>
    )
}
export default ForgotPassword
