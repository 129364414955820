import React, { useState } from 'react'
import { TextField, Button, Autocomplete } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'

const options = [
    { label: 'ホール', value: 'hall' },
    { label: 'キッチン', value: 'kitchen' },
    { label: 'ホールとキッチン両方', value: 'both' }
]

const EditHallKitchen = ({ current, handleReturn, employments = [] }) => {
    const { userEmpId } = useParams()
    const [newValue, setNewValue] = useState(options.find(option => option.value === current))

    return (
        <PageWithTitleLayout title="勤務タイプの変更">
            <Autocomplete
                sx={{ m: '1rem' }}
                id="select-work-type"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={options}
                label="勤務タイプ"
                placeholder="選択してください"
                value={newValue}
                onChange={(event, nv) => setNewValue(nv)}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            label="勤務タイプ"
                            placeholder="選択してください"
                            InputLabelProps={{ shrink: true }}
                        />
                    )
                }}
                className="input-box shrinkable"
            />
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                        disabled={!newValue}
                        className="shrinkable"
                    >
                        変更して保存する
                    </Button>
                }
                title="勤務タイプの変更"
                content="勤務タイプを変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    if (employments.length > 0) {
                        // uses array of employment ids if given through props instead of params
                        employments.forEach(e => {
                            AxiosWithAuth.put('/employments/' + e, {
                                role: newValue.value
                            }).then(res => {
                                res.status == 200 && handleReturn()
                            })
                        })
                    } else {
                        AxiosWithAuth.put('/employments/' + userEmpId, {
                            role: newValue.value
                        }).then(res => {
                            res.status == 200 && handleReturn()
                        })
                    }
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditHallKitchen
