import React, { useState } from 'react'
import { TextField, Stack, Button, Alert } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'
import { isMobile } from '../../../Utilities/isMobile'
import { PATHS } from '../../../constants'

const EditEmail = ({ current, history, propsId }) => {
    const { userId } = useParams()
    const [newValue, setNewValue] = useState('')
    const [error, setError] = useState({ email: '' })
    const [isUpdated, setIsUpdated] = useState(false)

    if (isUpdated) {
        return (
            <PageWithTitleLayout title="確認メール送信完了">
                <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Alert sx={{ mx: '1rem' }} severity="success">
                        まだ変更は完了していません。確認メールに記載されたリンクをクリックしてメールアドレスを変更してください。
                    </Alert>
                    <Button
                        onClick={() => history.push(PATHS.home)}
                        variant="contained"
                        color="paloBlue"
                        fullWidth
                        sx={{ mt: '2rem', mb: '2rem', width: isMobile() ? '100%' : '20%' }}
                    >
                        ホームに戻る
                    </Button>
                </div>
            </PageWithTitleLayout>
        )
    }

    return (
        <PageWithTitleLayout title="メールアドレスの変更">
            <Stack spacing={2} sx={{ m: '1rem' }} className="shrinkable">
                <TextField
                    label="現在のメールアドレス"
                    InputProps={{
                        readOnly: true
                    }}
                    value={current}
                />

                <TextField
                    type="email"
                    label="新しいメールアドレス"
                    placeholder={`例：${current}`}
                    helperText={error.email || '半角英数字'}
                    InputLabelProps={{ shrink: true }}
                    value={newValue}
                    onChange={e => setNewValue(e.target.value)}
                    error={error.email ? true : false}
                />
            </Stack>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                        className="shrinkable"
                    >
                        変更して保存する
                    </Button>
                }
                title="メールアドレスの変更"
                content={'メールアドレスをこちらに変更してもよろしいですか？' + newValue}
                confirmText="変更する"
                handleConfirm={() => {
                    AxiosWithAuth.put('/users/' + (userId || propsId), {
                        email: newValue
                    })
                        .then(res => {
                            res.status == 200 && setIsUpdated(true)
                        })
                        .catch(error => {
                            const errors = {}
                            for (const [key, value] of Object.entries(error?.response?.data)) {
                                errors[key] = value.join(' ')
                            }
                            setError(errors)
                        })
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditEmail
