import React, { useState, useMemo } from 'react'
import { Button, TextField, Stack } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'

const EditSegCode = ({ current }) => {
    const { segCode } = useParams()
    const [newValue, setNewValue] = useState('')
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const sameSegCode = useMemo(() => current === newValue)

    return (
        <PageWithTitleLayout title="店舗コードの変更">
            <Stack spacing={2} className="input-box shrinkable">
                <TextField
                    label="現在の店舗コード"
                    InputProps={{
                        readOnly: true
                    }}
                    value={current}
                    sx={{ marginTop: '1rem', width: '100%' }}
                />

                <TextField
                    label="新しい店舗コード"
                    placeholder="例：店舗12345"
                    helperText={errorMessage || '半角英数字のみ入力可能です。'}
                    InputLabelProps={{ shrink: true }}
                    value={newValue}
                    onChange={e => {
                        if (!e.target.value.match(/^[a-zA-Z0-9]+$/)) {
                            setErrorMessage('半角英数字のみ入力可能です。')
                            setError(true)
                        } else {
                            setErrorMessage('')
                            setError(false)
                        }
                        setNewValue(e.target.value)
                    }}
                    error={error}
                />
            </Stack>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        disabled={sameSegCode || newValue === '' || error}
                        variant="contained"
                        color="paloBlue"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                        className="shrinkable"
                    >
                        変更して保存する
                    </Button>
                }
                title="店舗コードの変更"
                content="店舗コードを変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    AxiosWithAuth.put('/stores/' + segCode, {
                        seg_code: newValue
                    })
                        .then(res => {
                            if (res.status === 200) {
                                // Redirect to the new seg code page instead of reloading
                                window.location.href = `/managerSettings/storeManagement/edit/${newValue}`
                            }
                        })
                        .catch(() => {
                            setError(true)
                            setErrorMessage('店舗コードの更新に失敗しました。')
                        })
                }}
            />
        </PageWithTitleLayout>
    )
}

export default EditSegCode