import { BehaviorSubject } from 'rxjs'
import { FREDERICK_API_ROOT } from '../constants'
import axios from 'axios'
import * as Sentry from '@sentry/react'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))

const logout = () => {
    // remove user from local storage to log user out
    console.log('Logging out')
    localStorage.removeItem('currentUser')
    Sentry.setUser(null)
    currentUserSubject.next(null)
    AxiosWithAuth.delete('/logout')
    console.log('logged out')
}

export const signupWithToken = token => {
    // this is a sign up flow so log out the current user
    return AxiosWithAuth.get(`/users/confirmation?confirmation_token=${token}`)
}

export const login = (emp_cd, password) => {
    return AxiosWithAuth.post(
        `/login`,
        { user: { emp_cd, password } },
        { headers: { 'Content-Type': 'application/json' } }
    ).then(res => {
        if (res.status === 200) {
            const data = res.data
            localStorage.setItem('currentUser', JSON.stringify(data))
            localStorage.setItem('selected_emp', data.selected_emp)
            currentUserSubject.next(data)
            Sentry.setUser({
                email: data.email,
                emp_cd: data.employee_code,
                is_admin: data.is_admin,
                selected_emp: data.selected_emp
            })
        }
        return res
    })
}

export const changeSelectedStore = emp_id => {
    localStorage.setItem('selected_emp', emp_id)
    localStorage.setItem('submit_for', emp_id)
    AxiosWithAuth.put('/users/me', { selected_emp: emp_id })
}

export const changeSelectedEmployee = emp_id => {
    localStorage.setItem('submit_for', emp_id)
}

const configuredAxios = () => {
    const configured = axios.create({
        baseURL: FREDERICK_API_ROOT,
        withCredentials: true,
        xsrfCookieName: 'CSRF-TOKEN',
        xsrfHeaderName: 'X-CSRF-Token',
        headers: {
            Accept: 'application/json'
        }
    })
    configured.interceptors.response.use(
        res => res, // can run code on every response here in the future
        error => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (window.location.pathname.includes('/employment')) {
                if (error.response.status === 422) {
                    // token has already been validated
                    window.location.pathname = '/login'
                    logout()
                }
                if (error.response.status === 401) {
                    // don't do anything...
                }
            } else if (
                error.response.status === 401 &&
                !window.location.pathname.includes('/confirmation') &&
                window.location.pathname !== '/login' &&
                !window.location.pathname.includes('/resetPassword')
            ) {
                window.location.pathname = '/login'
                logout()
            }
            return Promise.reject(error)
        }
    )
    return configured
}

export const AxiosWithAuth = configuredAxios()

export const authenticationService = {
    login,
    logout,
    AxiosWithAuth,
    changeSelectedStore,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    }
}

export function authHeader() {
    const currentUser = authenticationService.currentUserValue
    if (currentUser && currentUser.token) {
        return { Authorization: currentUser.token }
    } else {
        return {}
    }
}
