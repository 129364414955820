import React, { useEffect, useState, useContext } from 'react'
import { Typography, List, ListItem, ListItemText } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material/'
import { PATHS } from '../../constants'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { changeSelectedStore } from '../../Utilities/authenticationService'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { isMobile } from '../../Utilities/isMobile'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import moment from 'moment'

const CheckFinalizedMonths = ({ history }) => {
    const [stores, setStores] = useState([])
    const { userEmployments: employments } = useContext(EmploymentsContext)

    useEffect(() => {
        AxiosWithAuth.get('/mystores').then(res => {
            const confirmedStores = []
            res.data.forEach(e => {
                e.released_months
                    ?.sort((a, b) => moment(a).diff(moment(b)))
                    .forEach(month => {
                        const id = employments.find(employment => employment.store.seg_code === e.seg_code).id
                        confirmedStores.push({
                            emp_id: id,
                            name: e.name,
                            month: month
                        })
                    })
            })
            setStores(confirmedStores)
        })
    }, [employments])

    return (
        <PageWithTitleLayout title="シフトを確認する">
            <div className="shrinkable">
                <Typography variant="subtitle2" margin="1rem" marginTop=".5rem" marginBottom=".5rem">
                    以下の店舗のシフトが確認できます。
                </Typography>
                <List sx={{ mx: '1rem' }}>
                    {stores.map(store => {
                        const calendarYearMonth = moment(store.month, 'YYYY-MM').format('YYYY-MM')
                        return (
                            <ListItem
                                key={store.emp_id + '' + store.month}
                                onClick={() => {
                                    changeSelectedStore(store.emp_id)
                                    isMobile()
                                        ? history.push({
                                              pathname: PATHS.checkCalendar
                                                  .replace(':employmentId', store.emp_id)
                                                  .replace(':calendarYearMonth', calendarYearMonth)
                                          })
                                        : history.push({
                                              pathname: PATHS.checkMonthlyShift
                                                  .replace(':employmentId', store.emp_id)
                                                  .replace(':calendarYearMonth', calendarYearMonth)
                                          })
                                }}
                            >
                                <ListItemText
                                    primary={store.name}
                                    secondary={moment(store.month).format('M') + '月分'}
                                />
                                <KeyboardArrowRight sx={{ ml: 'auto' }} />
                            </ListItem>
                        )
                    })}
                </List>
            </div>
        </PageWithTitleLayout>
    )
}

export default CheckFinalizedMonths
