export const mobileBaseCellStyle = {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    margin: 0,
    borderRight: '1px solid lightgray',
    minWidth: '5rem',
    height: '1rem',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '1rem'
}
export const mobileHeaderColumnStyle = {
    ...mobileBaseCellStyle,
    position: 'sticky',
    left: 0,
    minWidth: '3rem',
    background: 'white',
    zIndex: 1,
    flexDirection: 'row'
}
export const mobileHeaderRowStyle = {
    ...mobileBaseCellStyle,
    position: 'sticky',
    zIndex: 1
}

export const desktopBaseCellStyle = {
    background: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    margin: 0,
    borderRight: '1px solid lightgray',
    minWidth: '1rem',
    height: '3rem',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '1rem',
    borderBottom: '1px solid lightgray'
}
export const desktopMonthlyBaseCellStyle = {
    justifyContent: 'center',
    alignItems: 'center',
    height: '3rem',
    minWidth: '8rem',
    textAlign: 'center',
    boxSizing: 'border-box',
    borderBottom: '1px solid lightgray',
    borderRight: '1px solid lightgray'
}
export const desktopHeaderColumnStyle = {
    ...desktopBaseCellStyle,
    position: 'sticky',
    left: 0,
    minWidth: '12rem',
    background: 'white',
    zIndex: 1,
    flexDirection: 'row'
}
export const desktopHeaderRowStyle = {
    ...desktopBaseCellStyle,
    background: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 2
}
export const desktopMonthlyHeaderRowStyle = {
    ...desktopMonthlyBaseCellStyle,
    position: 'sticky',
    top: 0,
    zIndex: 2,
    background: 'white',
    borderLeft: '1px solid lightgray'
}

export const desktopMonthlyTotalColumnStyle = {
    display: 'flex',
    width: '40rem',
    height: '100%',
    alignItems: 'center'
}
