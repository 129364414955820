export const FREDERICK_API_ROOT = process.env.REACT_APP_FREDERICK_API_ROOT

export const PATHS = {
    login: '/login',
    forgotPassword: '/forgotPassword',
    resetPassword: '/resetPassword/:token',
    resendConfirmation: '/resendConfirmation',
    home: '/home',
    check: '/check',
    submit: '/submit',
    signup: '/signup',
    confirmation: '/confirmation/:token',
    // submit shift flow
    stores: '/submitshift/',
    months: '/submitshift/:employmentId',
    calendar: '/submitshift/:employmentId/:calendarYearMonth',
    recurring: '/recurringshifts/:employmentId',
    submitShift: '/submitshift/:employmentId/:calendarYearMonth/:shiftDate',
    // check shift flow
    checkStores: '/checkshift/',
    checkCalendar: '/checkshift/:employmentId/:calendarYearMonth',
    checkDay: '/checkshiftday/:employmentId/:shiftDate',
    checkMonthlyShift: '/checkmonthlyshift/:employmentId/:calendarYearMonth',
    // user settings
    userSettings: '/settings',
    // manager create shift flow
    managerStores: '/createshift/',
    managerMonths: '/createshift/:employmentId',
    submittedEmployees: '/createshift/:employmentId/:calendarYearMonth',
    createShiftCalendar: '/createshift/:employmentId/:calendarYearMonth/calendar',
    createCalendar: '/createshiftcalendar/:employmentId/:calendarYearMonth',
    createMonthlyShift: '/createmonthlyshift/:employmentId/:calendarYearMonth',
    createDay: '/createshiftday/:employmentId/:shiftDate',
    // manager settings
    managerSettings: '/managerSettings',
    userManagementStores: '/managerSettings/userManagement/',
    userManagementList: '/managerSettings/userManagement/:employmentId/',
    userManagementEdit: '/managerSettings/userManagement/:employmentId/:userEmpId/:userId',
    storeManagement: '/managerSettings/storeManagement',
    editStore: '/managerSettings/storeManagement/edit/:segCode',
    // admin settings
    adminSettings: '/adminSettings',
    adminUserManagements: '/adminSettings/userManagement',
    adminUserBulkUpload: '/adminSettings/userManagement/bulkUpload',
    adminStoreManagements: '/adminSettings/storeManagement',
    adminStoreUserList: '/adminSettings/storeManagement/:segCode',
    adminStoreBulkUpload: '/adminSettings/storeManagement/bulkUpload',
    adminHLG: '/adminSettings/hlg',
    viewOnly: {
        home: '/viewOnly/home',
        months: '/viewOnly/check/:employmentId',
        checkMonthlyShift: '/viewOnly/:employmentId/:calendarYearMonth',
        checkMonthlyShiftMobile: '/viewOnly/:employmentId/:calendarYearMonth/mobile'
    }
}

export const ROLE_MAPPINGS = {
    kitchen: 'キッチン',
    hall: 'ホール',
    both: 'ホールとキッチン'
}

export const PART_FULLTIME_MAPPINGS = {
    'full-time': '正社員',
    'part-time': 'パートタイム',
    foreigner: 'パートタイム（外国籍）'
}

export const OPEN_CLOSE_MAPPINGS = {
    true: '担当者である',
    false: '開閉担当ではありません'
}

export const WEEKDAY_MAPPINGS = {
    monday: '月',
    tuesday: '火',
    wednesday: '水',
    thursday: '木',
    friday: '金',
    saturday: '土',
    sunday: '日'
}

export const PDF_TABLE_COL_WIDTHS = {
    default: '4%',
    name: '55%',
    hour: '3%',
    estimated: '15%',
    position: '15%',
    cleaning: '15%'
}

export const PALO_BLUE = '#3F51B5'
export const BREAK = '#000'
export const WARNING = '#F88078'
export const ERROR_COLOR = '#D32F2F'
export const CONFIRMED = '#1A3EA9'
export const BLOCKED = '#ADAEAD'
export const SUBMITTED = '#98AEEE'
export const RECURRING = '#ED6C02'
export const FUSOKU = '#FF0000'

export const HOURS_COEFF = 60 * 60 * 1000

export const MEMO_MAX_ROWS = 5

export const HlgColumns = [
    'seg_code',
    2500,
    5000,
    7500,
    10000,
    12500,
    15000,
    17500,
    20000,
    22500,
    25000,
    27500,
    30000,
    32500,
    35000,
    37500,
    40000,
    42500,
    45000,
    47500,
    50000,
    52500,
    55000,
    57500,
    60000,
    62500,
    65000,
    67500,
    70000,
    72500,
    75000,
    77500,
    80000,
    82500,
    85000
]
