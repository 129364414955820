import React, { useState } from 'react'
import { Button, RadioGroup, Radio, FormControl, FormLabel, FormControlLabel } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { AxiosWithAuth } from '../../../Utilities/authenticationService'
import ButtonWithConfirmPrompt from '../../ButtonWithConfirmPrompt'
import PageWithTitleLayout from '../../Layout/PageWithTitleLayout'

const EditOpenClose = ({ current, handleReturn, employments = [] }) => {
    const { userEmpId } = useParams()
    const [newValue, setNewValue] = useState(current?.toString())

    return (
        <PageWithTitleLayout title="開閉担当者の変更">
            <FormControl sx={{ m: '1rem' }} className="input-box shrinkable">
                <FormLabel>あなたは開閉担当ですか。</FormLabel>
                <RadioGroup value={newValue} onChange={e => setNewValue(e.target.value)}>
                    <FormControlLabel value="true" control={<Radio />} label="開閉担当です" />
                    <FormControlLabel value="false" control={<Radio />} label="開閉担当ではありません" />
                </RadioGroup>
            </FormControl>
            <ButtonWithConfirmPrompt
                component={
                    <Button
                        variant="contained"
                        color="paloBlue"
                        sx={{ mx: '1rem', mt: 'auto', mb: '2rem' }}
                        className="shrinkable"
                        disabled={!newValue}
                    >
                        変更して保存する
                    </Button>
                }
                title="開閉担当者の変更"
                content="開閉担当者を変更してもよろしいですか？"
                confirmText="変更する"
                handleConfirm={() => {
                    if (employments.length > 0) {
                        // uses array of employment ids if given through props instead of params
                        employments.forEach(e => {
                            AxiosWithAuth.put('/employments/' + e, {
                                open_close_duty: newValue
                            }).then(res => {
                                res.status == 200 && handleReturn()
                            })
                        })
                    } else {
                        AxiosWithAuth.put('/employments/' + userEmpId, {
                            open_close_duty: newValue
                        }).then(res => {
                            res.status == 200 && handleReturn()
                        })
                    }
                }}
            />
        </PageWithTitleLayout>
    )
}
export default EditOpenClose
