import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { CONFIRMED, PALO_BLUE, PATHS, SUBMITTED, BLOCKED } from '../../constants'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Button, Menu } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { authenticationService } from '../../Utilities/authenticationService'
import {
    AccountCircleOutlined,
    EventNoteOutlined,
    HomeOutlined,
    SendOutlined,
    EditOutlined,
    ArrowDropDown
} from '@mui/icons-material'
import { SwipeableDrawer, Divider, List } from '@mui/material'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import ButtonWithConfirmPrompt from '../ButtonWithConfirmPrompt'
import { isMobile } from '../../Utilities/isMobile'

const theme = createTheme({
    palette: {
        paloBlue: {
            main: PALO_BLUE,
            contrastText: '#fff'
        },
        success: {
            main: '#4CAF50',
            contrastText: '#fff'
        },
        submitted: {
            main: SUBMITTED,
            contrastText: '#fff'
        },
        confirmed: {
            main: CONFIRMED,
            contrastText: '#fff'
        },
        blocked: {
            main: BLOCKED,
            contrastText: '#fff'
        }
    },
    components: {
        MuiAutocomplete: {
            defaultProps: {
                noOptionsText: '該当なし'
            }
        }
    }
})

const DrawerItem = ({ toggleDrawer, path, text, icon }) => {
    return (
        <NavLink to={path} aria-label={text} onClick={() => toggleDrawer(false)}>
            <MenuItem>
                <ListItemIcon sx={{ mr: '1rem' }}>{icon}</ListItemIcon>
                <ListItemText primary={text} />
            </MenuItem>
        </NavLink>
    )
}

const Layout = ({ children }) => {
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const { isManager, canEditShift, setUserEmployments, isAdmin, resetAppsStates } = useContext(EmploymentsContext)

    const [anchorEl, setAnchorEl] = useState(null)
    const adminOpen = Boolean(anchorEl)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const toggleDrawer = action => {
        setOpen(action)
    }

    const handleLogout = () => {
        setUserEmployments([])
        resetAppsStates()
        localStorage.clear()
        authenticationService.logout()
        history.push(PATHS.login)
    }

    const tag =
        process.env.REACT_APP_FREDERICK_API_ROOT === 'http://localhost:3000'
            ? '(dev)'
            : process.env.REACT_APP_FREDERICK_API_ROOT === 'https://staging-api.ringerhutschedule.com'
            ? '(staging)'
            : ''

    const toolbar = (
        <>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => toggleDrawer(true)}
            >
                <MenuIcon />
            </IconButton>
            <SwipeableDrawer
                id="drawer"
                anchor="left"
                open={open}
                onClose={() => toggleDrawer(false)}
                onOpen={() => toggleDrawer(true)}
            >
                <List>
                    <DrawerItem toggleDrawer={toggleDrawer} path={PATHS.home} icon={<HomeOutlined />} text="ホーム" />
                </List>

                <Divider orientation="horizontal" flexItem />
                <List>
                    {(isManager || canEditShift) && (
                        <DrawerItem
                            toggleDrawer={toggleDrawer}
                            path={PATHS.managerStores + localStorage.selected_emp}
                            icon={<EditOutlined />}
                            text="シフト表を作成する"
                        />
                    )}
                    <DrawerItem
                        toggleDrawer={toggleDrawer}
                        path={PATHS.check}
                        icon={<EventNoteOutlined />}
                        text="シフトを確認する"
                    />
                    <DrawerItem
                        toggleDrawer={toggleDrawer}
                        path={PATHS.stores + localStorage.selected_emp}
                        icon={<SendOutlined />}
                        text="希望シフトを提出する"
                    />
                </List>
                <Divider orientation="horizontal" flexItem />

                <List>
                    <DrawerItem
                        toggleDrawer={toggleDrawer}
                        path={PATHS.userSettings}
                        icon={<AccountCircleOutlined />}
                        text="あなたのアカウント"
                    />
                    {isManager && (
                        <DrawerItem
                            toggleDrawer={toggleDrawer}
                            path={PATHS.managerSettings}
                            icon={<SettingsIcon />}
                            text="設定"
                        />
                    )}
                    <ButtonWithConfirmPrompt
                        component={
                            <MenuItem aria-label="logout">
                                <ListItemIcon sx={{ mr: '1rem' }}>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="ログアウト" />
                            </MenuItem>
                        }
                        title="ログアウト"
                        content="ログアウトしてよろしいですか？"
                        confirmText="ログアウト"
                        handleConfirm={() => {
                            toggleDrawer(false)
                            handleLogout()
                        }}
                    />
                </List>
            </SwipeableDrawer>
        </>
    )

    const desktopMenu = (
        <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '50%' }}>
                <a href={PATHS.home} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button sx={{ color: '#fff', fontSize: '1.3rem', ml: '0.5em', textTransform: 'none' }}>
                        Frederick {tag}
                    </Button>
                </a>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
                <List sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button sx={{ color: '#fff', fontSize: '1rem' }} onClick={() => history.push(PATHS.home)}>
                        ホーム
                    </Button>
                    <Button sx={{ color: '#fff', fontSize: '1rem' }} onClick={() => history.push(PATHS.userSettings)}>
                        あなたのアカウント
                    </Button>
                    {isManager && (
                        <Button
                            sx={{ color: '#fff', fontSize: '1rem' }}
                            onClick={() => history.push(PATHS.userManagementStores)}
                        >
                            ユーザー管理
                        </Button>
                    )}
                    {isManager && (
                        <Button
                            sx={{ color: '#fff', fontSize: '1rem' }}
                            onClick={() => history.push(PATHS.storeManagement)}
                        >
                            店舗管理
                        </Button>
                    )}

                    {isAdmin && (
                        <div>
                            <Button
                                id="basic-button"
                                aria-controls={adminOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={adminOpen ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{ color: '#fff', fontSize: '1rem' }}
                                endIcon={<ArrowDropDown />}
                            >
                                管理者
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={adminOpen}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button'
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose()
                                        history.push(PATHS.adminUserManagements)
                                    }}
                                >
                                    ユーザー管理
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleClose()
                                        history.push(PATHS.adminStoreManagements)
                                    }}
                                >
                                    店舗管理
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleClose()
                                        history.push(PATHS.adminHLG)
                                    }}
                                >
                                    マスタデータ管理
                                </MenuItem>
                            </Menu>
                        </div>
                    )}

                    <ButtonWithConfirmPrompt
                        component={
                            <MenuItem aria-label="logout">
                                <ListItemText primary="ログアウト" />
                            </MenuItem>
                        }
                        title="ログアウト"
                        content="ログアウトしてよろしいですか？"
                        confirmText="ログアウト"
                        handleConfirm={() => {
                            toggleDrawer(false)
                            handleLogout()
                        }}
                    />
                </List>
            </div>
        </div>
    )
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" color="paloBlue">
                    {!isMobile() ? (
                        localStorage.getItem('currentUser') == null ? null : (
                            desktopMenu
                        )
                    ) : (
                        <Toolbar>{localStorage.getItem('currentUser') == null ? null : toolbar}</Toolbar>
                    )}
                </AppBar>
                {children}
            </Box>
        </ThemeProvider>
    )
}

export default Layout
