export const displayNameWithLimit = (userObj, maxlength = 9) => {
    const { kanji_last, kanji_first, name_last, name_first, nickname } = userObj
    const firstname = kanji_first || name_first || ''
    const lastname = kanji_last || name_last || ''

    if (maxlength <= 0) {
        return ''
    }
    if (firstname && lastname && firstname.length > 0 && lastname.length > 0) {
        const isAlphaNumeric = name => {
            return /^[a-zA-Z0-9]+$/.test(name)
        }
        const shortenName = (name, maxlength) => {
            return name.length > maxlength ? name.substring(0, maxlength) + '...' : name
        }
        const combinedName = nickname || lastname + firstname
        const shortenedJapaneseName = shortenName(combinedName, maxlength)
        const shortenedEnglishName = shortenName(firstname, 10) + ' ' + lastname[0] + '.'

        if (!isAlphaNumeric(lastname)) {
            return shortenedJapaneseName
        } else {
            return shortenedEnglishName
        }
    } else {
        return '名無し'
    }
}

export const displayNameWithSpace = userObj => {
    const { kanji_last, kanji_first, name_last, name_first, middle_name, nickname } = userObj
    return nickname || `${kanji_last || name_last} ${middle_name ? middle_name + ' ' : ''}${kanji_first || name_first}`
}
